import React, { useContext } from "react";
import {
  Form,
  Input,
  Title,
  Text,
  Button,
  Label,
  Select,
  PrimaryButton,
} from "../../theme//theme.js";
import styled from "styled-components";
import { Formik, validateYupSchema } from "formik";
import * as yup from "yup";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";

const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.negRed};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.white};
  align-self: flex-end;
  margin-left: 1.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

function UserForm(props) {
  const context = useContext(Context);

  const deleteUser = async (id) => {
    try {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: true,
          isModalOpen: true,
          modalComponent: "",
        },
      });
      let res = await FirebaseService.deleteUser(id);

      console.log(res);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      props.onClose();
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
        },
      });
    }
  };

  return (
    <>
      <Title>{props.title}</Title>
      {/* FORMIK */}
      <Formik
        //the form works in 2 modes create and update
        initialValues={
          props.init
            ? {
                name: props.init.displayName,
                email: props.init.email,
                phone: props.init.phoneNumber,
                role: props.init.customClaims.admin ? "Admin" : "Investor",
                password: "11111111111",
              }
            : {
                role: "Investor",
              }
        }
        validate={(values) => {
          console.log(values);
        }}
        onSubmit={async (values) => {
          try {
            console.log("USER FORM CONTEXT STATE\n\n", context.state);
            context.actions({
              type: "setState",
              payload: { ...context.state, isFetching: true },
            });
            if (!props.isEdit) {
              let user = await FirebaseService.createUser(
                values.name,
                values.phone,
                values.password,
                values.email,
                values.role
              );
              console.log(user);
              props.onClose();
              context.actions({ type: "getUsers", payload: null });
            } else {
              let user = await FirebaseService.updateUser(
                props.init.uid,
                values.name,
                values.phone,
                values.email,
                values.role
              );
              console.log(user);
              props.onClose();
              context.actions({ type: "getUsers", payload: null });
            }
          } catch (e) {
            context.actions({
              type: "setState",
              payload: {
                ...context.state,
                isError: true,
                errorMsg: String(e.message),
              },
            });
            console.log(e);
          }
        }}
        validationSchema={yup.object().shape({
          email: yup.string().email().required(),
          password: yup.string().min(6).required(),
          name: yup.string().required(),
          phone: yup
            .string()
            .trim()
            .matches(/^\+[1-9]\d{9,14}$/, "This is not a valid phone number")
            .required(),
          role: yup.string().required(),
        })}
        render={({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Label>
              Email *
              {errors.email && touched.email && (
                <Text color="red">{errors.email}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                border={errors.email && "1px solid red"}
                type="email"
                name="email"
                placeholder="Email"
              />
            </Label>
            {!props.init ? (
              <Label>
                Password *
                {errors.password && touched.password && (
                  <Text color="red">{errors.password}</Text>
                )}
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  border={errors.password && "1px solid red"}
                  type="password"
                  name="password"
                  placeholder="Password"
                />
              </Label>
            ) : (
              <></>
            )}
            <Label>
              FullName *
              {errors.name && touched.name && (
                <Text color="red">{errors.name}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                border={errors.name && "1px solid red"}
                type="text"
                name="name"
                placeholder="Full Name"
              />
            </Label>

            <Label>
              Phone *
              {errors.phone && touched.phone && (
                <Text color="red">{errors.phone}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                border={errors.phone && "1px solid red"}
                type="text"
                name="phone"
                placeholder="+123456789"
              />
            </Label>
            <Label>
              Role
              {errors.role && <Text color="red">{errors.role}</Text>}
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
                border={errors.role && "1px solid red"}
                type="text"
                name="role"
                placeholder=""
              >
                <option value="Investor">Investor</option>
                <option value="Admin">Admin</option>
              </Select>
            </Label>
            <ButtonsContainer>
              <PrimaryButton type="submit">Submit</PrimaryButton>
              {props.init ? (
                <DeleteButton
                  type="button"
                  onClick={async () => {
                    let a = window.confirm(
                      "Warning! Are you sure you want to delete " +
                        props.init.email +
                        " ? This can not be reversed."
                    );
                    if (a) {
                      try {
                        await deleteUser(props.init.uid);
                        //  props.refresh();
                      } catch (e) {
                        console.log(e);
                        context.actions({
                          type: "setState",
                          payload: {
                            ...context.state,
                            isError: true,
                            errorMsg: e.message,
                          },
                        });
                      }
                    }
                  }}
                >
                  DELETE USER
                </DeleteButton>
              ) : (
                <></>
              )}
            </ButtonsContainer>
          </Form>
        )}
      />
      {/* END OF FORMIK */}
    </>
  );
}

export default UserForm;

//validation example //
/*
// Synchronous validation
const validate = (values, props // only available when using withFormik 
    ) => {
    const errors = {};
  
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
  
    //...
  
    return errors;
  };
  
  // Async Validation
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
  
  const validate = (values, props // only available when using withFormik 
    ) => {
    return sleep(2000).then(() => {
      const errors = {};
      if (['admin', 'null', 'god'].includes(values.username)) {
        errors.username = 'Nice try';
      }
      // ...
      return errors;
    });
  };
*/

/*
<p>
                <label htmlFor="required-input">First Name</label>
                <input type="text" id="required-input" required />
                <span class="help-text"></span>
            </p>
            <p>
                <label htmlFor="lastName">Last Name</label>
                <input type="text" name="lastName" id="lastName" required />
                <span class="help-text"></span>
            </p>
            <p>
                <label htmlFor="optional-input">Optional input</label>
                <input type="text" id="optional-input" />
                <span class="help-text"></span>
            </p>
            <p>
                <label htmlFor="disabled-input">Disabled input</label>
                <input type="text" id="disabled-input" disabled />
            </p>
            <p>
                <label htmlFor="read-only-input">Read-only input</label>
                <input type="text" id="read-only-input" value="Read-only value" readonly />
            </p>
            <p>
                <label htmlFor="valid-email">Valid input</label>
                <input type="email" id="valid-email" value="email@email.com" required />
                <span class="help-text"></span>
            </p>
            <p>
                <label htmlFor="invalid-email">Invalid input</label>
                <input type="email" id="invalid-email" value="notanemail" required />
                <span class="help-text"></span>
            </p>
            <p>
                <label htmlFor="in-range-input">In-range input</label>
                <input type="number" id="in-range-input" min="1" max="10" value="8" required />
                <span class="help-text"> (value must be between 1 and 10)</span>
            </p>
            <p>
                <label htmlFor="out-of-range-input">Out-of-range input</label>
                <input type="number" id="out-of-range-input" min="1" max="10" value="12" required />
                <span class="help-text"> (value must be between 1 and 10)</span>
            </p>
            <fieldset>
                <legend>Checked input</legend>
                <div class="checkbox">
                    <input type="checkbox" name="checkbox" id="check-option-1" value="1" checked />
                    <label htmlFor="check-option-1">Option 1</label>
                </div>
                <div class="checkbox">
                    <input type="checkbox" name="checkbox" id="check-option-2" value="2" />
                    <label htmlFor="check-option-2">Option 2</label>
                </div>
            </fieldset>
            */
