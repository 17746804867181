import React, { useState, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import TimeSelector from "../ui/TimeSelector";
import { Form, PrimaryButton } from "../../theme/theme";
import ExpensesBox from "../ui/ExpensesBox";
import FinanceTable from "./FinanceTable";
import CanceledTable from "./CanceledTable";
import FirebaseService from "../../services/FirebaseService";
import ReactTooltip from "react-tooltip";
import InvestorExpensesTable from "./InvestorExpensesTable";

import Context from "../../store/context";
import { Cell } from "recharts";
import TaxExpensesBox from "../ui/TaxExpensesBox";

const { Parser } = require("json2csv");

const PnlLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  text-align: left;
`;

const AcountTable = styled.div`
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  border-radius: 4px;
  border: 1px ${({ theme }) => theme.darkGray} solid;
  padding: 1rem;
  width: 85%;
  max-width: 900px;
  min-width: 300px;
  margin: auto;
`;

const Cells = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 1.5rem;
  line-height: 1.4rem;
  padding: 0rem 0.4rem;

  & div {
    width: 50%;
    border-bottom: 1px ${({ theme }) => theme.darkGray} dotted;
    &.income {
      color: ${({ theme }) => theme.posGreen};
    }
    &.expense {
      color: ${({ theme }) => theme.negRed};
    }
    &.payouts {
      color: ${({ theme }) => theme.primaryDark};
    }
  }
  & :last-child {
    text-align: right;
  }
  & a:hover {
    color: black;
  }
`;
const TableTitleRow = styled.div`
  font-size: 1rem;
  font-weight: 900;
  width: flex 1;
  padding: 0.7rem 0.3rem;
  &.balance {
    background-color: ${({ theme }) => theme.darkGray};
    font-size: 1.1rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

const ExpenseForm = styled(Form)`
  border-radius: 4px;
  border: 1px ${({ theme }) => theme.darkGray} solid;
  width: 30%;
  margin: 1rem auto;
  height: fit-content;
  padding: 1rem;
  min-width: 300px;
  align-items: center;
`;

const TableSubTotalRow = styled.div`
  font-size: 1rem;
  font-weight: 900;
  width: flex 1;
  height: 2rem;
  padding: 0.5rem 0;
`;

const exportCsv = function (obj, year, period) {
  const fields = ["property", "year", "period", "type", "source", "amount"];
  const opts = { fields };
  let accountingData = generateCSVData(obj, year);
  try {
    const parser = new Parser(opts);
    const csv = parser.parse(accountingData);
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = obj.title + "_report.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  } catch (err) {
    console.error(err);
  }
};

//DEPRECATED - filter by date
const prepareResData = (resObjs, period, time) => {
  //console.log("PERIOD : ", period);
  // console.log("TIME", time);
  let end_date = time.clone().add(time.daysInMonth() - 1, "d");
  // console.log("END", end_date);
  let data = Object.values(resObjs);

  let filtered = data.filter(
    (e) =>
      (e.financeCalc !== undefined &&
        moment(e.checkOut).diff(end_date) <= 0 &&
        moment(e.checkOut).diff(time) > 0) ||
      (moment(e.checkIn).diff(end_date) <= 0 &&
        moment(e.checkIn).diff(time) > 0)
  );
  console.log(filtered);
  return filtered;
};

//New method - filter by resid in cal
const setResData = (resObjs, period, time, calendar) => {
  console.log("time,", time);
  let start_date =
    period !== "annual" ? moment.utc(time) : moment.utc(time).startOf("year");

  let end_date =
    period !== "annual"
      ? moment.utc(start_date).endOf("month")
      : moment.utc(start_date).endOf("year");

  let reservations = {};
  // console.log("SET RES DATA ",start_date.format("YYYY-MM-DD"),end_date.format("YYYY-MM-DD") );
  end_date.add(1, "d");

  while (
    end_date.format("YYYY-MM-DD") !== moment(start_date).format("YYYY-MM-DD")
  ) {
    let date_string = start_date.format("YYYY-MM-DD");
    let calDay = calendar[date_string];
    if (calDay && calDay.reservationId) {
      //console.log("RESERVATION " + calDay.reservationId);
      if (
        resObjs[calDay.reservationId] &&
        resObjs[calDay.reservationId].financeCalc
      ) {
        reservations[calDay.reservationId] = resObjs[calDay.reservationId];
      }
      // console.log("RESERVATION " + resObjs[calDay.reservationId]);
    }
    start_date.add(1, "d");
  }
  console.log(reservations);
  return Object.values(reservations);
};

const generateCSVData = function (obj, year) {
  let entireData = [];

  let allmonths = Object.keys(obj.months);

  allmonths.forEach((period) => {
    let income1 = Object.keys(obj.months[period].income.distribution).map(
      (el) => {
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "income",
          source: el,
          amount: obj.months[period].income.distribution[el].toFixed(2),
        };
      }
    );
    let income2 = [];
    if (obj.income[period]) {
      income2 = Object.keys(obj.income[period].distribution).map((el) => {
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "income",
          source: el,
          amount: obj.income[period].distribution[el].amount.toFixed(2),
        };
      });
    }

    let expenses1 = Object.keys(obj.months[period].expenses.distribution).map(
      (el) => {
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "expense",
          source: el,
          amount: obj.months[period].expenses.distribution[el].toFixed(2),
        };
      }
    );
    let expenses2 = [];
    if (obj.expenses[period]) {
      // console.log("******");
      //console.log(obj.expenses[period]);
      expenses2 = Object.keys(obj.expenses[period].distribution).map((el) => {
        // console.log("****  ", el);
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "expense",
          source: el,
          amount: obj.expenses[period].distribution[el].amount.toFixed(2),
        };
      });
    }

    let payouts = [];
    if (obj.payouts[period]) {
      payouts = Object.keys(obj.payouts[period].distribution).map((el) => {
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "payout",
          source: el,
          amount: obj.payouts[period].distribution[el].amount.toFixed(2),
        };
      });
    }
    entireData = [
      ...entireData,
      ...income1,
      ...income2,
      ...expenses1,
      ...expenses2,
      ...payouts,
    ];
  });
  return entireData;
};

function getMonth(month) {
  var d;
  d = new Date().toString().split(" ")
  console.log("d1", d);
  d[1] = month
  console.log("d2" , d);
  d = new Date(d.join(' ')).getMonth()+1
  console.log("d3" , d);
  if(!isNaN(d)) {
    return d
  }
  return -1;
}
const get_reservations_columns = function (bulk, year, CurrentMonth) {
  // console.log("THECOLUMNS : ", year, " -- ", bulk);
  console.log("bulk", bulk);
  console.log(year);
  console.log("CurrentMonth", CurrentMonth);
  var currentDate = moment().format("MM-DD-YYYY");
  console.log("currentDate", currentDate);
  console.log("currentyear" ,  moment().format("YYYY"))
  return [
    {
      name: "Source",
      selector: "source",
      sortable: true,
    },
    {
      name: "Check In",
      selector: "checkIn",
      sortable: true,
      format: (row) => (
        
        <span
        //old that says more than one month in red
        // row.money !== true ? "partial" : "" nir here set new logic
          className={            
            //row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
            (row.money.balanceDue !==  0 && currentDate > moment.utc(row.checkIn).format("MM-DD-YYYY")) || row.money.balanceDue !==  0 && moment().format("YYYY") > moment.utc(row.checkIn).format("YYYY")
              ? "partial"
              : "" ||
               row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
              ? "several"
              :""
          }          
        >          
          {moment.utc(row.checkIn).format("MM-DD-YYYY")}
        </span>
      ),
    },
    {
      name: "Check Out",
      selector: "checkOut",
      sortable: true,
      format: (row) => (
        <span
        className={            
          //row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
          (row.money.balanceDue !==  0 && currentDate > moment.utc(row.checkIn).format("MM-DD-YYYY")) || row.money.balanceDue !==  0 && moment().format("YYYY") > moment.utc(row.checkIn).format("YYYY")
          ? "partial"
            : "" ||
             row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
            ? "several"
            :""
        }          
        >
          {moment.utc(row.checkOut).format("MM-DD-YYYY")}
        </span>
      ),
    },
    {
      name: "Income",
      selector: "financeCalc." + year + "." + bulk + "." + "breakdown.income",
      sortable: true,
      format: (row) => (
        <span
        className={            
          //row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
          (row.money.balanceDue !==  0 && currentDate > moment.utc(row.checkIn).format("MM-DD-YYYY")) || row.money.balanceDue !==  0 && moment().format("YYYY") > moment.utc(row.checkIn).format("YYYY")
          ? "partial"
            : "" ||
             row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
            ? "several"
            :""
        }          
        >
          {row.financeCalc[year][bulk]
            ? Number(row.financeCalc[year][bulk].breakdown.income)
            : "--"}
        </span>
      ),
    },
    {
      name: "Cleaning",
      selector:
        "financeCalc." + year + "." + bulk + "." + "breakdown.cleaning_fee",
      sortable: false,
      format: (row) => (
        <span
        className={            
          //row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
          (row.money.balanceDue !==  0 && currentDate > moment.utc(row.checkIn).format("MM-DD-YYYY")) || row.money.balanceDue !==  0 && moment().format("YYYY") > moment.utc(row.checkIn).format("YYYY")
          ? "partial"
            : "" ||
             row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
            ? "several"
            :""
        }          
        >
          {row.financeCalc[year][bulk]
            ? Number(row.financeCalc[year][bulk].breakdown.cleaning_fee)
            : "--"}
        </span>
      ),
    },
    {
      name: "Channel Fee",
      selector:
        "financeCalc." + year + "." + bulk + "." + "breakdown.channel_fee",
      sortable: false,
      format: (row) => (
        <span
        className={            
          //row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
          (row.money.balanceDue !==  0 && currentDate > moment.utc(row.checkIn).format("MM-DD-YYYY")) || row.money.balanceDue !==  0 && moment().format("YYYY") > moment.utc(row.checkIn).format("YYYY")
          ? "partial"
            : "" ||
             row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
            ? "several"
            :""
        }          
        >
          {row.financeCalc[year][bulk]
            ? Number(row.financeCalc[year][bulk].breakdown.channel_fee)
            : "--"}
        </span>
      ),
    },
    {
      name: "Proc. Fee",
      selector:
        "financeCalc." + year + "." + bulk + "." + "breakdown.process_fee",
      sortable: false,
      format: (row) => (
        <span
        className={            
          //row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
          (row.money.balanceDue !==  0 && currentDate > moment.utc(row.checkIn).format("MM-DD-YYYY")) || row.money.balanceDue !==  0 && moment().format("YYYY") > moment.utc(row.checkIn).format("YYYY")
          ? "partial"
            : "" ||
             row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
            ? "several"
            :""
        }          
        >
          {row.financeCalc[year][bulk]
            ? Number(row.financeCalc[year][bulk].breakdown.process_fee)
            : "--"}
        </span>
      ),
    },
    {
      name: "Mng.",
      selector:
        "financeCalc." + year + "." + bulk + "." + "breakdown.management_fee",
      sortable: false,
      format: (row) => (
        <span
        className={            
          //row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
          (row.money.balanceDue !==  0 && currentDate > moment.utc(row.checkIn).format("MM-DD-YYYY")) || row.money.balanceDue !==  0 && moment().format("YYYY") > moment.utc(row.checkIn).format("YYYY")
          ? "partial"
            : "" ||
             row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
            ? "several"
            :""
        }          
        >
          {row.financeCalc[year][bulk]
            ? Number(row.financeCalc[year][bulk].breakdown.management_fee)
            : "--"}
        </span>
      ),
    },

    {
      name: "# Nights",
      selector: "nightsCount",
      sortable: true,
      format: (row) => (
        <span
        className={            
          //row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
          (row.money.balanceDue !==  0 && currentDate > moment.utc(row.checkIn).format("MM-DD-YYYY")) || row.money.balanceDue !==  0 && moment().format("YYYY") > moment.utc(row.checkIn).format("YYYY")
          ? "partial"
            : "" ||
             row.financeCalc[year][bulk] && row.financeCalc[year][bulk].rate < 1
            ? "several"
            :""
        }          
        >
          {row.financeCalc[year][bulk]
            ? Number(
                row.financeCalc[year][bulk].rate * row.nightsCount
              ).toFixed(0)
            : "--"}
        </span>
      ),
    },
    {
      name: "Guest",
      selector: "guest.fullName",
      sortable: false,
      hide: "sm",
    },
    /*{
      name: "inPeriod(%)",
      selector: "financeCalc." + year + "." + bulk + ".rate",
      sortable: true,
      format: (row) => (
        <span className={row.financeCalc[year][bulk].rate < 1 ? "partial" : ""}>
          {Number(row.financeCalc[year][bulk].rate * 100).toFixed(1)}
        </span>
      ),
      hide: "sm",
    },*/
  ];
};

const calcTableCanceledPortion = function (
  canceledObj,
  period,
  lineItem,
  source = undefined
) {
  const lineItemConvert = {
    "Cleaning fee": "cleaningFee",
    "Processing fee": "processingFee",
    "City tax": "cityTax",
    "State tax": "stateTax",
    "Management fee": "managementFee",
    "Channel fee": "channelFee",
  };

  if (lineItemConvert[lineItem] !== undefined) {
    // console.log("LINE ITEM was : ", lineItem);
    lineItem = lineItemConvert[lineItem];
    // console.log("LINE ITEM is : ", lineItem);
  }

  let sum = 0;
  if (canceledObj[period]) {
    let periodArray = Object.values(canceledObj[period]);

    if (source) {
      periodArray = periodArray.filter((el) => el.source === source);
    }

    const getSum = function (a, b) {
      return a + b.finance[lineItem];
    };
    sum = periodArray.reduce(getSum, 0);
  }

  return sum;
};
const addExpensesType = function(props){
  Object.keys(props.data.expenses[props.bulk].distribution).forEach(e=>{
    console.log("eee", e);
    if(e === 'City tax'){
      props.data.months[props.bulk].expenses.distribution['City tax'] += props.data.expenses[props.bulk].distribution[e].amount;
    }
    else if(e==='State tax'){
      props.data.months[props.bulk].expenses.distribution['State tax'] += props.data.expenses[props.bulk].distribution[e].amount;
    }
    else if(e==='Cleaning fee'){
      props.data.months[props.bulk].expenses.distribution['Cleaning Fee'] += props.data.expenses[props.bulk].distribution[e].amount;
    }
    else if(e==='Management fee'){
      props.data.months[props.bulk].expenses.distribution['Managment Fee'] += props.data.expenses[props.bulk].distribution[e].amount;
    }
    else if(e==='Processing fee'){
      props.data.months[props.bulk].expenses.distribution['Processing fee'] += props.data.expenses[props.bulk].distribution[e].amount;
    }
    else if(e==='Channel fee'){
      props.data.months[props.bulk].expenses.distribution['Channel fee'] += props.data.expenses[props.bulk].distribution[e].amount;
    }
    })
    return props;
}
export default function FinView(props) {
  console.log("propsooo", props);
  const context = useContext(Context);
  // props = addExpensesType(props);
  // console.log("newProps" , props);
  // const cleaningFeeMonthlyExpenses = getExtraExpensesByMonthNType(props.data.expenses[props.bulk].distribution , 'Cleaning Fee');
  // console.log(cleaningFeeMonthlyExpenses);
  // props.data.months[props.bulk].expenses.distribution['Cleaning Fee'] += cleaningFeeMonthlyExpenses;

  const changeMonth = function (dir) {
    //log("REDUCE TIME");
    let newT = props.time.clone();
    !props.period ? newT.add(dir, "months") : newT.add(dir, "years");
    props.onTimeChange(newT);
  };

  let totalIncome = 0;
  let totalEx = 0;
  let totalPay = 0;

  const isAdmin = context.state.isAdmin;

  if (props.data.months && props.data.months[props.bulk]) {
    totalEx =
      props.data.months[props.bulk].expenses.amount +
      (props.data.expenses[props.bulk]
        ? props.data.expenses[props.bulk].amount
        : 0);
    totalPay = props.data.payouts[props.bulk]
      ? Number(props.data.payouts[props.bulk].amount)
      : 0;
    totalIncome =
      props.data.months[props.bulk].income.amount +
      (props.data.income[props.bulk]
        ? props.data.income[props.bulk].amount
        : 0);
  }

  const rollBalance = async (property_id, amount, current_month) => {
    // console.log(current_month);
    try {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: true,
          isModalOpen: true,
          modalComponent: "",
        },
      });
      let res = await FirebaseService.rollBalance(
        property_id,
        amount,
        current_month
      );
      //  console.log(res);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });

      // props.refresh(Math.random());
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
        },
      });
    }
  };

  return (
    <>
      <fieldset>
        <legend> Accounting Details </legend>
        <TimeSelector
          time={props.time}
          onTimeChange={changeMonth}
          onPeriodChange={() => props.onPeriodChange(!props.period)}
          period={props.period}
        ></TimeSelector>
        {props.data.months ? (
          <PnlLayout>
            <AcountTable>
              <TableTitleRow>Income</TableTitleRow>
              {Object.keys(
                props.data.months[props.bulk].income.distribution
              ).map((el, index) => (
                <>
                  <Cells>
                    <div>{el}</div>
                    <div className="income">
                      <span>
                        <a data-tip data-for={el + "_id"}>
                          $
                          {props.data.months[props.bulk].income.distribution[
                            el
                          ] !== null
                            ? props.data.months[props.bulk].income.distribution[
                                el
                              ].toFixed(2)
                            : el}
                        </a>
                      </span>
                    </div>{" "}
                  </Cells>
                  <ReactTooltip id={el + "_id"} type="dark" effect="solid">
                    <span>
                      $
                      {calcTableCanceledPortion(
                        props.canceled,
                        props.bulk,
                        "totalPaid",
                        el
                      )}{" "}
                      from canceled reservations
                    </span>
                  </ReactTooltip>
                </>
              ))}
              {props.data.income[props.bulk] ? (
                Object.keys(props.data.income[props.bulk].distribution).map(
                  (el, index) => (
                    <Cells>
                      <div>{el}</div>
                      <div className="income">
                        $
                        {props.data.income[props.bulk].distribution[
                          el
                        ].amount.toFixed(2)}
                      </div>{" "}
                    </Cells>
                  )
                )
              ) : (
                <></>
              )}
              <TableSubTotalRow>
                <>
                  <Cells>
                    <div className="income">Total:</div>
                    <div>
                      <span>
                        <a data-tip data-for={"TotalIncome_id"}>
                          $ {totalIncome.toFixed(2)}
                        </a>
                      </span>
                    </div>
                  </Cells>
                  <ReactTooltip
                    id={"TotalIncome_id"}
                    type="dark"
                    effect="solid"
                  >
                    <span>
                      $
                      {calcTableCanceledPortion(
                        props.canceled,
                        props.bulk,
                        "totalPaid"
                      )}{" "}
                      from canceled reservations
                    </span>
                  </ReactTooltip>
                </>
              </TableSubTotalRow>
              <br></br>
              <TableTitleRow>Expenses</TableTitleRow>
              {props.data.months[props.bulk] ?  (                
                Object.keys(
                props.data.months[props.bulk].expenses.distribution)
                .map((el, index) => (                  
                  <>                                                
                  <TaxExpensesBox
                      type={el}
                      index = {index}
                      total={ 
                        (props.data.expenses[props.bulk] != undefined && props.data.expenses[props.bulk].distribution[el] !== undefined) ||
                        (props.data.expenses[props.bulk] != undefined && props.data.expenses[props.bulk].distribution[el] !== undefined) 
                        ?
                        props.data.expenses[props.bulk].distribution[
                        el
                      ].amount.toFixed(2) : props.data.months[
                        props.bulk
                      ].expenses.distribution[el].toFixed(2)}
                      list={                        
                        props.data.expenses[props.bulk] != undefined && props.data.expenses[props.bulk].distribution[el] != undefined?
                        props.data.expenses[props.bulk].distribution[el].list
                      : null}
                      Reservation = {props.data.months[
                        props.bulk
                      ].expenses.distribution[el] !== undefined ? props.data.months[
                        props.bulk
                      ].expenses.distribution[el].toFixed(2) : null  }
                      isTax = {false}
                      cleaningFee = {
                        el === "Cleaning fee" && props.bulk !== "annual"
                          ?  el  +
                            "    (" + 
                            ( 
                              props.data.months[props.bulk].expenses
                                .distribution[el] /
                              props.data.months[props.bulk]
                                .ListingActualCleaninCost
                            ).toFixed(2) +
                            ")"
                          : el
                      }
                    ></TaxExpensesBox>                                  
                   
                   
                    <ReactTooltip id={el + "_id"} type="dark" effect="solid">
                      <span>
                        $
                        {calcTableCanceledPortion(
                          props.canceled,
                          props.bulk,
                          el
                        )}{" "}
                        from canceled reservations
                      </span>
                    </ReactTooltip>
                  </>
                ))
              ) : (
                <></>
              )}
              {props.data.expenses[props.bulk] ? (
                <>
                  {Object.keys(
                    props.data.expenses[props.bulk].distribution
                  )
                  .filter((key)=> key !== 'State tax')
                  .filter((key) => key !== 'Management fee')
                  .filter((key)=> key !== 'City tax')
                  .filter((key)=> key !== 'Cleaning fee')
                  .filter((key)=> key !== 'Processing fee')
                  .filter((key)=> key !== 'Channel fee')
                  .map((el, index) => (
                    <ExpensesBox
                    type={el}
                    total={props.data.expenses[props.bulk].distribution[
                      el
                    ].amount.toFixed(2)}
                    list={
                      props.data.expenses[props.bulk].distribution[el].list
                    }
                    Reservation = {props.data.months[
                      props.bulk
                    ].expenses.distribution[el] !== undefined ? props.data.months[
                      props.bulk
                    ].expenses.distribution[el].toFixed(2) : null  }
                  ></ExpensesBox>
                  ))}
                </>
              ) : (
                <></>
              )}
              <TableSubTotalRow>
                <Cells>
                  <div className="expense">Total:</div>
                  <div>$ {totalEx.toFixed(2)}</div>
                </Cells>
              </TableSubTotalRow>
              <TableTitleRow>Client Payments</TableTitleRow>
              {props.data.payouts[props.bulk] ? (
                <>
                  {Object.keys(props.data.payouts[props.bulk].distribution)
                  .filter((key)=> key !== 'State tax')
                  .filter((key) => key !== 'Management fee')
                  .filter((key)=> key !== 'City tax')
                  .filter((key)=> key !== 'Cleaning fee')
                  .map(
                    (el, index) => (
                      <ExpensesBox
                        type={el}
                        total={props.data.payouts[props.bulk].distribution[
                          el
                        ].amount.toFixed(2)}
                        list={
                          props.data.payouts[props.bulk].distribution[el].list
                        }
                      ></ExpensesBox>
                    )
                  )}
                </>
              ) : (
                <></>
              )}

              <TableSubTotalRow>
                <Cells>
                  <div className="payouts">Total:</div>
                  <div>$ {totalPay.toFixed(2)}</div>
                </Cells>
              </TableSubTotalRow>
              <br></br>
              <TableTitleRow className="balance">
                <Cells>
                  <div>Balance:</div>
                  <div>$ {(totalIncome - totalEx - totalPay).toFixed(2)}</div>
                </Cells>
              </TableTitleRow>
              <br></br>
              <ButtonsContainer>
                <PrimaryButton
                  className="desktop"
                  onClick={() =>
                    exportCsv(props.data, props.time.year(), props.bulk)
                  }
                >
                  Export to CSV
                </PrimaryButton>
                {isAdmin ? (
                  <PrimaryButton
                    className="desktop"
                    onClick={async () => {
                      let balance = (totalIncome - totalEx - totalPay).toFixed(
                        2
                      );
                      let a = window.confirm(
                        "Are you sure you want to roll $" +
                          balance +
                          " to next month?"
                      );
                      if (a) {
                        try {
                          await rollBalance(
                            props.propertyId,
                            balance,
                            moment.utc(
                              props.time.format("YYYY") +
                                "-" +
                                props.time.format("MMM"),
                              "YYYY-MMM"
                            )
                          );
                          props.refresh();
                        } catch (e) {
                          console.log(e);
                          context.actions({
                            type: "setState",
                            payload: {
                              ...context.state,
                              isError: true,
                              errorMsg: e.message,
                            },
                          });
                        }
                      }
                    }}
                  >
                    Roll Balance
                  </PrimaryButton>
                ) : (
                  <></>
                )}
              </ButtonsContainer>
            </AcountTable>
          </PnlLayout>
        ) : (
          <></>
        )}
      </fieldset>
      <fieldset>
        <legend>
          Reservations{" "}
          {props.bulk === "annual" ? props.time.format("YYYY") : props.bulk}
        </legend>
        
        <FinanceTable
          data={setResData(
            props.reservations,
            props.bulk,
            props.time,
            props.calendar
          )}
          title=""
          columns={get_reservations_columns(
            props.bulk,
            props.time.format("YYYY"),
            props.time.format("MM"),
            
          )}
          onRowClick={(initData) => {}}
          paginationPerPage={30}
        ></FinanceTable>
      </fieldset>

      <fieldset>
        <legend>
          Canceled Reservations{" "}
          {props.bulk === "annual" ? props.time.format("YYYY") : props.bulk}
        </legend>
        <CanceledTable
          id={props.propertyId}
          bulk={props.bulk}
          time={props.time}
          year={props.year}
          refresh={props.refresh}
          notifyRefresh={props.notifyRefresh}
        ></CanceledTable>
      </fieldset>

      <fieldset>
        <legend>
          Investor Expenses{" "}
          {props.bulk === "annual" ? props.time.format("YYYY") : props.bulk}
        </legend>
        <InvestorExpensesTable
          id={props.propertyId}
          bulk={props.bulk}
          time={props.time}
          year={props.year}
        ></InvestorExpensesTable>
      </fieldset>
    </>
  );
}

/**
 * <fieldset>
        <legend>
          Investor Expenses{" "}
          {props.bulk === "annual" ? props.time.format("YYYY") : props.bulk}
        </legend>
        <InvestorExpensesTable
          id={props.propertyId}
          bulk={props.bulk}
          time={props.time}
          year={props.year}
        ></InvestorExpensesTable>
      </fieldset>
 */
