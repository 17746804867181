import React, { useState, useEffect, useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import Context from "../../store/context";
import moment from "moment";
import styled from "styled-components";
import FirebaseService from "../../services/FirebaseService";
import FinanceTable from "./FinanceTable";
import CResForm from "./CResForm";

const get_reservations_columns = function (bulk, year) {
  return [
    {
      name: "Source",
      selector: "source",
      sortable: true,
    },
    {
      name: "Check In",
      selector: "checkIn",
      sortable: true,
      format: (row) => {
        if (row.activationStatus) {
        }
        return (
          <span className={row.activationStatus ? "active" : ""}>
            {moment.utc(row.checkIn).format("MM-DD-YYYY")}
          </span>
        );
      },
    },

    {
      name: "Income",
      selector: "financeCalc.icome",
      sortable: true,
      format: (row) => (
        <span className={row.activationStatus ? "active" : ""}>
          {row.financeCalc.activate
            ? row.financeCalc.activate.totalPaid
            : row.financeCalc.income}
        </span>
      ),
    },
    {
      name: "Cleaning",
      selector: "financeCalc.cleaningCost",
      sortable: false,
      format: (row) => (
        <span className={row.activationStatus ? "active" : ""}>
          {row.financeCalc.activate
            ? row.financeCalc.activate.cleaningFee
            : row.financeCalc.cleaningCost}
        </span>
      ),
    },

    {
      name: "Channel Fee",
      selector: "financeCalc.channelFee",
      sortable: false,
      format: (row) => (
        <span className={row.activationStatus ? "active" : ""}>
          {row.financeCalc.activate
            ? row.financeCalc.activate.channelFee
            : row.financeCalc.channelFee}
        </span>
      ),
    },
    {
      name: "Proc. Fee",
      selector: "financeCalc.processingFee",
      sortable: false,
      format: (row) => (
        <span className={row.activationStatus ? "active" : ""}>
          {row.financeCalc.activate
            ? row.financeCalc.activate.processingFee
            : row.financeCalc.processingFee}
        </span>
      ),
    },
    {
      name: "Mng. Fee",
      selector: "financeCalc.managementFee",
      sortable: false,
      format: (row) => (
        <span className={row.activationStatus ? "active" : ""}>
          {row.financeCalc.activate
            ? row.financeCalc.activate.managementFee
            : row.financeCalc.managementFee}
        </span>
      ),
    },
    {
      name: "CityTax",
      selector: "financeCalc.cityTax",
      sortable: false,
      format: (row) => (
        <span className={row.activationStatus ? "active" : ""}>
          {row.financeCalc.activate
            ? row.financeCalc.activate.cityTax
            : row.financeCalc.cityTax}
        </span>
      ),
    },
    {
      name: "StateTax",
      selector: "financeCalc.stateTax",
      sortable: false,
      format: (row) => (
        <span className={row.activationStatus ? "active" : ""}>
          {row.financeCalc.activate
            ? row.financeCalc.activate.stateTax
            : row.financeCalc.stateTax}
        </span>
      ),
    },
    {
      name: "Guest",
      selector: "guest.fullName",
      sortable: true,
      format: (row) => (
        <span className={row.activationStatus ? "active" : ""}>
          {row.guest.fullName}
        </span>
      ),
    },
  ];
};

const setResData = (res, period, year) => {
  console.log("CANCELED  setResData ", period, year);

  if (period === "annual") {
    return res;
  } else {
    res = res.filter(
      (el) =>
        moment(el.checkIn).format("MMM") === period &&
        moment(el.checkIn).format("YYYY") === year
    );
  }
  return res;
};

const openCResForm = (
  context,
  id,
  title,
  refresh,
  initData,
  updateLocalRefresh,
  notifyRefresh
) => {
  context.actions({
    type: "openModal",
    payload: {
      modalComponent: (
        <CResForm
          title={title}
          id={id}
          refresh={refresh}
          init={initData}
          onupdate={updateLocalRefresh}
          notifyRefresh={notifyRefresh}
        ></CResForm>
      ),
    },
  });
};

function CanceledTable(props) {
  const context = useContext(Context);

  const [list, setList] = useState(null);
  const [localRefresh, updateLocalRefresh] = useState(Math.random());

  useEffect(() => {
    const loadList = async function (time) {
      let dateString = time.startOf("month").format("YYYY-MM-DD");

      console.log("DATE STRING : ", dateString);
      let canceled_payload;

      try {
        canceled_payload = await FirebaseService.getListingCanceledData(
          props.id,
          dateString
        );
        if (context.state.isAdmin) {
          setList(
            Object.values(canceled_payload.data.reservations),
            props.bulk,
            props.year
          );
        } else {
          let filtered = Object.values(canceled_payload.data.reservations)
            .filter((res) => res.activationStatus == true)
            .map((line) => {
              line.activationStatus = false;
              return line;
            });

          console.log("FILTERED : ", filtered);
          setList(filtered, props.bulk, props.year);
        }
      } catch (e) {
        context.actions({
          type: "setState",
          payload: { ...context.state, isError: true, errorMsg: e.message },
        });
      }
    };

    loadList(props.time);
  }, [props.year, localRefresh]);

  return (
    <>
      {list !== null ? (
        <FinanceTable
          data={setResData(list, props.bulk, props.year)}
          title=""
          columns={get_reservations_columns(props.bulk)}
          onRowClick={(initData) => {
            if (context.state.isAdmin) {
              openCResForm(
                context,
                props.id,
                "Activate Reservation",
                props.refresh,
                initData,
                updateLocalRefresh,
                props.notifyRefresh
              );
            }
          }}
          paginationPerPage={5}
        ></FinanceTable>
      ) : (
        <>
          <div>Loading Data...</div>
        </>
      )}
    </>
  );
}

export default CanceledTable;

/*


    */
