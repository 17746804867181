import React, { useState, useEffect, useContext, useReducer } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Page, Content, PrimaryButton } from "../../theme//theme.js";
import { MetricBox } from "../../theme//theme.js";

const MenuBar = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.primaryDark};
  box-shadow: ${(props) => props.theme.darkGray} 1px 1px 2px;
  top: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
  z-index: 1001;
  height: 3.5rem;
  &.secondary {
    z-index: 100;
    top: 3.5rem;
    background-color: white;
    background-color: ${(props) => props.theme.white};
  }

  @media (max-width: 1024px) {
    position: fixed;
    background-color: ${({ theme }) => theme.primaryDark};
  }
`;

const ListingContent = styled.div`
  padding-top: 14vh;
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;
const ViewContent = styled(Content)`
  padding-top: 7rem;
  overflow-y: auto;
`;

//////REPORT COMPONENT/////////////////////
const ReportMenu = (props) => {
  return (
    <Page>
      <div className="top-menu-bar">
        <h1>Reports</h1>
      </div>
      <ViewContent>
        <Link to="/reports/summary">
          <MetricBox>
            <h2>Portfolio Summary</h2>
          </MetricBox>
        </Link>
        <Link to="/reports/export">
          <MetricBox>
            <h2>Export .CSV</h2>
          </MetricBox>
        </Link>
        <Link to="/reports/MonthlyTax">
          <MetricBox>
            <h2>Export Tax Report</h2>
          </MetricBox>
        </Link>
      </ViewContent>
    </Page>
  );
};

export default ReportMenu;
