import React, { useState, useContext } from "react";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";
import {
  Form,
  Input,
  Title,
  Text,
  Button,
  Label,
  Select,
} from "../../theme//theme.js";

function ListingSetDateForm(props) {
  const context = useContext(Context);

  const [startDate, setStartDate] = useState(new Date());

  const submitForm = async (values) => {
    context.actions({
      type: "setState",
      payload: { ...context.state, isFetching: true },
    });

    try {
      let dateString = values.month + "-" + values.year;
      let server_ok = await FirebaseService.updateBeginDate(
        props.listingId,
        dateString
      );
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      props.updateLocalListing(dateString);
    } catch (e) {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    }
  };

  return (
    <>
      <Title>{props.title}</Title>

      {/* FORMIK */}
      <Formik
        initialValues={
          !props.init
            ? {
                year: 2019,
                month: "Feb",
              }
            : props.init
        }
        validate={(values) => {
          console.log("VALIDATE : ", values);
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        enableReinitialize={false}
        validationSchema={yup.object().shape({
          month: yup.string().required(),
          year: yup.number().required(),
        })}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Label>
              Month
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.month}
                border={errors.month && "1px solid red"}
                type="string"
                name="month"
                placeholder="Expense Amount"
              >
                <option value="Jan">Jan</option>
                <option value="Feb">Feb</option>
                <option value="Mar">Mar</option>
                <option value="Apr">Apr</option>
                <option value="May">May</option>
                <option value="Jun">Jun</option>
                <option value="Jul">Jul</option>
                <option value="Aug">Aug</option>
                <option value="Sep">Sep</option>
                <option value="Oct">Oct</option>
                <option value="Nov">Nov</option>
                <option value="Dec">Dec</option>
              </Select>
            </Label>

            <Label>
              Year
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.year}
                border={errors.year && "1px solid red"}
                type="number"
                name="year"
                placeholder="Expense Amount"
                disabled={props.init !== undefined}
              >
                <option value={1980}>1980</option>
                <option value={2015}>2015</option>
                <option value={2016}>2016</option>
                <option value={2017}>2017</option>
                <option value={2018}>2018</option>
                <option value={2019}>2019</option>
                <option value={2020}>2020</option>
                <option value={2021}>2021</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
                <option value={2025}>2025</option>
                <option value={2026}>2026</option>
                <option value={2027}>2027</option>
                <option value={2028}>2028</option>
                <option value=""></option>
              </Select>
            </Label>

            <Button type="submit">Save</Button>
          </Form>
        )}
      </Formik>
      {/* END OF FORMIK */}
    </>
  );
}

export default ListingSetDateForm;
