import React, { createRef } from 'react';
import styled from "styled-components"


import NavBar from "./NavBar"
const MobileSide = styled.div`
position:fixed;
top:0;
left:-1px;
height:100vh;
z-index:900;
width:90%;
transition: all 0.3s linear;
transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
background-color:${({ theme }) => theme.primaryDark};
overflow:hidden;
@media (min-width: 1024px) {
    display:none;
  }
`
const Mask = styled.div`
background-color: ${({ theme }) => theme.darkGray};
z-index:899;
position: absolute;
top:100;
left:0;
transition: all 0.3s linear;
transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
width:100vw;
height:100%;
`;

const MobileMenu = ({ open, setOpen, state, logOutFunc }) => {

    return (
        <>
            <Mask open={open} >sdfsdfsdfsdfsd</Mask>
            <MobileSide open={open} onMouseDown={(e) => { e.preventDefault() }} id="mobilemenu">
                <NavBar state={state} func={logOutFunc} closeMe={setOpen}></NavBar>
                <Mask></Mask>
            </MobileSide>

        </>
    )
}
export default MobileMenu;