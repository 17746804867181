import DataTable from "react-data-table-component";
import React from "react";
import styled from "styled-components";
import moment from "moment";

const CustomTable = styled(DataTable)`
  & .rdt_TableCell {
    & .partial {
      color: red;
    }
    & .several {
      color: blue;
    }
    & .active {
      color: ${({ theme }) => theme.brandColor};
      font-weight: 900;
    }
    & .positive {
      color: ${({ theme }) => theme.posGreen};
      font-weight: 900;
    }
    & .small {
      font-size: 0.6rem;
    }
  }
  & .rdt_TableHeader {
    display: none;
  }

  & .rdt_Table {
    margin: 0;
  }
`;

const conditionalRowStyles = [
  {
    when: (row) => row.activationStatus === true,
    style: {
      backgroundColor: "DarkSeaGreen",
      color: "white",
    },
  },
];
function sortingDescByDate(props){
  if(props.title == 'Customer Payments'){
    let arr = [props.data][0];
    console.log(arr);
    arr.sort(function(a,b) { 
      return new Date(b.paidAt).getTime() - new Date(a.paidAt).getTime() 
    });
    console.log(arr);
  }
}
function sortingAscByDate(props){
  if(props.title == 'Customer Payments'){
    let arr = [props.data][0];
    console.log(arr);
    arr.sort(function(a,b) { 
      return new Date(a.paidAt).getTime() - new Date(b.paidAt).getTime() 
    });
    console.log(arr);
  }
}
function FinanceTable(props) {
  sortingDescByDate(props);
  console.log("FinanceTable", props);
  console.log(props.columns);
  console.log("props.sortFunction" , props.sortFunction);
  return (
    <div className="data-table">
      <CustomTable
        title={props.title}
        columns={props.columns}
        conditionalRowStyles={conditionalRowStyles}
        data={props.data}
        responsive={true}
        pagination={!props.noPagination && true}
        paginationPerPage={props.paginationPerPage || 10}
        dense={props.dense}
        fixedHeader={props.fixedHeader || false}
        paginationRowsPerPageOptions={
          props.paginationRowsPerPageOptions || [5, 10, 30,  50, 100]
        }
        highlightOnHover={true}
        sortFunction={sortingDescByDate(props)}
        onSort = {sortingDescByDate(props)}
        onRowClicked={(e) => (props.onRowClick ? props.onRowClick(e) : "")}
        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
          console.log("ONPAGE ", currentRowsPerPage, currentPage);
        }}
        onChangePage={(page, totalRows) => {
          console.log("ON PAGE CHANGE ", page, totalRows);
        }}
      />
    </div>
  );
}

export default FinanceTable;

/**
 *   onChangePage={() => {
          if (props.onChangePage) {
          }
        }}
 */
