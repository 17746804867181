import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import Context from "../../store/context";
import ReservationForm from "./ReservationForm";

const CalendarDisplay = styled.div`
  display: grid;
  width: 100%;
  align-content: center;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 30px repeat(auto-fit, minmax(60px, 1fr));
`;
const DayView = styled.div`
  border: 1px var(--darkgray) solid;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.white};
  height: 4rem;
  padding: 0;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  position: relative;
  height: 60px;
`;
const DayHead = styled.div`
  border: 1px var(--darkgray) solid;
  background-color: var(--darkgray);
  min-height: 20px;
  padding: 0;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  position: relative;
  color: var(--darkblue);
  font-weight: 300;
`;
const DayNumber = styled.h5`
  position: absolute;
  top: 5px;
  right: 10px;
  margin: 0;
  color: ${({ theme }) => theme.primaryDark};
`;
const DayPrice = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.darkGray};
  top: 10px;
  font-size: 0.7rem;
  left: 10px;
  @media (max-width: 768px) {
    top: 20px;
  }
`;

const ResSourceLabel = styled.div`
  position: absolute;
  color: ${({ color }) => color};
  top: 10px;
  font-size: 1rem;
  right: 15%;
  @media (max-width: 768px) {
    font-size: 0.7rem;
    top: 20px;
    right: 0%;
  }
`;

const ReservationCell = styled.div`
  position: absolute;
  background-color: var(--posgreen);
  height: 40%;
  width: 100%;
  bottom: 0;
  color: white;
  line-height: 1.4rem;
  z-index: 10;
  border-left: 2px var(--posgreen) solid;
  font-size: 0.8rem;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 0.6rem;
  }

  &.ending {
    width: 25%;
    border-top-right-radius: 20px;
    background-color: var(--posgreen);
    border-left: 2px var(--posgreen) solid;
    @media (max-width: 768px) {
      width: 20%;
      background-color: var(--posgreen);
    }
  }
  &.start {
    width: 60%;
    border-bottom-left-radius: 20px;
    left: 40%;
    background-color: var(--posgreen);
    border-left: 2px ${({ theme }) => theme.primaryDark} solid;
    @media (max-width: 768px) {
      width: 75%;
      left: 25%;
      border-bottom-left-radius: 12px;
    }
  }
  & .partial {
    color: ${({ theme }) => theme.brandColor};
  }
`;

const colorRange = ["green", "red", "blue", "purple", "orange"];
let colorSlicer = [...colorRange];
let colorMap = {};

//We fill the calendar with days to fit the week structure and then assign the reservation data from cal data
const prepareCalendar = (time, calData) => {
  console.log(calData);
  let days = [];
  //let dateString = time.year() + "/" + (Number(time.month()) + 1) + "/" + 1
  let startDate = time.startOf("month").clone();

  const numOfDays = startDate.daysInMonth();

  for (let i = 0; i < numOfDays; i++) {
    let runner = moment(startDate);

    let dayNum = runner.add(i, "days");
    //log(runner.format("YYYY-MM-DD"));
    days.push({
      dayNum: dayNum.date(),
      calDataObj: calData[runner.format("YYYY-MM-DD")],
    });
  }

  let runnerBack = moment(startDate);
  while (
    runnerBack.weekday() != 0 &&
    startDate.diff(runnerBack, "days") < startDate.weekday()
  ) {
    let dayNum = runnerBack.subtract(1, "days");
    days.unshift({
      dayNum: dayNum.date(),

      calDataObj: calData[dayNum.format("YYYY-MM-DD")],
    });
  }

  let runnerForward = moment();
  runnerForward = moment(startDate.add(numOfDays - 1, "days"));
  // console.log("RUNNER FORWARD ", runnerForward);

  while (
    runnerForward.weekday() != 6 &&
    runnerForward.diff(startDate, "days") < 6
  ) {
    let dayNum = runnerForward.add(1, "days");
    days.push({
      dayNum: dayNum.date(),
      calDataObj: calData[dayNum.format("YYYY-MM-DD")],
    });
  }
  return days;
};

const generateColor = function (key) {
  if (!colorMap[key]) {
    colorMap[key] = colorSlicer.length > 0 ? colorSlicer.pop() : "black";
  }
  return colorMap[key];
};
const getStringSourceByKey = function (key){
  let res = key;
  if(key.toString().includes('.com')){
    if(key.toString().includes('@')){
      res = key.split('@')[1].split('.')[0];
    }
    else{
      res = key.split('.')[0];
    }
  }
  return res;
}
export default function Calendar(props) {
  console.log(props);
  var source;
  // const [days, setDays] = useState([])
  const [resData, setRes] = useState(props.resData);
  const context = useContext(Context);
  let calData = props.calData;
  var source;
  let days = prepareCalendar(props.time, calData);
  //console.log(days);

  /* useEffect(() => {
         let calData = props.calData
 
         setDays(prepareCalendar(props.time, calData))
         console.log("CALENDAR : reservations", props.resData)
     }, [props.time])*/

  return (
    <CalendarDisplay>
      <DayHead>Sun</DayHead>
      <DayHead>Mon</DayHead>
      <DayHead>Tue</DayHead>
      <DayHead>Wed</DayHead>
      <DayHead>Thu</DayHead>
      <DayHead>Fri</DayHead>
      <DayHead>Sat</DayHead>
      {days.map((el, index, data) => (
        <DayView key={index}>
          <DayNumber>{el.dayNum}</DayNumber>
          {
          el.calDataObj ? (
            el.calDataObj.status === "booked" &&
            props.resData[el.calDataObj.reservationId] ? (
              <>
                <ResSourceLabel
                  color={generateColor(
                    props.resData[el.calDataObj.reservationId].source
                  )}
                >
                  {el.calDataObj.checkIn !== undefined || index === 0
                    ?
                    source = getStringSourceByKey(props.resData[el.calDataObj.reservationId].source)
                    : ""}
                </ResSourceLabel>
                <ReservationCell
                  className={el.calDataObj.checkIn !== undefined ? "start" : ""}
                  onClick={() => {
                    context.actions({
                      type: "openModal",
                      payload: {
                        modalComponent: (
                          <ReservationForm
                            res={props.resData[el.calDataObj.reservationId]}
                            time={props.time}
                          ></ReservationForm>
                        ),
                      },
                    });
                  }}
                >
                  <span
                    className={
                      props.resData[el.calDataObj.reservationId].financeCalc &&
                      (props.resData[el.calDataObj.reservationId].financeCalc[
                        props.time.format("YYYY")
                      ] === undefined ||
                        props.resData[el.calDataObj.reservationId].financeCalc[
                          props.time.format("YYYY")
                        ].annual.rate < 1)
                        ? "partial"
                        : ""
                    }
                  >
                    {(el.calDataObj.checkIn !== undefined || index === 0) &&
                    props.resData[el.calDataObj.reservationId].financeCalc !==
                      undefined
                      ? "$" +
                        (props.resData[el.calDataObj.reservationId].financeCalc[
                          props.time.format("YYYY")
                        ]
                          ? Number(
                              props.resData[el.calDataObj.reservationId]
                                .financeCalc[props.time.format("YYYY")].annual
                                .breakdown.income
                            )
                          : 0
                        ).toFixed(0) +
                        (props.resData[el.calDataObj.reservationId].financeCalc[
                          props.time.format("YYYY")
                        ] === undefined ||
                        props.resData[el.calDataObj.reservationId].financeCalc[
                          props.time.format("YYYY")
                        ].annual.rate < 1
                          ? " (*)"
                          : "")
                      : ""}
                  </span>
                </ReservationCell>
                {el.calDataObj.checkOut !== undefined ? (
                  <ReservationCell
                    className="ending"
                    onClick={() => {
                      context.actions({
                        type: "openModal",
                        payload: {
                          modalComponent: (
                            <ReservationForm
                              res={props.resData[el.calDataObj.checkOut.resId]}
                              time={props.time}
                            ></ReservationForm>
                          ),
                        },
                      });
                    }}
                  ></ReservationCell>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <DayPrice>${el.calDataObj.price}</DayPrice>
                {el.calDataObj.checkOut !== undefined ? (
                  <ReservationCell
                    className="ending"
                    onClick={() => {
                      context.actions({
                        type: "openModal",
                        payload: {
                          modalComponent: (
                            <ReservationForm
                              res={props.resData[el.calDataObj.checkOut.resId]}
                              time={props.time}
                            ></ReservationForm>
                          ),
                        },
                      });
                    }}
                  ></ReservationCell>
                ) : (
                  <></>
                )}
              </>
            )
          ) : (
            <></>
          )}
        </DayView>
      ))}
    </CalendarDisplay>
  );
}
