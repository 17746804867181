import React, { useState } from "react";
import styled from "styled-components";
import { Form, Input, Title, Text, Button, Label } from "../../theme//theme.js";
import { Formik } from "formik";
import * as yup from "yup";

import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import FirebaseService from "../../services/FirebaseService.js";

const ForgotPassword = styled.div`
  margin: auto;
  padding: 1rem;
  cursor: pointer;
  color: blue;
`;

const Email = styled.div`
  margin: auto;
  padding: 1rem;
  color: ${({ theme }) => theme.darkBlue};
`;
function LoginForm(props) {
  let history = useHistory();
  let location = useLocation();
  const [emailSent, setSending] = useState(null);

  let { from } = location.state || { from: { pathname: "/" } };

  let login = async () => {
    console.log("signin");
    history.replace(from);
    props.signin();
  };
  return (
    <div>
      {emailSent === null ? (
        <>
          <Title>{props.title}</Title>
          {/* FORMIK */}
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              console.log("validates");
            }}
            onSubmit={(values) => {
              props.signin(values);
            }}
            validationSchema={yup.object().shape({
              email: yup.string().email().required(),
              password: yup.string().min(6).required(),
            })}
            render={({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Label>
                  Email *
                  {errors.email && <Text color="red">{errors.email}</Text>}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    border={errors.email && "1px solid red"}
                    type="email"
                    name="email"
                    placeholder="Email"
                  />
                </Label>
                <Label>
                  Password *
                  {errors.password && (
                    <Text color="red">{errors.password}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    border={errors.password && "1px solid red"}
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </Label>
                <Button type="submit">Submit</Button>
              </Form>
            )}
          />
          {/* END OF FORMIK */}
          <ForgotPassword>
            <a>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  let email = window.prompt("Please enter your email address");
                  FirebaseService.forgotPassword(email);
                  setSending(email);
                }}
              >
                Forgot your password?
              </div>
            </a>
          </ForgotPassword>
        </>
      ) : (
        <div>
          An email with password renewal code was sent to
          <Email>{emailSent}</Email>
          <ForgotPassword
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSending(null);
            }}
          >
            Login
          </ForgotPassword>
        </div>
      )}
    </div>
  );
}

export default LoginForm;
