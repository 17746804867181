import React, { useState, getState ,useContext, useRef } from "react";
import ExpenseForm from "../Listings/ExpenseForm";
import Context from "../../store/context";
import styled from "styled-components";
import {
  Form,
  Input,
  Title,
  Text,
  Button,
  Label,
  Select,
  PrimaryButton,
} from "../../theme//theme.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";


const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.negRed};
  align-self: flex-end;
`;
const ReturnButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.grey};
  color: ${({ theme }) => theme.blue};
  align-self: flex-end;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

const openExpenseForm = (context, id, title, refresh, initData , action) => {  
  context.actions({
    type: "openModal",
    payload: {
      modalComponent: (
        <ExpenseForm
          title={title}
          id={id}
          refresh={refresh}
          init={initData}
          action = {action}
        ></ExpenseForm>
      ),
    },
  });
};

function PopupExpensesFiles(props) {
  const [title, setTitle] = useState(props.eporps.title);
  const context = useContext(Context);
  const [action , setAction] = useState(0);
  let [oldImages, setOldImages] = useState(props.eporps.init ? props.eporps.init.media : []);
  console.log(oldImages);
  const setVal = (val) =>{
    setAction(val);
    console.log(action);
    if(val == 2){
      let a = window.confirm("Are you sure you want to delete this image?");
      if(a){
        let newImage = [];
        let i =0;
        for (let index = 0; index < oldImages.length; index++) {
          if(oldImages[index] !== props.url){
            newImage[i] = oldImages[index];
            i++;
          }          
        }
        console.log(newImage);
        props.eporps.init.media = newImage;
      }
    }
    console.log(props.eporps);
    openExpenseForm(context , props.eporps.id , props.eporps.title , props.eporps.refresh , props.eporps.init , action);
  }
  console.log(action);
  return (
    <div>
    <ButtonsContainer>
   
    <PrimaryButton style={{margin:"5px"}} type="button" onClick={async()=> {window.open(props.url)}}>Open File</PrimaryButton>
    <DeleteButton style={{margin:"5px"}} type="button" onClick={async()=>{setVal(2)}}> Delete File</DeleteButton>
    <ReturnButton style={{margin:"5px"}} type="button" onClick={async()=>{setVal(3)}}>Return</ReturnButton>
    </ButtonsContainer>
  </div>
  );
}
export default PopupExpensesFiles;
