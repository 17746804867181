
import React, { Component,ReactDOM , useEffect  } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faArrowRight, faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import styled from 'styled-components';
import $ from "jquery"

const myImage = styled.iframe`
'width': '100%',
'height': '90%'
`;

const myHeader = (state, previousDocument, nextDocument) => {
    var isThisPdf = false;
    var isNotPdf = true;
    var documentNumber = state.currentFileNo + 1;
    var NumberOfDocuments = state.documents.length;
    var type = GetFileTypeByPath(state.currentDocument.uri);
    console.log(type);
    var fileName = "";
    if(type == 'pdf'){
        isThisPdf = true;
    }
    
    const openFullScreen = () => {
        window.open(state.currentDocument.uri);
    }
    const downloadDoc = () => {
        downloadImage(state.currentDocument.uri, fileName , type);
    }
    const setPdfSize = ()=>{
        console.log("setPdfSize");
        document.getElementById("react-pdf__Page__canvas").style.width = '800px';
        document.getElementById("react-pdf__Page__canvas").style.width = '1000px';
    }
    
    const setNotLoading = ()=>{
        state.documentLoading = false;
        // console.log("isThisPdf" , isThisPdf);
        // console.log("type" , type);
        if(type !== 'pdf'){
            if(document.getElementById("image-img") !== undefined){
                document.getElementById("react-doc-viewer").style.height = '600px';
                document.getElementById("react-doc-viewer").style.width = '600px';
                if(document.getElementById("loading-renderer") !==null){
                    document.getElementById("loading-renderer").style.visibility = 'hidden';
                }
                
            }
         
        }    
        else{
            document.getElementById("react-pdf__Page__canvas").style.width = '500px';
            document.getElementById("react-pdf__Page__canvas").style.width = '1000px';
        }
        
    }
    return (
        <>            
            <div id='main' style={{height:'105px'}}>
                <div id='headerP'>
                    <p>
                        Doc {documentNumber} From {NumberOfDocuments}
                    </p>
                </div>
                <div id='buttonim'>
                    <div className='buttons'>
                    <button
                        onClick={previousDocument}
                        disabled={state.currentFileNo === 0}
                    >
                        Previous Document
                    </button>
                    <button
                        onClick={nextDocument}
                        disabled={state.currentFileNo >= state.documents.length - 1}
                    >
                        Next Document
                    </button>
                    <div className='icons' style={{marginTop:'10px'}}>
                    <FontAwesomeIcon style={{marginRight:'5px'}} icon={faExpand} size={'1x'} id={'expand'}
                        onClick={openFullScreen}
                    >
                    </FontAwesomeIcon>
                    {!isThisPdf?(<>
                    <FontAwesomeIcon style={{marginLeft:'5px'}} icon={faDownload} size={'1x'} id={'expand'}
                    onClick={downloadDoc}
                    >
                    </FontAwesomeIcon>
                    <iframe style={{width:"1000px" , height:'1200px'}}
                    id='myFrameImg'
                    srcDoc={`<html style=max-width:100%;max-height:100%;height:1000px><body style=width:500px;height:500px;max-width:95%;max-height:95%;padding-left:30px><img style=width:1000px;height:1200px;max-width:95%;max-height:95% src=${state.currentDocument.uri}></body></html>`}
                    onLoad={setNotLoading}> 
                    </iframe>                  
                    </>):
                    <>
                    {setPdfSize}
                    </>}        
                    </div>

                    </div>
                </div>                
            </div>
        </>
    );
};

export default myHeader;
function downloadImage(src, fileName , type) {
    console.log(src);
    console.log(fileName);
    const img = new Image();
    img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
    // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
    img.src = src;
    img.onload = () => {
        // create Canvas
        const canvas = document.createElement('canvas');
        console.log(canvas);
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        console.log(ctx);
        console.log(canvas);
        // create a tag
        const a = document.createElement('a');
        console.log(a);
        a.download = fileName +"." + type;
        a.href = canvas.toDataURL('image/' + type);
        a.click();
    };
}


function GetFileTypeByPath(path){
    let res;
    if(String(path).toLocaleLowerCase().includes('pdf')){
        res = "pdf";
    }
    else if(String(path).toLocaleLowerCase().includes('jpg')){
        res = "jpg";
    }
    else if(String(path).toLocaleLowerCase().includes('jpeg')){
        res = "jpeg";
    }
    else if(String(path).toLocaleLowerCase().includes('png')){
        res = "png";
    }
    else if(String(path).toLocaleLowerCase().includes('xlsx')){
        res = "xlsx";
    }
    else if(String(path).toLocaleLowerCase().includes('docs')){
        res = "docs";
    }
    return res;
}
