import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ToggleButton = styled.div`
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  & input[type="checkbox"] {
    display: none;
  }
  & label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: 20px;
    margin: 0;

    & .toggle-switch-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease -in 0s;
      & ::before {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
        content: attr(data - yes);
        text-transform: uppercase;
        padding-left: 10px;
        background-color: var(--gnorange);
        color: #fff;
      }
      & ::after {
        display: block;
        float: left;
        width: 50%;
        height: 34px;
        padding: 0;
        line-height: 34px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        box-sizing: border-box;
        content: attr(data - no);
        text-transform: uppercase;
        padding-right: 10px;
        background-color: #ccc;
        color: #fff;
        text-align: right;
      }
    }
    & .toggle-switch-switch {
      display: block;
      width: 24px;
      margin: 5px;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 40px;
      border: 0 solid #ccc;
      border-radius: 20px;
      transition: all 0.3s ease -in 0s;
    }
  }

  @media (max-width: 991px) {
    transform: scale(0.9);
  }
  @media (max-width: 767px) {
    transform: scale(0.825);
  }
  @media (max-width: 575px) {
    transform: scale(0.75);
  }
`;

const ToggleSwitch = (props) => {
  const [checked, setChecked] = useState(props.defaultChecked);

  const onChange = (e) => {
    const val = e.target.checked;
    setChecked(e.target.checked);
    if (typeof props.onChangeHandler === "function") {
      const timer = setTimeout(() => {
        props.onChangeHandler(val);
      }, 200);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    setChecked(props.defaultChecked);
  }, [props.defaultChecked]);

  return (
    <div className={props.isBig ? "toggle-switch big-switch" : "toggle-switch"}>
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={props.Name}
        id={props.Name}
        checked={checked}
        defaultChecked={props.defaultChecked}
        onChange={onChange}
        disabled={props.disabled}
      />
      <label className="toggle-switch-label" htmlFor={props.Name}>
        <span
          className="toggle-switch-inner"
          data-yes={props.dataYes}
          data-no={props.dataNo}
        />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  );
};
export default ToggleSwitch;
