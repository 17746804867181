import React, { useState, useEffect, useContext } from "react";
import UserList from "./UserList";
import UserForm from "./UserForm";
import styled from "styled-components";
import { PrimaryButton, Page, Content } from "../../theme/theme.js";
import Context from "../../store/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const ViewContent = styled(Content)`
  padding-top: 4.5rem;
  overflow-y: auto;
`;

const MenuBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.darkGray} 1px 1px 2px;
  top: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
  z-index: 100;
  height: 3.5rem;
  justify-content: flex-end;
  padding: 0 1rem;
  @media (max-width: 1024px) {
    position: fixed;
  }
  @media (max-width: 768px) {
    padding: 0 0.5rem; // Adjust padding for smaller screens
  }
`;
const SearchBar = styled.div`
  height: 45px;
  width: 60%;
  background-color: white;
  border-radius: 40px;
  line-height: 45px;
  border: 1px var(--darkgray) solid;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 1.5rem;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-right: 200px; 
  @media (max-width: 768px) {
    width: 40%; // Reduce width for smaller screens
    margin-right: 10px; // Adjust margin
  }
`;

const SearchInput = styled.input`
  width: 70%;
  line-height: 24px;
  font-size: 1.2rem;
  -webkit-appearance: none;
  border: none;
  margin-left: 8px;
  @media (max-width: 768px) {
    width: 100%; 
  }
`;


const divStyle = {
  width: '100%',
  marginBottom:'15px',
  height: '30px',
  size: '35px',
  border: '0px',
  borderradius: '10px',
  lineheight: '20px',
  background: '#FFE4B5',
  //color: '#930'
};

const Users = (props)=> {
  console.log(props);
  const context = useContext(Context);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [popupComponent, setPopupComponent] = useState(<></>);
  const [refresh, setRefresh] = useState(Date.now());
  const [searchInput, setSearchInput] = useState(""); // State to track input value

  const [filteredData, setFiltered] = useState(props.users);
  console.log(filteredData.length);
  //useEffect(() => {
    // setFiltered(props.users);
  //}, [props.users]);
  //useEffect(() => {
    // componentDidMount logic
    // props.getUsers();
    // props.loadListings();
  //}, []);


  // const handleInputChange = (event) => {
  //   console.log("Input Changed: ", event.target.value);
  //   const inputValue = event.target.value;
  //   const regex = new RegExp(inputValue, "i");

  //   // Filter the user list based on the input value
  //   const filtered = props.users.filter(function (datum) {
  //     return datum.displayName.search(regex) > -1;
  //   });

  //   // Update the filteredData state with the filtered list
  //   setFiltered(filtered);
  // };
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // Use useEffect to filter the user list whenever input or props.users changes
  useEffect(() => {
    const regex = new RegExp(searchInput, "i");
    const filtered = props.users.filter(function (datum) {
      return datum.displayName.search(regex) > -1;
    });
    setFiltered(filtered);
  }, [searchInput, props.users]);

  return (
    <Page>
      <div className="top-menu-bar">
        <MenuBar>
          <SearchBar>
            <FontAwesomeIcon icon={faSearch} />
            <SearchInput
              type="text"
              placeholder="Search"
              value={searchInput} // Bind input value to searchInput state
              onChange={handleInputChange}
            />
          </SearchBar>

          <PrimaryButton
            className="button raised primary link"
            onClick={() => {
              console.log("PRIMARY BUTTON WAS CLICKED");
              context.actions({
                type: "openModal",
                payload: {
                  modalComponent: (
                    <UserForm
                      title="Create New User"
                      onClose={() => {
                        context.actions({
                          type: "setState",
                          payload: {
                            ...context.state,
                            isModalOpen: false,
                          },
                        });
                        console.log("Closing Window \n\n", context.state);
                        setRefresh(Date.now());
                      }}
                    ></UserForm>
                  ),
                },
              });
            }}
          >
            Create User
          </PrimaryButton>
        </MenuBar>
      </div>

      <Content>
        <UserList
          users={filteredData}
          list={props.list}
          type="Users"
          listings={props.listings}
          refresh={refresh}
          onClose={() => {
            console.log("Closing Window");
            context.actions({
              type: "setState",
              payload: { ...context.state, isModalOpen: false },
            });
            setRefresh(Date.now());
          }}
        ></UserList>
      </Content>
    </Page>
  );
};

export default Users;