import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import FirebaseService from "../../services/FirebaseService";
import moment from "moment";
import { PrimaryButton } from "../../theme/theme";
import Context from "../../store/context";
import PQueue from "p-queue";
import { array, date, object, string } from "yup";
import {firebase , collection , get} from "firebase";
const { Parser } = require("json2csv");

const exportCsv = function (data) {
  
  const fields = ["Property ID","Property","Nick Name", "Ownership", "Period", "Nights Rented", "Total Income","City Tax" , "State Tax", "Taxable Revenue", "Total Taxes","Street Address","City", "State", "Zip Code" , "State Filling Field" , "City Filling Field" ,"County Filling Field","property_internal_id" ];
  const opts = { fields };  
  try { 
    const parser = new Parser(opts);
    const csv = parser.parse(data);
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "_report.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  } catch (err) {
    console.error(err);
  }
};

const formateConvert = function(str){
  var date = new Date(str),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);
return [date.getFullYear(), mnth, day].join("/");
}
async function getListingNickNameByListingId(id){
  var nickname = await FirebaseService.getListingById(id);
  console.log(nickname);
  return nickname;
}
const generateCSVData = async (obj, year , month , dbListingId)=> {
  let nick = await getListingNickNameByListingId(dbListingId)
  console.log(nick);
  console.log("thisObj" , obj);
  var DateMonthVar = new Date(month);
  var nextMonthVar =new Date(DateMonthVar.getFullYear(), DateMonthVar.getMonth() + 1);
  let entireData = [];  
  let configTaxRate = obj.months[month.format("MMM")].taxRate;
  console.log("year", year);
  let StateFillingField =GetFillingFieldNameByNumber(obj.months[month.format("MMM")].StateFillingField);
  console.log("StateFillingField", StateFillingField);
  let CityFillingField =GetFillingFieldNameByNumber(obj.months[month.format("MMM")].CityFillingField);
  console.log("StateFillingField", CityFillingField);
  let CountyFillingField =GetFillingFieldNameByNumber(obj.months[month.format("MMM")].CountyFillingField);
  console.log("StateFillingField", CountyFillingField);
  let Ownership =obj.months[month.format("MMM")].Ownership;
  console.log("StateFillingField", Ownership);
  console.log("obj.title" , obj.title);
  console.log(dbListingId);
  let avalaraProperyId = obj.months[month.format("MMM")].AvalaraProperyId;
  var today = new Date(month);
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0); 
  let allmonths = Object.keys(obj.months);
  let TotalIncome;
  let tempo = 0;  
  let values;
  allmonths.forEach((period) =>{
    if(period === month.format("MMM") && tempo===0){   
      values = Object.keys(obj.months[period]).map((el) => {
        let json = obj.reservations;
        console.log(obj.months[month.format("MMM")]);
        console.log(obj);
        console.log(obj.expenses[month.format("MMM")]);
        console.log(month.format("MMM"));
        let totalNights = new Date(DateMonthVar.getFullYear(), DateMonthVar.getMonth()+1 ,0).getDate();
        let countNight =roundToTwo( (obj.months[month.format("MMM")].occupanceRate / 100)  * totalNights);
        TotalIncome = obj.months[month.format("MMM")].income.amount;        
        let cityTax = 0;
        let stateTax = 0;
        if(obj.months[month.format("MMM")].expenses.distribution["City tax"] != undefined){
          cityTax += obj.months[month.format("MMM")].expenses.distribution["City tax"];
        }
        if(obj.expenses[month.format("MMM")] !== undefined && obj.expenses[month.format("MMM")].distribution['City tax'] != undefined){
          cityTax += obj.expenses[month.format("MMM")].distribution['City tax'].amount;
        }
        
        if(obj.months[month.format("MMM")].expenses.distribution["State tax"] != undefined){
          stateTax += obj.months[month.format("MMM")].expenses.distribution["State tax"];
        }
        if(obj.expenses[month.format("MMM")] !== undefined && obj.expenses[month.format("MMM")].distribution['State tax'] != undefined){
          stateTax += obj.expenses[month.format("MMM")].distribution['State tax'].amount;
        }
        let TotalTaxes = cityTax + stateTax;      
        let TaxableRevenue = TotalTaxes/configTaxRate;
        var splitAddress = String(obj.title).split(',');
        console.log("splitAddress" , splitAddress);
        let zipCode = splitAddress[2].replace(/\D/g, "");
        console.log("zipCode", zipCode);
        return {
          "Property ID": avalaraProperyId,
          "Property": obj.title,
          "Nick Name" : nick,
          "Ownership" : Ownership ,
          "Period" : formateConvert(today) + "-" + formateConvert(lastDayOfMonth),
          "Nights Rented" :countNight,
          "Total Income" : obj.months[month.format("MMM")].income.amount,
          "City Tax": cityTax,
          "State Tax" : stateTax,
          "Taxable Revenue": TaxableRevenue,
          "Total Taxes" : TotalTaxes,
          "Street Address" :splitAddress[0],
          "City" :splitAddress[1],
          "State" :splitAddress[2].replace(/[0-9]/g, ''),
          "Zip Code" :zipCode, 
          "State Filling Field" : StateFillingField,
          "City Filling Field" : CityFillingField,
          "County Filling Field" : CountyFillingField,
          "property_internal_id" : obj.goldnest_internal_id
        }
      })
     tempo++;
    }
    
  })
  entireData =await values[0];
  return  entireData;
};

function GetFillingFieldNameByNumber(number){
  let res;
  switch (number) {
    case 1:
      res = "Monthly";
      break;
      case 2:
      res = "Quarterly";
      break;
      case 3:
      res = "N/A";
      break;
    default:
      res = "N/A"
      break;
  }
  return res;
}
function roundToTwo(num) {
  return Number(Math.round(num + "e+2") + "e-2");
}
const TaxReport = (props) => {
  console.log("propsi" , props);
  console.log(props.time.startOf("month").format("YYYY-MM"));
  console.log(props.time.format("MMM"));
  console.log(props.time);
  console.log(props.time.format("YYYY"));
  const [selectMonth , setMonth] = useState(props.time);
  const [loading, setLoading] = useState(0);
  const [isDone, setDone] = useState(false);
  const [data, setData] = useState([]);
  const context = useContext(Context);
  const loadingBar = useRef(null);
  const isDualYear = !props.isExport;
  const queue = new PQueue({ concurrency: 1 });

  useEffect(() => {
    //alert("Queue:" + queue.concurrency);
    try {
      const loadReport = async (list) => {
        console.log("list",list);
        let reportData = await loadReportData(list);
      };
      loadReport(props.list);
    } catch (e) {
      console.log("UseEffect Error catch");
    }
  }, [props.list]);
  const loadReportData = async (listigIds) => {
    try {
      console.log(listigIds);
      let promises = [];
      let counter = 0;
      let newData = [];
      let tempData;
      let resCurrent = [];
      let tempNum = 0;
      let  indexer = 0;
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      let myVar = [];
      console.log(listigIds);
      //USING QUEUE API
      let configuration = [];
      queue.on("completed",async (res) => {
        console.log(res);
        if (isDualYear) {
          console.log("isDualYear")
          res[0].data.prev = res[1].data;
          newData[counter] = res[0].data;
        }

        console.log(res);
        //newData = res;        
        counter++;
        // indexer=0;        
        setLoading((counter / temp.length) * 100);
        if (props.isExport === true) {
          //CSV GENERATION - TODO
          //setTimeout(15000);
          console.log(newData);
          console.log(res);
          if(res !== undefined){
            console.log("res[0].data:" , res[0].data);
            console.log(res[0].data);
            if(res.length >1){
              console.log(res[1].data);
            }
            console.log(res);
            newData[counter] = await generateCSVData(res[0].data, props.time.year() , selectMonth ,res[0].listingId);
            console.log("newdata" , newData);
            console.log("newdata" , newData);
            console.log(newData[1].object);
            setData(newData);
          }
          
        } else {
          if(res !== undefined){
            console.log("...res[0].data",{ ...res[0].data});
            resCurrent.push({ ...res[0].data, id: res[0].listingId });
          }
          
        }
        if (temp.length===counter) {
          console.log("QUEUE  :  COMPLETE", counter);
          if (props.isExport === true) {
            console.log("newdatabefore:" , newData);
            setData(newData);
            console.log("newDate" , newData);
          } else {
            console.log("rescurrent:" , resCurrent);
            props.onLoad(resCurrent);
            console.log("Report is Done!!!", resCurrent);
            context.actions({
              type: "setState",
              payload: {
                ...context.state,
                isFetching: false,
                isModalOpen: props.isExport,
                modalComponent: "",
              },
            });
          }
          setDone(true);
        }        
      });      
      var resqueue =queue.on("active", async()  => {
         console.log(
          `Working on item #${counter}.  Size: ${queue.size}  Pending: ${queue.pending}`
        );
      });
      console.log(resqueue);
      queue.on("error", (e) => {
        console.log(e);
        queue.pause();
        queue.clear();
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isFetching: false,
            isModalOpen: false,
            modalComponent: "",
            isError: true,
            errorMsg:
              "There was a communication error while requesting data from Guesty's server : " +
              e.message,
          },
        });
      });
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      let temp = listigIds.map(async (el, index) => {
        queue.add(
          async () => {
            try {
              return await FirebaseService.getListingAnnualPerformanceData2(
                el,
                props.time.startOf("month").format("YYYY-MM"),
                true,
                isDualYear
              );
            } catch (e) {
              console.log(e);
              context.actions({
                type: "setState",
                payload: {
                  ...context.state,
                  isFetching: false,
                  isModalOpen: false,
                  modalComponent: "",
                  isError: true,
                  errorMsg: e.message,
                },
              });
            }
          },
          { priority: 0 }
        );
      });
      console.log("QUEUE TOTAL SIZE : ==> ", queue.size);
      console.log("temp" , temp);
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
          isError: true,
          errorMsg: "There was a communication error with Guesty's server",
        },
      });
    }
  };
function GetData(){
  return data;
}
  return (
    <>
      <div>
        {!isDone ? (
          <>
            <div>
              <h1>Do not close this window!</h1>
            </div>
            <div>
              <h1>{loading.toFixed(1)}%</h1>
              <h1> Loading in progress...</h1>
            </div>
            <div className="lds-hourglass"> </div>
          </>
        ) : (
          <>
            <div>
              <h1>Loading was completed</h1>
            </div>
            <PrimaryButton onClick={(e) =>exportCsv(data)}>
              Download report
            </PrimaryButton>
          </>
        )}
      </div>
    </>
  );
};

export default TaxReport;
