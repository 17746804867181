import React, { useState, useContext, useRef } from "react";
import {
  Form,
  Input,
  Title,
  Text,
  Button,
  Label,
  Select,
  PrimaryButton,
} from "../../theme//theme.js";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";

import DatePicker from "react-datepicker";

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  border: 1px solid ${({ theme }) => theme.darkGray};
  margin: 5px;
  & img {
    max-width: 33%;
    max-height: 40px;
    margin: 5px;
  }
`;

const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.negRed};
  align-self: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

function IncomeForm(props) {
  const context = useContext(Context);

  const [startDate, setStartDate] = useState(new Date());

  const submitForm = async (values) => {
    let res;
    try {
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });
      if (props.init == null) {
        res = await FirebaseService.createIncome(values, props.id);
      } else {
        res = await FirebaseService.updateIncome(values);
      }

      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      console.log(res);
      props.refresh(Math.random());
    } catch (e) {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    }
  };

  const deleteIncome = async (id) => {
    try {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: true,
          isModalOpen: true,
          modalComponent: "",
        },
      });
      let res = await FirebaseService.deleteIncome(id);
      console.log(res);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      props.refresh(Math.random());
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
        },
      });
    }
  };

  return (
    <>
      <Title>{props.title}</Title>

      {/* FORMIK */}
      <Formik
        initialValues={
          !props.init
            ? {
                amount: 0,
                type: "Claim Payout",
                doneBy: "",
                paidAt: startDate,
              }
            : props.init
        }
        validate={(values) => {
          console.log("VALIDATE : ", values);
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        enableReinitialize={true}
        validationSchema={yup.object().shape({
          amount: yup.number().required(),
          doneBy: yup.string().required(),
        })}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Label>
              Amount
              {errors.amount && <Text color="red">{errors.amount}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.amount}
                border={errors.amount && "1px solid red"}
                type="number"
                name="amount"
                placeholder="Expense Amount"
              />
            </Label>
            <Label>
              Done By
              {errors.doneBy && touched.doneBy && (
                <Text color="red">{errors.doneBy}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.doneBy}
                border={errors.doneBy && "1px solid red"}
                type="text"
                name="doneBy"
                placeholder="Done by.."
              />
            </Label>

            <Label>
              Paid at
              <DatePickerField
                name="paidAt"
                value={values.paidAt}
                onChange={setFieldValue}
              />
            </Label>
            <Label>
              Type
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.type}
                border={errors.type && "1px solid red"}
                type="text"
                name="type"
                placeholder="Expense Amount"
              >
                <option value="Claim Payout">Claim Payout</option>
                <option value="Pet Fee">Pet Fee</option>
                <option value="Extra Guest Fee">Extra Guest Fee</option>
                <option value="Other Income">Other Income</option>
              </Select>
            </Label>

            <ButtonsContainer>
              <PrimaryButton type="submit">Save</PrimaryButton>
              {props.init !== null ? (
                <DeleteButton
                  type="button"
                  onClick={async () => {
                    let a = window.confirm(
                      "Are you sure you want to delete the payment?"
                    );
                    if (a) {
                      try {
                        await deleteIncome(props.init.incomeId);
                        props.refresh();
                      } catch (e) {
                        console.log(e);
                        context.actions({
                          type: "setState",
                          payload: {
                            ...context.state,
                            isError: true,
                            errorMsg: e.message,
                          },
                        });
                      }
                    }
                  }}
                >
                  DELETE
                </DeleteButton>
              ) : (
                <></>
              )}
            </ButtonsContainer>
          </Form>
        )}
      </Formik>
      {/* END OF FORMIK */}
    </>
  );
}

export default IncomeForm;
