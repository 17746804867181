import React from 'react';

import styled from "styled-components";
import { Page, Content } from "../../theme//theme.js";

import {
    BrowserRouter as Router,
    useHistory,
    useLocation
} from "react-router-dom";
import LoginForm from './LoginForm';


function Login(props) {


    return (
        <Page>
            <Content>
                <LoginForm title="Login" authSource={props.authSource} cb={props.cb}></LoginForm>
            </Content>
        </Page>

    );
}

export default Login;