import React, { useState, useEffect, useContext } from "react";
import Context from "../../store/context";
import styled from "styled-components";
import FirebaseService from "../../services/FirebaseService";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import {
  faChartPie,
  faMoneyBillWave,
  faInfoCircle,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import {
  Sector,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  Cell,
  Label,
} from "recharts";

import { MetricBox } from "../../theme/theme";

import {
  calcMortgageTableLine,
  calcMortgageTable,
  calcReturnKpis,
  buildRoiData,
  buildPredictiontableData,
  buildAnnualGraphData,
  buildMonthlyGraphData,
} from "../../util/roi_util.js";

const CustomTooltip = styled.div`
  background-color: white;
  padding: 1rem;
`;

const CustomTable = styled(DataTable)`
  & .rdt_TableCell {
    & .partial {
      color: red;
    }
    & .active {
      color: ${({ theme }) => theme.brandColor};
      font-weight: 900;
    }
    & .positive {
      color: ${({ theme }) => theme.posGreen};
      font-weight: 900;
    }
    & .small {
      font-size: 0.6rem;
    }
  }
  & .rdt_TableHeader {
    display: none;
  }

  &.rdt_Table {
    margin: 0;
  }
`;

const ROIComponent = (props) => {
  const context = useContext(Context);

  const buildColumnObj = function (name, selectorName, isRound, sign, width) {
    let columnObj = { name: name, selector: selectorName, width: width };

    columnObj.format = (row) => {
      let res = row[selectorName];
      res = isRound ? Math.round(res) : (res * 100).toFixed(2);
      return res + (sign !== undefined ? sign : "");
    };

    return columnObj;
  };

  const [columns, setCol] = useState([
    buildColumnObj("Year", "year", true, "", "60px"),
    buildColumnObj("Cash Flow", "cash", true, "$"),
    buildColumnObj("Price Appreciation", "pa", true, "$"),
    buildColumnObj("Debt Reduction", "debtReduce", true, "$"),
    buildColumnObj("Total Return", "returnTotal", true, "$"),
    buildColumnObj("COC", "coc", false, "%"),
    buildColumnObj("ROI", "roi", false, "%"),
    buildColumnObj("Investment", "investment", true, "$"),
    buildColumnObj("Home Value", "homeValue", true, "$"),
  ]);

  const [data, setData] = useState([]);
  const [totalInvestment, set_totalInvestment] = useState(-1);
  const [future_data, set_future_data] = useState([]);
  const [revGraphData, set_revGraphData] = useState([]);
  const [revGraphDataMonth, set_revGraphDataMonth] = useState([]);
  const [date, set_date] = useState("");
  const [is_error, set_is_error] = useState(false);

  const [kpis, set_kpis] = useState({});

  useEffect(() => {
    console.log("USE EFFECT");

    const loadData = async function (id) {
      try {
        context.actions({
          type: "setState",
          payload: { ...context.state, isFetching: true },
        });
        let listingDataRaw = {};
        try {
          listingDataRaw = await FirebaseService.getRoiReportData(id);
          const listingData = listingDataRaw.data.calc;
          const listingSetup = listingDataRaw.data.setup;
          const startDate = listingDataRaw.data.startDate;

          const purchasePrice = listingSetup.purchasePrice;
          const loan_pv = listingSetup.loan;
          const downPayment = listingSetup.downPayment;
          const nperiod = listingSetup.nperiod;
          const annual_rate = listingSetup.annualRate;
          const totalCostOfPurchase = listingSetup.closingCost;
          const init_setupInvestment = listingSetup.setupCost;

          const priceAppreciation = listingSetup.priceAppreciation
            ? listingSetup.priceAppreciation / 100
            : 0;

          const cashGrowthRate = listingSetup.cashGrowth / 100;

          const _totalInvestment =
            downPayment + init_setupInvestment + totalCostOfPurchase;

          set_totalInvestment(_totalInvestment);

          set_date(startDate);
          let temp_data = buildRoiData(
            startDate,
            annual_rate,
            nperiod,
            loan_pv,
            purchasePrice,
            _totalInvestment,
            priceAppreciation,
            listingData
          );

          setData(temp_data);

          set_future_data(
            buildPredictiontableData(
              temp_data,
              priceAppreciation,
              _totalInvestment,
              annual_rate,
              nperiod,
              loan_pv,
              cashGrowthRate
            )
          );

          set_revGraphData(buildAnnualGraphData(temp_data));

          set_revGraphDataMonth(buildMonthlyGraphData(temp_data));
          console.log("TOTAL INVEST", _totalInvestment);

          set_kpis(calcReturnKpis(temp_data, _totalInvestment, startDate));

          context.actions({
            type: "setState",
            payload: { ...context.state, isFetching: false },
          });
          console.log(data);
        } catch (e) {
          console.log(e);
          context.actions({
            type: "setState",
            payload: { ...context.state, isError: true, errorMsg: e.message },
          });
          set_is_error(true);
        }
      } catch (e) {
        console.log(e);
        context.actions({
          type: "setState",
          payload: { ...context.state, isError: true, errorMsg: e.message },
        });
      }
    };

    loadData(props.propertyId);
  }, []);

  return (
    <>
      {!is_error && (
        <>
          <div>
            <h2>Return On Investment since {date}</h2>
            <h3>
              <div>
                <span className="info" data-tip data-for="coc">
                  Cash on Cash <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="coc"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    {" "}
                    A cash-on-cash return is a rate of return that calculates
                    the cash income earned (net operation income) on the cash
                    invested in the property and its set up.<br></br> Simply
                    put, the amount of cash earned (after all expense including
                    debt services) divided by the amount of cash brought to the
                    deal.
                  </span>
                </ReactTooltip>
              </div>
            </h3>
            <div className="widget-container">
              <MetricBox>
                <h3>Total</h3>
                <h1>
                  {kpis.ytotal}
                  {"%"}
                </h1>
                <span className="info" data-tip data-for="ytotal">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="ytotal"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    {" "}
                    The percentage introduced show the total return on the cash
                    you invested from the day listing was created and showed
                    income.
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                <h3>Yearly Avg.</h3>
                <h1>
                  {kpis.yavg}
                  {"%"}
                </h1>
                <span className="info" data-tip data-for="yavg">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="yavg"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    {" "}
                    The percentage introduced show the average yearly cash
                    return since the day listing was created, in case the
                    listing as not yet hit the year mark, the percentage
                    introduced will estimate the full first year upon the
                    existing data.
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                <h3>Year To Date (current)</h3>
                <h1>
                  {kpis.ytd}
                  {"%"}
                </h1>
                <span className="info" data-tip data-for="ytd">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="ytd"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    {" "}
                    The percentage introduced show the return rate from the
                    beginning of current year up to date (a year start counting
                    from beginning of operation and is not necessarily aligned
                    with the calendar year).
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                <h3>End Of Year Forcast </h3>
                <h1>
                  {kpis.ytdp}
                  {"%"}
                </h1>
                <span className="info" data-tip data-for="ytdp">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="ytdp"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    The percentage introduced show the current year estimate
                    return rate base on the entire data collected up to date
                    along with assumption for the upcoming month (until the end
                    of the year).
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                {kpis.ytotal < 100 ? (
                  <h3>Estimated Date of B/E </h3>
                ) : (
                  <h3>Actual Date of B/E </h3>
                )}
                <h1>{kpis.be}</h1>
                <span className="sign"> {kpis.be_t} months total</span>
                <span className="info" data-tip data-for="be">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="be"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    According to data collected from previous months of
                    operation you will return your total cash investment by the
                    month introduced on this box.
                  </span>
                </ReactTooltip>
              </MetricBox>

              <MetricBox>
                <h3>Net Profit</h3>
                <h1>
                  {" "}
                  {"$"}
                  {numberWithCommas(kpis.netprof)}
                </h1>
              </MetricBox>
            </div>
            <h3>
              <div>
                <span className="info" data-tip data-for="aaa">
                  ROI <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="aaa"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    {" "}
                    An ROI return is a rate calculating your net operation
                    income, plus debt reduction (mortgage principal payments),
                    <br></br>
                    plus value appreciation (set according to the appreciation
                    rate typed in under set up tab) divided by the total cash
                    brought to the deal.<br></br> ROI is different from Cash on
                    Cash as it take into calculation both debt reduction and
                    appreciation.
                  </span>
                </ReactTooltip>
              </div>
            </h3>
            <div className="widget-container">
              <MetricBox>
                <h3>Total</h3>
                <h1>
                  {kpis.r_ytotal}
                  {"%"}
                </h1>
                <span className="info" data-tip data-for="r_ytotal">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="r_ytotal"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    {" "}
                    The percentage introduced show the total return on the cash
                    you invested <br></br>from the day listing was created and
                    showed income.
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                <h3>Yearly Avg.</h3>
                <h1>
                  {kpis.r_yavg}
                  {"%"}
                </h1>
                <span className="info" data-tip data-for="r_yavg">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="r_yavg"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    {" "}
                    The percentage introduced show the average yearly cash
                    return since the day listing was created, in case the
                    listing as not yet hit the year mark, the percentage
                    introduced will estimate the full first year upon the
                    existing data.
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                <h3>Year To Date (current)</h3>
                <h1>
                  {kpis.r_ytd}
                  {"%"}
                </h1>
                <span className="info" data-tip data-for="r_ytd">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="r_ytd"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    {" "}
                    The percentage introduced show the return rate from the
                    beginning of current year up to date <br></br>(a year start
                    counting from beginning of operation and is not necessarily
                    aligned with the calendar year).
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                <h3>End Of Year Forcast </h3>
                <h1>
                  {kpis.r_ytdp}
                  {"%"}
                </h1>
                <span className="info" data-tip data-for="r_ytdp">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="r_ytdp"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    The percentage introduced show the current year estimate
                    return rate base on the entire data <br></br>collected up to
                    date along with assumption for the upcoming month (until the
                    end of the year).
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                {kpis.r_ytotal < 100 ? (
                  <h3>Estimated Date of B/E </h3>
                ) : (
                  <h3>Actual Date of B/E </h3>
                )}
                <h1>{kpis.r_be}</h1>
                <span className="sign"> {kpis.r_be_t} months total</span>
                <span className="info" data-tip data-for="r_be">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                <ReactTooltip
                  id="r_be"
                  type="info"
                  effect="solid"
                  className="roitooltip"
                  multiline={true}
                >
                  <span>
                    According to data collected from previous months of
                    operation you will return your total cash <br></br>
                    investment by the month introduced on this box.
                  </span>
                </ReactTooltip>
              </MetricBox>
              <MetricBox>
                <h3>Net Profit</h3>
                <h1>
                  {" "}
                  {"$"}
                  {numberWithCommas(kpis.r_netprof)}
                </h1>
              </MetricBox>
            </div>
          </div>

          <fieldset>
            <legend>Yearly return since {date}</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={revGraphData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      stackOffset="sign"
                    >
                      <CartesianGrid strokeDasharray="6 6" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis />
                      <Tooltip content={renderTooltipContent} />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="COC" fill="#8884d8" />
                      <Bar dataKey="ROI" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Current Year By Month</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={revGraphDataMonth}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      stackOffset="sign"
                    >
                      <CartesianGrid strokeDasharray="6 6" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis />
                      <Tooltip content={renderTooltipContentMonth} />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="COC" fill="#8884d8" />
                      <Bar dataKey="ROI" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>
        </>
      )}
    </>
  );
};

const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;

  return toPercent(ratio, 2);
};

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

const renderTooltipContent = (o) => {
  const { payload, label } = o;
  //const total = payload.reduce((result, entry) => result + entry.value, 0);

  return (
    <CustomTooltip>
      <p className="total"> Year {label} </p>
      <ul className="list">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}%`}
          </li>
        ))}
      </ul>
    </CustomTooltip>
  );
};

const renderTooltipContentMonth = (o) => {
  const { payload, label } = o;
  //const total = payload.reduce((result, entry) => result + entry.value, 0);

  return (
    <CustomTooltip>
      <p className="total"> Period {label} </p>
      <ul className="list">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}%`}
          </li>
        ))}
      </ul>
    </CustomTooltip>
  );
};

const numberWithCommas = function (x) {
  return x !== undefined
    ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";
};

export default ROIComponent;
/* 


//////////////////

   <div className="widget-container">
            <h3>5 years projection</h3>
            <div className="data-table">
              <CustomTable
                title=" "
                columns={columns}
                data={future_data}
                responsive={true}
                dense={true}
              />
            </div>
          </div>

      */
