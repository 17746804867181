import React, { useState, useContext, useRef } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Title,
  Text,
  Label,
  Select,
  PrimaryButton,
} from "../../theme//theme.js";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";

const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.negRed};
  align-self: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

const Item = styled(Text)`
  color: black;
  margin: 0.1rem;
  &.active {
    color: ${({ theme }) => theme.posGreen};
  }
`;

function CResForm(props) {
  const context = useContext(Context);

  const [startDate, setStartDate] = useState(new Date());

  console.log(props.init);

  const submitForm = async (values) => {
    let res;
    try {
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });

      res = await FirebaseService.activateCanceledRes(values);

      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      console.log(res);
      props.onupdate(Math.random());
      props.notifyRefresh();
    } catch (e) {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    }
  };

  const deleteActivation = async (id) => {
    try {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: true,
          isModalOpen: true,
          modalComponent: "",
        },
      });
      let res = await FirebaseService.deleteCanceled(id);
      props.onupdate(Math.random());
      props.notifyRefresh();
      console.log(res);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
        },
      });
    }
  };

  return (
    <>
      <Title>{props.title}</Title>

      {/* FORMIK */}
      <Formik
        initialValues={
          props.init.financeCalc.activate
            ? {
                guestyResId: props.init._id,
                source: props.init.source,
                income: props.init.financeCalc.activate.income,
                cityTax: props.init.financeCalc.activate.cityTax,
                stateTax: props.init.financeCalc.activate.stateTax,
                processingFee: props.init.financeCalc.activate.processingFee,
                channelFee: props.init.financeCalc.activate.channelFee,
                managementFee: props.init.financeCalc.activate.managementFee,
                cleaningFee: props.init.financeCalc.activate.cleaningFee,
                checkIn: props.init.checkIn,
                guest: props.init.guest.fullName,
                propertyId: props.id,
              }
            : {
                guestyResId: props.init._id,
                source: props.init.source,
                income: props.init.financeCalc.income,
                cityTax: props.init.financeCalc.cityTax,
                stateTax: props.init.financeCalc.stateTax,
                processingFee: props.init.financeCalc.processingFee,
                channelFee: props.init.financeCalc.channelFee,
                managementFee: props.init.financeCalc.managementFee,
                cleaningFee: props.init.financeCalc.cleaningCost,
                checkIn: props.init.checkIn,
                guest: props.init.guest.fullName,
                propertyId: props.id,
              }
        }
        validate={(values) => {
          console.log("VALIDATE : ", values);
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        enableReinitialize={false}
        validationSchema={yup.object().shape({
          source: yup.string().required(),
          guestyResId: yup.string().required(),
          income: yup.number().required(),
          cityTax: yup.number().required(),
          stateTax: yup.number().required(),
          processingFee: yup.number().required(),
          channelFee: yup.number().required(),
          managementFee: yup.number().required(),
          cleaningFee: yup.number().required(),
          guest: yup.string().required(),
          propertyId: yup.string().required(),
        })}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Item
              className={props.init.activationStatus === true ? "active" : ""}
            >
              Source : {values.source}{" "}
            </Item>
            <Item
              className={props.init.activationStatus === true ? "active" : ""}
            >
              CheckIn : {moment.utc(values.checkIn).format("MM-DD-YYYY")}
            </Item>
            <Item
              className={props.init.activationStatus === true ? "active" : ""}
            >
              Guest : {values.guest}{" "}
            </Item>
            <Item
              className={props.init.activationStatus === true ? "active" : ""}
            >
              Ex. ID : {values.guestyResId}{" "}
            </Item>
            <Item>
              <hr></hr>
            </Item>

            <Label>
              Income {"    ( "}
              {props.init.financeCalc.activate
                ? props.init.financeCalc.income
                : 0}
              {")"}
              {errors.income && <Text color="red">{errors.income}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.income}
                border={errors.income && "1px solid red"}
                type="number"
                name="income"
                placeholder="Total Fare"
              />
            </Label>
            <Label>
              Cleaning Fee {"    ( "}
              {props.init.financeCalc.activate
                ? props.init.financeCalc.cleaningCost
                : 0}
              {")"}
              {errors.cleaningFee && (
                <Text color="red">{errors.cleaningFee}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cleaningFee}
                border={errors.cleaningFee && "1px solid red"}
                type="number"
                name="cleaningFee"
                placeholder="Cleaning Fee"
              />
            </Label>

            <Label>
              City Tax {"    ( "}
              {props.init.financeCalc.activate
                ? props.init.financeCalc.cityTax
                : 0}
              {")"}
              {errors.cityTax && <Text color="red">{errors.cityTax}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cityTax}
                border={errors.cityTax && "1px solid red"}
                type="number"
                name="cityTax"
                placeholder="City tax"
              />
            </Label>
            <Label>
              State Tax {"    ( "}
              {props.init.financeCalc.activate
                ? props.init.financeCalc.stateTax
                : 0}
              {")"}
              {errors.stateTax && <Text color="red">{errors.stateTax}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.stateTax}
                border={errors.stateTax && "1px solid red"}
                type="number"
                name="stateTax"
                placeholder="State tax"
              />
            </Label>

            <Label>
              Processing Fee {"    ( "}
              {props.init.financeCalc.activate
                ? props.init.financeCalc.processingFee
                : 0}
              {")"}
              {errors.processingFee && (
                <Text color="red">{errors.processingFee}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.processingFee}
                border={errors.processingFee && "1px solid red"}
                type="number"
                name="processingFee"
                placeholder="Processing Fee"
              />
            </Label>

            <Label>
              Channel Fee {"    ( "}
              {props.init.financeCalc.activate
                ? props.init.financeCalc.channelFee
                : 0}
              {")"}
              {errors.channelFee && (
                <Text color="red">{errors.channelFee}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.channelFee}
                border={errors.channelFee && "1px solid red"}
                type="number"
                name="channelFee"
                placeholder="Channel Fee"
              />
            </Label>

            <Label>
              Management Fee {"    ( "}
              {props.init.financeCalc.activate
                ? props.init.financeCalc.managementFee
                : 0}
              {")"}
              {errors.managementFee && (
                <Text color="red">{errors.managementFee}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.managementFee}
                border={errors.managementFee && "1px solid red"}
                type="number"
                name="managementFee"
                placeholder="Management Fee"
              />
            </Label>

            <ButtonsContainer>
              {props.init.activationStatus !== true ? (
                <PrimaryButton type="submit">Activate</PrimaryButton>
              ) : (
                <>
                  <PrimaryButton type="submit">Update</PrimaryButton>
                  <DeleteButton
                    type="button"
                    onClick={async () => {
                      let a = window.confirm(
                        "Are you sure you want to delete this?"
                      );
                      if (a) {
                        try {
                          await deleteActivation(props.init._id);
                        } catch (e) {
                          console.log(e);
                          context.actions({
                            type: "setState",
                            payload: {
                              ...context.state,
                              isError: true,
                              errorMsg: e.message,
                            },
                          });
                        }
                      }
                    }}
                  >
                    Deactivate
                  </DeleteButton>
                </>
              )}
            </ButtonsContainer>
          </Form>
        )}
      </Formik>
      {/* END OF FORMIK */}
    </>
  );
}

export default CResForm;
