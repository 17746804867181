import ImageGallery from 'react-image-gallery';
import React, { useEffect } from "react";
import DocViewer, { DocViewerRenderers , PDFRenderer ,PNGRenderer , JPGRenderer  } from "react-doc-viewer";
import myHeader from './myHeader';
export default function PhotoGallery(props) {    
    
    console.log("sprops", props);
    //////
    let { images } = props;
    var docs = convertFilesToArray(images);    
    console.log(docs);
    
    return<>
    {/* <myHeader>

    </myHeader> */}
    <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} 
     config={{header:{overrideComponent:myHeader }}}
    ></DocViewer>    
    </>    
}
function convertFilesToArray(arr){
    return arr.map((e)=> ({uri: e , fileType: GetFileTypeByPath(e)}));
}

function GetFileTypeByPath(path){
    let res;
    if(String(path).toLocaleLowerCase().includes('pdf')){
        res = "pdf";
    }
    else if(String(path).toLocaleLowerCase().includes('jpg')){
        res = "jpg";
    }
    else if(String(path).toLocaleLowerCase().includes('jpeg')){
        res = "jpg";
    }
    else if(String(path).toLocaleLowerCase().includes('png')){
        res = "png";
    }
    else if(String(path).toLocaleLowerCase().includes('xlsx')){
        res = "xlsx";
    }
    else if(String(path).toLocaleLowerCase().includes('docs')){
        res = "docs";
    }
    return res;
}
