import React, { useState, useContext, useRef, useEffect } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Title,
  Text,
  Label,
  Select,
  PrimaryButton,
  Content,
} from "../../theme//theme.js";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

import {
  faChartPie,
  faMoneyBillWave,
  faInfoCircle,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

const Item = styled(Text)`
  color: black;
  margin: 0.1rem;
  &.active {
    color: ${({ theme }) => theme.posGreen};
  }
`;

const ViewContent = styled(Content)`
  padding-top: 0.1rem;
  overflow-y: auto;
`;

function SetupListingForm(props) {
  const context = useContext(Context);

  const [init, setInit] = useState(undefined);
  const [refreshFlag, set_refreshFlag] = useState(Math.random());

  useEffect(() => {
    const loadData = async (id) => {
      let payload = await FirebaseService.getListingSetup(id);
      console.log("SetupListingForm ", payload);
      setInit(payload.data);
    };
    console.log("LISTING ID: ", props.propertyId);
    loadData(props.propertyId);
  }, [refreshFlag]);

  const submitForm = async (values) => {
    let res;
    try {
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });

      res = await FirebaseService.updateListingSetup(props.propertyId, values);

      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      console.log(res);
      set_refreshFlag(Math.random());
    } catch (e) {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    }
  };

  return (
    <ViewContent>
      <fieldset>
        <Title>Listing Setup Data</Title>
        {init !== undefined && (
          <Formik
            initialValues={
              init === null
                ? {
                    annualRate: 0,
                    cashGrowth: 0,
                    closingCost: 0,
                    downPayment: 0,
                    loan: 0,
                    nperiod: 0,
                    priceAppreciation: 0,
                    purchasePrice: 0,
                    setupCost: 0,
                  }
                : {
                    ...init,
                  }
            }
            validate={(values) => {
              console.log("VALIDATE : ", values);
            }}
            onSubmit={(values) => {
              submitForm(values);
            }}
            enableReinitialize={false}
            validationSchema={yup.object().shape({
              setupCost: yup.number().required(),
              purchasePrice: yup.number().required(),
              loan: yup.number().required(),
              downPayment: yup.number().required(),
              nperiod: yup.number().required(),
              annualRate: yup.number().required(),
              closingCost: yup.number().required(),
              priceAppreciation: yup.number().required(),
              cashGrowth: yup.number().required(),
            })}
          >
            {({
              touched,
              setFieldValue,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Label>
                  <div>
                    <span className="info" data-tip data-for="pp">
                      Purchase Price ($) <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="pp"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span>The price property was sold for.</span>
                    </ReactTooltip>
                  </div>
                  {errors.purchasePrice && (
                    <Text color="red">{errors.purchasePrice}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.purchasePrice}
                    border={errors.purchasePrice && "1px solid red"}
                    type="number"
                    name="purchasePrice"
                    placeholder="$ "
                  />
                </Label>
                <Label>
                  <div>
                    <span className="info" data-tip data-for="lv">
                      Loan ($) <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="lv"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span> the loan portion of the purchase.</span>
                    </ReactTooltip>
                  </div>
                  {errors.purchasePrice && (
                    <Text color="red">{errors.loan}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.loan}
                    border={errors.loan && "1px solid red"}
                    type="number"
                    name="loan"
                    placeholder="$ "
                  />
                </Label>
                <Label>
                  <div>
                    <span className="info" data-tip data-for="dp">
                      Down payment ($) <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="dp"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span>The down payment portion of the purchase.</span>
                    </ReactTooltip>
                  </div>
                  {errors.downPayment && (
                    <Text color="red">{errors.downPayment}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.downPayment}
                    border={errors.downPayment && "1px solid red"}
                    type="number"
                    name="downPayment"
                    placeholder="$ "
                  />
                </Label>
                <Label>
                  <div>
                    <span className="info" data-tip data-for="np">
                      Number Of Periods (years){" "}
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="np"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span>Length of the loan in years.</span>
                    </ReactTooltip>
                  </div>
                  {errors.nperiod && <Text color="red">{errors.nperiod}</Text>}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nperiod}
                    border={errors.nperiod && "1px solid red"}
                    type="number"
                    name="nperiod"
                    placeholder="years "
                  />
                </Label>
                <Label>
                  <div>
                    <span className="info" data-tip data-for="ar">
                      Annual Interest Rate (%){" "}
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="ar"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span>
                        {" "}
                        Loan interest rate – in case no manual entries on a
                        month to month base, portal will use this field to
                        assume the monthly interest and principal payments.
                      </span>
                    </ReactTooltip>
                  </div>
                  {errors.annualRate && (
                    <Text color="red">{errors.annualRate}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.annualRate}
                    border={errors.annualRate && "1px solid red"}
                    type="number"
                    name="annualRate"
                    placeholder="0.00 "
                  />
                </Label>

                <Label>
                  <div>
                    <span className="info" data-tip data-for="cc">
                      Closing Cost ($)
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="cc"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span>
                        {" "}
                        the total sum amount of all closing cost from your
                        transaction CD. Simply put, total cash needed for
                        closing minus down payment.
                      </span>
                    </ReactTooltip>
                  </div>
                  {errors.closingCost && (
                    <Text color="red">{errors.closingCost}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.closingCost}
                    border={errors.closingCost && "1px solid red"}
                    type="number"
                    name="closingCost"
                    placeholder="$ "
                  />
                </Label>
                <Label>
                  <div>
                    <span className="info" data-tip data-for="sc">
                      Setup Cost ($)
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="sc"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span>
                        The total cost include all expenses related to the set
                        up of the property for the short term rental market.{" "}
                        <br></br> Set up cost will include building
                        improvements, furniture, décor, beddings, kitchen
                        equipment etc..
                      </span>
                    </ReactTooltip>
                  </div>
                  {errors.setupCost && (
                    <Text color="red">{errors.setupCost}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.setupCost}
                    border={errors.setupCost && "1px solid red"}
                    type="number"
                    name="setupCost"
                    placeholder="$ "
                  />
                </Label>

                <Label>
                  <div>
                    <span className="info" data-tip data-for="pa">
                      Price Appreciation Rate (%){" "}
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="pa"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span>
                        The estimate rate increase (%) of house values in your
                        area.<br></br> Portal will use this to calculate the ROI
                        and is recommended to plug in a conservative figure in
                        order for the portal to keep a realistic prediction of
                        your ROI (1-3% max).{" "}
                      </span>
                    </ReactTooltip>
                  </div>
                  {errors.priceAppreciation && (
                    <Text color="red">{errors.priceAppreciation}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.priceAppreciation}
                    border={errors.priceAppreciation && "1px solid red"}
                    type="number"
                    name="priceAppreciation"
                    placeholder="0.00 "
                  />
                </Label>

                <Label>
                  <div>
                    <span className="info" data-tip data-for="acr">
                      Annual Income Growth Rate (%){" "}
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    <ReactTooltip
                      id="acr"
                      type="info"
                      effect="solid"
                      className="roitooltip"
                      multiline={true}
                    >
                      <span>
                        {" "}
                        The estimate rate increase (%) of the income .<br></br>{" "}
                        Portal will use this field in the future to generate ROI
                        forecast.<br></br>For now keep it 0.
                      </span>
                    </ReactTooltip>
                  </div>
                  {errors.cashGrowth && (
                    <Text color="red">{errors.cashGrowth}</Text>
                  )}
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cashGrowth}
                    border={errors.cashGrowth && "1px solid red"}
                    type="number"
                    name="cashGrowth"
                    placeholder="0.00 "
                  />
                </Label>

                <ButtonsContainer>
                  {props.init !== undefined ? (
                    <PrimaryButton type="submit">Update</PrimaryButton>
                  ) : (
                    <>
                      <PrimaryButton type="submit">Save</PrimaryButton>
                    </>
                  )}
                </ButtonsContainer>
              </Form>
            )}
          </Formik>
        )}
        {/* END OF FORMIK */}
      </fieldset>
    </ViewContent>
  );
}

export default SetupListingForm;
