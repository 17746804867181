import React, { useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUserEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ListingSelector from "../Listings/ListingSelector";
import UserForm from "../users/UserForm";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";

const Row = styled.div`
  width: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  border: 1px ${({ theme }) => theme.darkGray} solid;
  background-color: white;
  border-radius: 4px;
  margin: 0.2rem 0.2rem;
  align-items: baseline;
  padding: 0.7rem 0.8rem;
  color: ${({ theme }) => theme.primaryDark};
  &:hover {
    background-color: ${({ theme }) => theme.mediumGray};
  }
`;

const DetailBox = styled.div`
  text-align: left;
  width: ${({ width }) => width};
  &.smalltxt {
    font-size: 0.8rem;
  }
  &.edit {
    color: ${({ theme }) => theme.negRed};
  }
`;

const EditPanel = styled.div`
  height: auto;
  width: 100%;
  margin: 0.8rem;
  display: flex;
  flex-flow: row wrap;
  padding: 0.2rem;
  justify-content: start;
  align-content: flex-start;
`;

const PropertyThumb = styled.div`
  background: url(${({ url }) => url});
  background-size: contain;
  height: 4rem;
  width: 4rem;
  border-radius: 4px;
`;

const PropertyTitle = styled.div`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  font-size: 0.7rem;
  max-width: 8rem;
  padding: 0.4rem 0.2rem;
  max-height: 4rem;
`;
const PropertyBox = styled.div`
  margin: 0.2rem;
  border-radius: 4px;
  display: flex;
  flex-flow: row wrap;
  justify-items: left;
  box-shadow: ${({ theme }) => theme.darkGray} 1px 1px 2px;
  background-color: ${({ theme }) => theme.white};
  position: relative;
  & .deleteButton {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.1rem;
    background-color: ${({ theme }) => theme.negRed};
    color: ${({ theme }) => theme.white};
  }
`;

const EmptyMessage = styled.div`
  width: fit-content;
  font-size: 2rem;
  padding: 2rem;
`;

const AddAsset = styled.div`
  width: 8rem;
  margin: 0.5rem;
  font-size: 1rem;
  padding: 1rem;
  border: 1px dotted black;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.mediumGray};
  &:hover {
    background-color: ${({ theme }) => theme.darkGray};
  }
`;

function UserRow(props) {
  const context = useContext(Context);

  const doSomething = function (e) {
    //Selects and Unselects the clicked user row
    props.isEdit
      ? props.onGetSelected(-1)
      : props.onGetSelected(props.user.uid);
  };

  //Converts the array to an Object
  let myAssetsObjectIds = props.user.data
    ? props.user.data.listings.reduce((a, b) => ((a[b] = ""), a), {})
    : [];
  //Combines user listings with entire listing list
  let myAssetList = props.listings
    .filter((el) => {
      return myAssetsObjectIds[el.listingId] !== undefined;
    })
    .map((el) => el);

  const deleteUserListing = async function (id, userId) {
    context.actions({
      type: "setState",
      payload: { ...context.state, isFetching: true },
    });
    let filtered = props.user.data.listings.filter((el) => el !== id);
    console.log("USER LISTINGS ", props.user.data.listings);
    console.log("FILTERED ", filtered);
    let update = await FirebaseService.addListingToUser(
      { assets: filtered },
      userId
    );
    context.actions({
      type: "setState",
      payload: { ...context.state, isFetching: false },
    });
    props.onUpdate();
  };

  return (
    <Row onClick={(e) => doSomething(e)}>
      <DetailBox width="20%"> {props.user.displayName}</DetailBox>
      <DetailBox width="17%">
        {" "}
        {props.user.customClaims
          ? Object.keys(props.user.customClaims).map((e) => e)
          : ""}
      </DetailBox>
      <DetailBox width="20%" className="desktop smalltxt">
        {" "}
        {props.user.email}
      </DetailBox>
      <DetailBox width="20%" className="desktop smalltxt">
        {" "}
        {props.user.phoneNumber}
      </DetailBox>
      <DetailBox width="17%">
        {" "}
        <FontAwesomeIcon icon={faHome} /> ({" "}
        {Object.keys(myAssetList).length || 0} )
      </DetailBox>

      <DetailBox
        className="edit"
        onClick={(e) => {
          e.stopPropagation();
          context.actions({
            type: "openModal",
            payload: {
              ...context.state,
              isModalOpen: true,
              modalComponent: (
                <UserForm
                  title="Edit User"
                  init={props.user}
                  isEdit={true}
                  onClose={props.onClose}
                ></UserForm>
              ),
            },
          });
        }}
      >
        <FontAwesomeIcon icon={faUserEdit} />{" "}
      </DetailBox>

      <EditPanel className={props.isEdit ? "show" : "hide"}>
        {myAssetList.length > 0 ? (
          myAssetList.map((el, index) => (
            <PropertyBox key={el.listingId}>
              <PropertyThumb url={el.data.thumb} />
              <PropertyTitle className="p_title">{el.data.title}</PropertyTitle>
              <div className="deleteButton">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteUserListing(el.listingId, props.user.uid);
                  }}
                />
              </div>
            </PropertyBox>
          ))
        ) : (
          <EmptyMessage>No listings found.</EmptyMessage>
        )}
        <AddAsset
          onClick={(e) => {
            e.stopPropagation();
            context.actions({
              type: "openModal",
              payload: {
                ...context.state,
                isModalOpen: true,
                modalComponent: (
                  <ListingSelector
                    userList={myAssetsObjectIds}
                    list={props.listings}
                    onClose={props.onClose}
                    userId={props.user.uid}
                    onUpdate={props.onUpdate}
                  ></ListingSelector>
                ),
              },
            });
          }}
        >
          Add +
        </AddAsset>
      </EditPanel>
    </Row>
  );
}

export default UserRow;
