import React, { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import pnglogo from "../../goldnest_logo.png";
import Burger from "../ui/Burger";
import MobileMenu from "./MobileMenu";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const AppLayoutSide = styled.div`
  overflow: hidden;
  position: relative;
  width: 16%;
  max-width: 250px;
  min-width: 150px;
  max-height: 100vh;
  @media (max-width: 1024px) {
    display: none;
  }
`;

function Navigation(props) {
  let location = useLocation();

  const [open, setOpen] = useState(false);
  const targetElement = document.querySelector("#mobilemenu");

  console.log("LOCATION : ", location);

  const onBurgerClick = () => {
    if (open) {
      enableBodyScroll(targetElement);
      clearAllBodyScrollLocks();
    } else {
      disableBodyScroll(targetElement);
    }
    setOpen(!open);
  };

  return (
    <>
      <Burger setOpen={() => onBurgerClick()} open={open}></Burger>
      <AppLayoutSide>
        <div className="App-logo-container">
          <Link to="/">
            <img className="App-logo" src={pnglogo}></img>
          </Link>
        </div>
        <NavBar
          state={props.state}
          func={props.logOutFunc}
          path={location.pathname}
        ></NavBar>
        <div className="App-version">
          version :
          {process.env.REACT_APP_CLIENT_VERSION +
            (process.env.REACT_APP_API_REF !== "remote"
              ? "   ( " + process.env.REACT_APP_API_REF + " ) "
              : "")}
        </div>
      </AppLayoutSide>

      <MobileMenu
        setOpen={() => onBurgerClick()}
        open={open}
        state={props.state}
        logOutFunc={props.logOutFunc}
        id="mobilemenu"
      ></MobileMenu>
    </>
  );
}

export default Navigation;
