//import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import styled, { css } from "styled-components";
//import React from "react";
import FirebaseService from "../../services/FirebaseService";


const searchListing = styled.span`
;
`

const ListingRow = styled.span`
  min-width: 200px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%; 
  & img {
    max-width: 80px;
    margin-right: 10px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.mediumGray}
  }
  font-size: 1.5rem;
  .listing-row-main {
    text-align: left; 
    flex-grow: 1;
  }
`;

const divStyle = {
    width: '100%',
    marginBottom:'15px',
    height: '30px',
    size: '35px',
    border: '0px',
    borderRadius: '20px',
    lineheight: '20px',
    border: '2px solid black',
    paddingLeft:'10px'
  };


export default function ListingSelector(props) {
    const [isEnabled, setIsEnabled] = useState();

    console.log(props);
    const userListArray = Object.keys(props.userList)
    let filteredList = props.list.filter((el) => props.userList[el.listingId] === undefined)
    const [filteredData, setFiltered] = useState(props.list);

    const onSearch = (event) => {
        const regex = new RegExp(event.target.value, "i");
        const filtered = props.list.filter(function (datum) {
          console.log("props.list.title", props.list.title);
          return datum.data.title.search(regex) > -1;
        });
        setFiltered(filtered);
    };
   
    
    return (
        [
            <input className="SearchListing" style={divStyle}  type="search" placeholder="search" onChange={onSearch.bind(this)}></input>,
            filteredData.map((el, index) =>
            el.data.externalData.active == true ? (
                <ListingRow key={el.listingId} onClick={() => addListing({ listingId: el.listingId, user: props.userId, oldList: userListArray }, () => props.onUpdate())}>
                <img src={el.data.thumb} alt="Listing Thumbnail" />
                <div className="listing-row-main">
                  <div className="listing-row-header" style={{fontSize: "1.3rem"}}>
                    {el.data.nickname}
                  </div>
                  <div style={{fontSize: "0.8rem"}} className="listing-row-footer">
                    {el.data.title}
                  </div>
                </div>
              </ListingRow>
            ):("")
            
        )]
    )
}

async function addListing(data, callback) {
    console.log(data.user)
    let uniqueCombined = [...new Set([...data.oldList, data.listingId])]
    let update = await FirebaseService.addListingToUser({ assets: uniqueCombined }, data.user)
    callback();
}