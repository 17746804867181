import React, { Component, createRef, useContext } from "react";
import styled from "styled-components";
import Settings from "./pages/Settings";
import Navigation from "./components/NavBar/Navigation";
import Listings from "./components/Listings/Listings";
import Reports from "./components/Listings/Reports";
import ReportComponent from "./components/Listings/ReportComponent";
import TaxReport from "./components/Listings/TaxReport";
import Users from "./components/users/Users";
import Login from "./components/users/Login";
import Home from "./components/home/Home";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./global";
import { theme } from "./theme/theme";
import ViewListing from "./components/Listings/ViewListing";
import EditListing from "./components/Listings/EditListing";
import Modal from "./components/ui/Modal";
import FirebaseService from "./services/FirebaseService";
import ReportMenu from "./components/ui/ReportMenu";
//import { GlobalProvider, GlobalContext, GlobalConsumer } from "./contexts/GlobalContext"
import Context from "./store/context";
import { Preloader, ErrorPopup } from "./theme/theme";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faMehRollingEyes,
} from "@fortawesome/free-solid-svg-icons";
const AppLayout = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  height: 100vh;
  @media (max-width: 1024px) {
    height: 100%;
  }
`;

const AppLayoutMain = styled.div`
  background-color: var(--spacegray);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  @media only screen and(min-width: 1024px) {
    width: 100%;
  }
`;

class App extends Component {
  static contextType = Context;

  constructor() {
    super();
    console.log(window.client_version);
    this.state = {
      isNavOpen: false,
    };
  }

  async componentDidMount() {
    await this.postLoginHandler();
  }

  render() {
    const isLogged = this.context.state.user !== null;
    const isAdmin = this.context.state.isAdmin;
    return (
      <Router>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <>
            <AppLayout>
              <Navigation
                state={isLogged}
                logOutFunc={this.signout.bind(this)}
              ></Navigation>

              <AppLayoutMain>
                {isLogged ? (
                  <>
                    <Route exact path="/">
                      <Redirect to="/listings"></Redirect>
                    </Route>

                    <PrivateRoute exact path="/listings" authSource={isLogged}>
                      <Listings list={this.context.state.listings}></Listings>
                    </PrivateRoute>

                    <PrivateRoute
                      exact
                      path="/listing/:id"
                      authSource={isLogged}
                    >
                      <ViewListing></ViewListing>
                    </PrivateRoute>

                    <PrivateRoute
                      exact
                      path="/listing/edit/:id"
                      authSource={isAdmin}
                    >
                      <EditListing></EditListing>
                    </PrivateRoute>

                    <PrivateRoute exact path="/users" authSource={isAdmin}>
                      <Users users={this.context.state.userList}></Users>
                    </PrivateRoute>

                    <PrivateRoute exact path="/settings" authSource={isAdmin}>
                      <Settings></Settings>
                    </PrivateRoute>
                   
                    <PrivateRoute
                      exact
                      path="/reports/summary"
                      authSource={isLogged}
                    >
                      <ReportComponent
                        list={this.context.state.listings.filter(
                          (el) => el.data.externalData.active
                        )}
                      ></ReportComponent>
                    </PrivateRoute>

                    <PrivateRoute
                      exact
                      path="/reports/MonthlyTax"
                      authSource={isLogged}
                    >
                     <TaxReport
                        list={this.context.state.listings.filter(
                          (el) => el.data.externalData.active
                        )}
                      ></TaxReport>
                    </PrivateRoute>
                    
                    <PrivateRoute
                      exact
                      path="/reports/export"
                      authSource={isLogged}
                    >
                      <Reports
                        list={this.context.state.listings.filter(
                          (el) => el.data.externalData.active
                        )}
                      ></Reports>
                    </PrivateRoute>
                    <PrivateRoute exact path="/reports" authSource={isLogged}>
                      <ReportMenu></ReportMenu>
                    </PrivateRoute>

                    <MemberRoute exact path="/login" authSource={isLogged}>
                      <Login signin={this.signin.bind(this)}></Login>
                    </MemberRoute>
                  </>
                ) : (
                  <Route path="/">
                    <Home signin={this.signin.bind(this)}></Home>
                  </Route>
                )}
              </AppLayoutMain>

              <Preloader
                className={this.context.state.isFetching ? "show" : "hide"}
              >
                <div className="lds-ripple">
                  <h3>Loading</h3>
                  <div></div>
                  <div></div>
                </div>
              </Preloader>

              <ErrorPopup
                className={this.context.state.isError ? "show" : "hide"}
              >
                <div className="container">
                  <div
                    className="closeButton"
                    onClick={() => {
                      this.context.actions({
                        type: "setState",
                        payload: { ...this.context.state, isError: false },
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </div>
                  <h1>
                    Oopsy, Something went wrong{" "}
                    <FontAwesomeIcon icon={faMehRollingEyes} />
                  </h1>
                  <h3>{this.context.state.errorMsg}</h3>
                </div>
              </ErrorPopup>

              {this.context.state.isModalOpen ? (
                <Modal
                  open={this.context.state.isModalOpen}
                  onClose={() => {
                    this.context.actions({
                      type: "setState",
                      payload: { ...this.context.state, isModalOpen: false },
                    });
                  }}
                  id="modal"
                >
                  {this.context.state.modalComponent}
                </Modal>
              ) : (
                <></>
              )}
            </AppLayout>
          </>
        </ThemeProvider>
      </Router>
    );
  }

  async postLoginHandler() {
    console.log("POST LOGIN : ", this.context.state);

    try {
      await FirebaseService.isInitialized();

      let user = FirebaseService.getCurrentUser();
      if (user === null) {
        this.context.actions({
          type: "setState",
          payload: { ...this.context.state, isFetching: false, user: null },
        });
      } else {
        let idTokenResult = await user.getIdTokenResult();
        console.log("ADMIN ", idTokenResult);
        // Confirm the user is an Admin.
        if (idTokenResult.claims.admin) {
          this.context.actions({
            type: "setState",
            payload: { ...this.context.state, isAdmin: true },
          });
        } else {
          // Show regular user UI.
          this.context.actions({
            type: "setState",
            payload: { ...this.context.state, isAdmin: false },
          });
        }
        this.context.actions({
          type: "setState",
          payload: { ...this.context.state, isFetching: true },
        });
        let listings = await FirebaseService.loadListings();
        this.context.actions({
          type: "setState",
          payload: {
            ...this.context.state,
            isFetching: false,
            user: user,
            listings: listings,
          },
        });
      }
    } catch (e) {
      //TODO
      console.log(e);
      this.context.actions({
        type: "setState",
        payload: { ...this.context.state, isError: true, errorMsg: e.message },
      });
    }
  }
  async signout() {
    await FirebaseService.logout();
    await this.postLoginHandler();
  }

  async signin(values) {
    this.context.actions({
      type: "setState",
      payload: { ...this.context.state, isFetching: true },
    });
    try {
      await FirebaseService.login(values.email, values.password);
      await this.postLoginHandler();
      this.context.actions({
        type: "setState",
        payload: { ...this.context.state, isFetching: false },
      });
    } catch (e) {
      this.context.actions({
        type: "setState",
        payload: {
          ...this.context.state,
          isFetching: false,
          isError: true,
          errorMsg: e.message,
        },
      });
    }
  }
}

function PrivateRoute({ children, authSource, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authSource ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function MemberRoute({ children, authSource, ...rest }) {
  return (
    <Route {...rest} render={({ location }) => (!authSource ? children : "")} />
  );
}

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: palevioletred;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
  background: papayawhip;
`;

export default App;
