import React from 'react';
import { Link } from "react-router-dom"
import styled from "styled-components";
import { Page, Content } from "../../theme/theme.js";
import LoginForm from '../users/LoginForm.js';




function Home(props) {
    return (
        <Page>
            <Content>
                <LoginForm title="Welcome" signin={props.signin}></LoginForm>
            </Content>
        </Page >
    );
}

export default Home;