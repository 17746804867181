import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import Calendar from "./Calendar";
import TimeSelector from "../ui/TimeSelector";
import { MetricBox } from "../../theme/theme";

import {
  faThermometerThreeQuarters,
  faMoon,
  faMoneyBillWave,
  faAddressBook,
  faConciergeBell,
  faFunnelDollar,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  Sector,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  BarChart,
  Bar,
  Cell,
  Label,
} from "recharts";

const PricingTable = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 1rem;
`;

const DiffBox = styled.div`
  font-size: 0.9rem;
  font-weight: 900;
  &.negative {
    color: ${({ theme }) => theme.negRed};
  }
  &.positive {
    color: ${({ theme }) => theme.posGreen};
  }
`;
const ComparissonTable = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 4rem;
  grid-auto-rows: 3rem;
  grid-auto-flow: row;
  grid-gap: 1px;
  background-color: ${({ theme }) => theme.darkGray};
  & div {
    padding-top: 1rem;
    background-color: ${({ theme }) => theme.white};
  }
`;

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const getRepName = function (name) {
  let map = {
    adr: "ADR",
    avgnts: "Avg Nights",
    dayAdv: "Booked Before",
    income: "Income",
    expenses: "Expenses",
    occupanceRate: "Occupancy",
  };
  return map[name];
};
export default function ActivityView(props) {
  console.log("activity view props",props);
  
  const sourceDistData =
    props.stats.months && props.stats.months[props.bulk]
      ? createGraphArray(props.stats.months[props.bulk].resSource)
      : [];
  const expenseDistData =
    props.stats.expenses && props.stats.expenses[props.bulk]
      ? createExpenseGraphArray(props.stats.expenses[props.bulk].distribution)
      : [];
  let occupancyRatesData =
    props.stats.months && props.stats.months[props.bulk]
      ? createOccupancyGraphArray(props.stats.months)
      : [];
  let adrGraphData =
    props.stats.months && props.stats.months[props.bulk]
      ? createADRGraphArray(props.stats.months)
      : [];
  const revGraphData =
    props.stats.months && props.stats.months[props.bulk] && props.stats.prevRev
      ? createRevGraphArray(props.stats.months, props.stats.prevRev)
      : [];

  const channelDistributionIncome =
    props.stats.months && props.stats.months[props.bulk]
      ? createChannelIncomeGraphArray(
          props.stats.months[props.bulk].income.distribution
        )
      : [];
  const comparisonData =
    props.stats.months && props.stats.months[props.bulk] && props.stats.prevRev
      ? createComparissonArray(
          props.stats.months[props.bulk],
          props.stats.prevRev[props.bulk]
        )
      : [];
  console.log("REVVV : ", revGraphData);

  const changeMonth = function (dir) {
    console.log("REDUCE TIME");
    let newT = props.time.clone();
    !props.period ? newT.add(dir, "months") : newT.add(dir, "years");
    props.onTimeChange(newT);
  };
  //TODO - move the API data fetch to here

  return (
    <>
      <fieldset>
        <legend>Dashboard </legend>
        <TimeSelector
          time={props.time}
          onTimeChange={changeMonth}
          onPeriodChange={() => props.onPeriodChange(!props.period)}
          period={props.period}
        ></TimeSelector>
        <div className="widget-container">
          {props.stats.months !== null ? (
            <>
              <MetricBox>
                <h3>
                  {" "}
                  <FontAwesomeIcon icon={faThermometerThreeQuarters} />
                  &nbsp;&nbsp;ADR
                </h3>
                <h1>
                  <span className="sign">$</span>
                  {props.stats.months[props.bulk].adr}
                </h1>
              </MetricBox>

              <MetricBox>
                <h3>
                  {" "}
                  <FontAwesomeIcon icon={faMoon} />
                  &nbsp;&nbsp;Avg Nights
                </h3>
                <h1>{props.stats.months[props.bulk].avgnts}</h1>
              </MetricBox>

              <MetricBox>
                <h3>
                  {" "}
                  <FontAwesomeIcon icon={faMoneyBillWave} />
                  &nbsp;&nbsp;Income
                </h3>
                <h1>
                  <span className="sign">$</span>
                  {(
                    props.stats.months[props.bulk].income.amount +
                    (props.stats.income[props.bulk]
                      ? props.stats.income[props.bulk].amount
                      : 0)
                  ).toFixed(2)}
                </h1>
              </MetricBox>

              <MetricBox>
                <h3>
                  {" "}
                  <FontAwesomeIcon icon={faFunnelDollar} />
                  &nbsp;&nbsp;Expenses
                </h3>
                <h1>
                  <span className="sign">$</span>
                  {(
                    props.stats.months[props.bulk].expenses.amount +
                    (props.stats.expenses[props.bulk]
                      ? props.stats.expenses[props.bulk].amount
                      : 0)
                  ).toFixed(2)}
                </h1>
              </MetricBox>

              <MetricBox>
                <h3>
                  {" "}
                  <FontAwesomeIcon icon={faAddressBook} />
                  &nbsp;&nbsp;Booked Before
                </h3>
                <h1>
                  {props.stats.months[props.bulk].dayAdv}
                  <span className="sign">days</span>
                </h1>
              </MetricBox>

              <MetricBox>
                <h3>
                  {" "}
                  <FontAwesomeIcon icon={faConciergeBell} />
                  &nbsp;&nbsp;Occupancy
                </h3>
                <h1>
                  {props.stats.months[props.bulk].occupanceRate}
                  <span className="sign">%</span>
                </h1>
              </MetricBox>

              <MetricBox className="primary">
                <h3>
                  {" "}
                  <FontAwesomeIcon icon={faConciergeBell} />
                  &nbsp;&nbsp;NET
                </h3>
                <h1>
                  <span className="sign">$ </span>
                  {Number(
                    props.stats.months[props.bulk].income.amount +
                      (props.stats.income[props.bulk]
                        ? props.stats.income[props.bulk].amount
                        : 0) -
                      (props.stats.months[props.bulk].expenses.amount +
                        (props.stats.expenses[props.bulk]
                          ? props.stats.expenses[props.bulk].amount
                          : 0))
                  ).toFixed(2)}
                </h1>
              </MetricBox>
            </>
          ) : (
            <></>
          )}
        </div>
      </fieldset>

      {!props.period ? (
        <>
          <fieldset>
            <legend>{props.time.format("MMM-YYYY")} </legend>

            <Calendar
              time={props.time}
              onResClick={props.onResClick}
              calData={props.calendar}
              resData={props.reservations}
            ></Calendar>
          </fieldset>

          <fieldset>
            <legend>{props.time.format("MMMM")} Pricing Table</legend>
            <PricingTable>
              <h3>
                In the pricing stats we only take into consideration the fare
                accommodation and not the total.
              </h3>
              <ComparissonTable>
                <div></div>
                <div>
                  {props.time.clone().subtract(1, "year").format("MMM-YYYY")}
                </div>
                <div>{props.time.format("MMM-YYYY")}</div>
                <div></div>
                {props.stats.months ? (
                  comparisonData.map((el, index) => (
                    <>
                      <div>
                        <a data-tip data-for={el.name + "_id"}>
                          {getRepName(el.name)}
                        </a>
                        <ReactTooltip
                          id={el.name + "_id"}
                          type="warning"
                          effect="solid"
                        >
                          <span>Explaining what {el.name} is </span>
                        </ReactTooltip>
                      </div>
                      <div>{el.prev}</div>
                      <div>
                        {el.value} <br></br>
                      </div>
                      {el.diff !== 0 ? (
                        <DiffBox
                          className={el.diff > 0 ? "positive" : "negative"}
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={el.diff > 0 ? faArrowUp : faArrowDown}
                          ></FontAwesomeIcon>
                          {Math.abs(el.diff).toFixed(1)}%
                        </DiffBox>
                      ) : (
                        <div></div>
                      )}
                    </>
                  ))
                ) : (
                  <></>
                )}
              </ComparissonTable>
            </PricingTable>
          </fieldset>
          <fieldset>
            <legend>Channel Revenue Distribution</legend>

            <div className="chart-widget ">
              <div style={{ width: "99%", height: 300 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Legend verticalAlign="top" align="left" height={36} />
                    <Pie
                      dataKey="value"
                      data={channelDistributionIncome}
                      fill="#8884d8"
                      label
                    >
                      {channelDistributionIncome.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </fieldset>
        </>
      ) : (
        <>
          <fieldset>
            <legend>Monthly Revenue</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={revGraphData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="value" fill="#8884d8" />
                      <Bar dataKey="prev" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Occupancy Rates</legend>

            <div className="chart-widget ">
              <div style={{ width: "99%", height: 300 }}>
                <ResponsiveContainer>
                  <AreaChart
                    data={occupancyRatesData}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      fill="#8884d8"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Average Daily Rates</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <AreaChart
                      data={adrGraphData}
                      margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="var(--posgreen)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Reservations Sources</legend>

            <div className="chart-widget ">
              <div style={{ width: "99%", height: 300 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Legend verticalAlign="top" align="left" height={36} />
                    <Pie
                      dataKey="value"
                      data={sourceDistData}
                      fill="#8884d8"
                      label
                    >
                      {sourceDistData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Expenses Sources</legend>

            <div className="chart-widget ">
              <div style={{ width: "99%", height: 300 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Legend verticalAlign="top" align="left" height={36} />
                    <Pie
                      dataKey="value"
                      data={expenseDistData}
                      fill="#8884d8"
                      label
                    >
                      {expenseDistData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </fieldset>
        </>
      )}
    </>
  );
}

const createGraphArray = function (obj) {
  Object.keys(obj).forEach(src=>{
    console.log(src);
    if(src.toString().includes('.com')){
      if(src.toString().includes('@')){
        let temp = src.split('@')[1].split('.')[0];
        obj[temp] = obj[src]
        delete obj[src]
      }
      else{
        let temp = src.split('.')[0];
        obj[temp] = obj[src]
        delete obj[src]
      }
    }
  })
  console.log("objecto2" , obj);
  return Object.keys(obj).map((e) => {
    return { name: e, value: obj[e] };
  });
};
const createExpenseGraphArray = function (obj) {
  return Object.keys(obj).map((e) => {
    return { name: e, value: obj[e].amount };
  });
};
const createOccupancyGraphArray = function (obj) {
  let temp = { ...obj };
  delete temp.annual;
  return Object.keys(temp).map((e) => {
    return { name: e, value: obj[e].occupanceRate };
  });
};
const createADRGraphArray = function (obj) {
  let temp = { ...obj };
  delete temp.annual;
  return Object.keys(temp).map((e) => {
    return { name: e, value: obj[e].adr };
  });
};

const createChannelIncomeGraphArray = function (obj) {
  console.log("objecto", obj);
  Object.keys(obj).forEach(src=>{
    console.log(src);
    if(src.toString().includes('.com')){
      if(src.toString().includes('@')){
        let temp = src.split('@')[1].split('.')[0];
        obj[temp] = obj[src]
        delete obj[src]
      }
      else{
        let temp = src.split('.')[0];
        obj[temp] = obj[src]
        delete obj[src]
      }
    }
  })
  let temp = { ...obj };
  return Object.keys(temp).map((e) => {
    return { name: e, value: obj[e] };
  });
};
const createRevGraphArray = function (obj, prev) {
  let temp = { ...obj };
  delete temp.annual;
  return Object.keys(temp).map((e) => {
    return {
      name: e,
      value: obj[e].income.amount,
      prev: prev[e].income.amount,
    };
  });
};

const createComparissonArray = function (cur, prev) {
  let temp = { ...cur };
  delete temp.CityFillingField;
  delete temp.CountyFillingField;
  delete temp.Ownership;
  delete temp.StateFillingField;
  delete temp.resSource;
  delete temp.AvalaraProperyId;
  delete temp.taxRate;
  delete temp.ListingActualCleaninCost
  let list = Object.keys(temp).map((e) => {
    switch (e) {
      case "income":
        return {
          name: e,
          value: temp[e].amount,
          prev: prev[e].amount,
          diff: calcDiff(temp[e].amount, prev[e].amount),
        };
      case "expenses":
        return {
          name: e,
          value: temp[e].amount,
          prev: prev[e].amount,
          diff: calcDiff(temp[e].amount, prev[e].amount),
        };
      default:
        return {
          name: e,
          value: temp[e],
          prev: prev[e],
          diff: calcDiff(temp[e], prev[e]),
        };
    }
  });
  return list;
};

const calcDiff = function (num1, num2) {
  let res = 0;
  if (num1 * num2 !== 0) {
    res = num1 > num2 ? (num1 / num2 - 1) * 100 : (1 - num1 / num2) * -100;
  }
  return res;
};
