import React, { useState, useEffect, useContext, useReducer } from "react";
import ReportCard from "./ReportCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

import ToggleSwitch from "../ui/ToggleSwitch";
import { Page, PrimaryButton } from "../../theme//theme.js";
import GlobalContext from "../../store/context";
import TimeSelector from "../ui/TimeSelector";

import ReportForm from "../ui/ReportForm";
import moment from "moment";

const ListingContent = styled.div`
  padding-top: 14vh;
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;

const Reports = (props) => {
  const context = useContext(GlobalContext);

  const [time, setTime] = useState(moment());

  const [isEnabled, setIsEnabled] = useState(true);

  const [filteredData, setFiltered] = useState(props.list);

  const incldeAll = function (val) {
    return props.list
      .filter((el) => el.data.externalData.active)
      .reduce((accum, el) => {
        accum[el.listingId] = val;
        return accum;
      }, {});
  };

  const changeYear = function (dir) {
    let newT = time.clone();
    newT.add(dir, "years");
    setTime(newT);
  };

  const onISelfInclude = (id) => {
    let next = {};
    Object.assign(next, included);

    next[id] = !included[id];

    setIncluded(next);

    console.log(id);
  };

  const [included, setIncluded] = useState(incldeAll(true));

  console.log("LISTING STATE ", included);

  const changeHandler = (val) => {
    setIncluded(incldeAll(val));
  };

  const onSearch = (event) => {
    const regex = new RegExp(event.target.value, "i");
    const filtered = props.list.filter(function (datum) {
      return datum.data.title.search(regex) > -1;
    });
    setFiltered(filtered);
  };

  return (
    <Page>
      <div className="top-menu-bar">
        <div className="search-bar desktop">
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            placeholder="Search"
            onChange={onSearch.bind(this)}
          />
        </div>
        <PrimaryButton
          onClick={() => {
            let a = window.confirm(
              "You are about to generate a report for " +
                Object.values(included).filter((e) => e === true).length +
                " listing items.\n Would you like to proceed?"
            );
            if (a) {
              context.actions({
                type: "openModal",
                payload: {
                  modalComponent: (
                    <ReportForm
                      list={Object.keys(included).filter((el) => included[el])}
                      time={time}
                      isExport={true}
                    ></ReportForm>
                  ),
                },
              });
            }
          }}
        >
          Generate
        </PrimaryButton>

        <ToggleSwitch
          isBig={false}
          Name="goo"
          dataYes="ALL"
          dataNo="CLR"
          defaultChecked={isEnabled}
          onChangeHandler={changeHandler.bind(this)}
        ></ToggleSwitch>
      </div>

      <ListingContent>
        <TimeSelector
          time={time}
          onTimeChange={changeYear}
          onPeriodChange={() => null}
          period={"year"}
          mode="monthOnly"
        ></TimeSelector>
        {props.list && props.list.length > 0 ? (
          filteredData.map((el, index) =>
            el.data.externalData.active == true ? (
              <ReportCard
                data={el}
                key={el.listingId}
                included={included[el.listingId]}
                onSelfInclude={onISelfInclude}
              ></ReportCard>
            ) : (
              ""
            )
          )
        ) : (
          <></>
        )}
      </ListingContent>
    </Page>
  );
};

export default Reports;
