import React, { useState, useEffect, useContext, useRef } from "react";
import FirebaseService from "../../services/FirebaseService";
import moment, { months } from "moment";
import { PrimaryButton } from "../../theme/theme";
import Context from "../../store/context";
import PQueue from "p-queue";

const { Parser } = require("json2csv");

const exportCsv = function (data) {
  console.log("data within exoport csv:" , data);
  const fields = ["property", "year", "period", "type", "source", "amount"];
  const opts = { fields };
  try {
    const parser = new Parser(opts);
    const csv = parser.parse(data);
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "_report.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  } catch (err) {
    console.error(err);
  }
};

const generateCSVData = function (obj, year) {
  console.log(obj)
  let entireData = [];

  let allmonths = Object.keys(obj.months);

  allmonths.forEach((period) => {
    let income1 = Object.keys(obj.months[period].income.distribution).map(
      (el) => {
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "income",
          source: el,
          amount:
            obj.months[period].income.distribution[el] !== null
              ? obj.months[period].income.distribution[el].toFixed(2)
              : 0,
        };
      }
    );
    let income2 = [];
    if (obj.income[period]) {
      income2 = Object.keys(obj.income[period].distribution).map((el) => {
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "income",
          source: el,
          amount: obj.income[period].distribution[el].amount.toFixed(2),
        };
      });
    }

    let expenses1 = Object.keys(obj.months[period].expenses.distribution).map(
      (el) => {
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "expense",
          source: el,
          amount: obj.months[period].expenses.distribution[el].toFixed(2),
        };
      }
    );
    let expenses2 = [];
    if (obj.expenses[period]) {
      //   console.log("******");
      // console.log(obj.expenses[period]);
      expenses2 = Object.keys(obj.expenses[period].distribution).map((el) => {
        // console.log("****  ", el);
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "expense",
          source: el,
          amount: obj.expenses[period].distribution[el].amount.toFixed(2),
        };
      });
    }

    let payouts = [];
    if (obj.payouts[period]) {
      payouts = Object.keys(obj.payouts[period].distribution).map((el) => {
        return {
          property: obj.title,
          year: year,
          period: period,
          type: "payout",
          source: el,
          amount: obj.payouts[period].distribution[el].amount.toFixed(2),
        };
      });
    }
    entireData = [
      ...entireData,
      ...income1,
      ...income2,
      ...expenses1,
      ...expenses2,
      ...payouts,
    ];
  });
  //console.log("entiredata: " , entireData);
  return  entireData;
};

const ReportForm = (props) => {
  console.log("props" , props);
  const [loading, setLoading] = useState(0);
  const [isDone, setDone] = useState(false);
  const [data, setData] = useState([]);
  const context = useContext(Context);
  const loadingBar = useRef(null);
  const isDualYear = !props.isExport;
  const queue = new PQueue({ concurrency: 1 });

  useEffect(() => {
    //alert("Queue:" + queue.concurrency);
    try {
      const loadReport = async (list) => {
        let reportData = await loadReportData(list);
      };
      loadReport(props.list);
    } catch (e) {
      console.log("UseEffect Error catch");
    }
  }, [props.list]);

  const loadReportData = async (listigIds) => {
    try {
      let promises = [];
      let counter = 0;
      let newData = [];
      let resCurrent = [];
      let tempNum = 0;
      ////////////////////////////////////////////////////////////////////////////////////////////////////
      let myVar = [];
      console.log(listigIds);
      //USING QUEUE API
      queue.on("completed", (res) => {
        console.log("completedres" , res);
        
        if (isDualYear) {
          res[0].data.prev = res[1].data;
          newData[counter] = res[0].data;
        }
        counter++;
        setLoading((counter / temp.length) * 100);
        if (props.isExport === true) {
          //CSV GENERATION - TODO
          //setTimeout(15000);
          console.log(newData);
          console.log("res[0].data:" , res[0].data);
          newData = [
            ...newData,
            ...generateCSVData(res[0].data, props.time.year()),
          ];
          console.log("newdata" , newData);
        } else {
          console.log("...res[0].data",{ ...res[0].data});
          let thisResult = {...res[0].data};
          console.log(thisResult);
          Object.keys(thisResult['months']).forEach(m=>{
            console.log("ddd" , thisResult['expenses'][m]);
            if(thisResult['expenses'][m] != undefined){
              Object.keys(thisResult['expenses'][m]['distribution']).forEach(expenses=>{
                console.log(expenses);
                console.log(thisResult['expenses'][m]['distribution'][expenses]['amount']);
                if(expenses==='Management fee'){
                  thisResult['months'][m]['expenses']['distribution']['Management fee'] += thisResult['expenses'][m]['distribution'][expenses]['amount'];
                }
              })
            }
            console.log(m);
            console.log(thisResult['expenses'][m]);
            // thisResult['months'][m]['expenses']['distribution']['Management fee'] += thisResult['expenses'][m]['distribution']['Management fee']['amount'];
            console.log(thisResult['months'].m);
            console.log(thisResult['months'][m]);
            Object.keys(thisResult['months'][m]).forEach(r=>{
              console.log(r);
              console.log(thisResult['months'][m][r]);
              Object.keys(thisResult['months'][m]['income']['distribution']).forEach(dis=>{
                if(dis.includes('@')){
                    let temp = dis.split('@')[1];
                    temp = temp.split('.')[0];
                    thisResult['months'][m]['income']['distribution'][temp] = thisResult['months'][m]['income']['distribution'][dis];
                    delete thisResult['months'][m]['income']['distribution'][dis]; 
                    console.log(thisResult['months'][m]['income']['distribution']);
                }
                else if(dis.includes('.')){
                  if(!dis.includes('@')){
                    let temp = dis.split('.')[0];
                    thisResult['months'][m]['income']['distribution'][temp] = thisResult['months'][m]['income']['distribution'][dis];
                    delete thisResult['months'][m]['income']['distribution'][dis];             
                    console.log(thisResult['months'][m]['income']['distribution'])
                    }
                  }
              })
            })            
          })
          resCurrent.push({ ...res[0].data, id: res[0].listingId });
        }

        if (temp.length===counter) {
          
          
          console.log("QUEUE  :  COMPLETE", counter);
          if (props.isExport === true) {
            console.log("newdatabefore:" , newData);
            setData(newData);
            console.log("newDate" , newData);
          } else {
            console.log("rescurrent:" , resCurrent);
            props.onLoad(resCurrent);
            console.log("Report is Done!!!", resCurrent);
            context.actions({
              type: "setState",
              payload: {
                ...context.state,
                isFetching: false,
                isModalOpen: props.isExport,
                modalComponent: "",
              },
            });
          }
          setDone(true);
        }
        
      });
      
      var resqueue =queue.on("active", ()  => {
         console.log(
          `Working on item #${counter}.  Size: ${queue.size}  Pending: ${queue.pending}`
        );
      });
      console.log(resqueue);

      queue.on("error", (e) => {
        console.log(e);
        queue.pause();
        queue.clear();
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isFetching: false,
            isModalOpen: false,
            modalComponent: "",
            isError: true,
            errorMsg:
              "There was a communication error while requesting data from Guesty's server : " +
              e.message,
          },
        });
      });

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      let temp = listigIds.map(async (el, index) => {
        queue.add(
          async () => {
            try {
              return await FirebaseService.getListingAnnualPerformanceData(
                el,
                props.time.startOf("month").format("YYYY-MM-DD"),
                true,
                isDualYear
              );
            } catch (e) {
              console.log(e);
              context.actions({
                type: "setState",
                payload: {
                  ...context.state,
                  isFetching: false,
                  isModalOpen: false,
                  modalComponent: "",
                  isError: true,
                  errorMsg: e.message,
                },
              });
            }
          },
          { priority: 0 }
        );
      });
      console.log("QUEUE TOTAL SIZE : ==> ", queue.size);
      //////////////////////////////////////////////////////////////////////////////////////////////
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
          isError: true,
          errorMsg: "There was a communication error with Guesty's server",
        },
      });
    }
  };
function GetData(){
  return data;
}
  return (
    <>
      <div>
        {!isDone ? (
          <>
            <div>
              <h1>Do not close this window!</h1>
            </div>
            <div>
              <h1>{loading.toFixed(1)}%</h1>
              <h1> Loading in progress...</h1>
            </div>
            <div className="lds-hourglass"> </div>
          </>
        ) : (
          <>
            <div>
              <h1>Loading was completed</h1>
            </div>
            <PrimaryButton onClick={(e) =>exportCsv(data)}>
              Download report
            </PrimaryButton>
          </>
        )}
      </div>
    </>
  );
};

export default ReportForm;
