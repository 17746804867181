import React, { useState, useEffect, useContext } from "react";
import ListCard from "./ListCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ToggleSwitch from "../ui/ToggleSwitch";
import { Page, Content } from "../../theme//theme.js";
import GlobalContext from "../../store/context";

const ListingContent = styled.div`
  padding-top: 14vh;
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;

const Listings = (props) => {
  const context = useContext(GlobalContext);

  const [isEnabled, setIsEnabled] = useState(true);

  const [filteredData, setFiltered] = useState(props.list);

  console.log("LISTING STATE ", context.state);

  const changeHandler = () => {
    setIsEnabled(!isEnabled);
  };

  const onSearch = (event) => {
    const regex = new RegExp(event.target.value, "i");
    const filtered = props.list.filter(function (datum) {
      console.log("props.list.title", props.list.title);
      return datum.data.title.search(regex) > -1;
    });
    setFiltered(filtered);
  };

  return (
    <Page>
      <div className="top-menu-bar">
        <div className="search-bar">
          <FontAwesomeIcon icon={faSearch}/>
          <input
            type="text"
            placeholder="Search"
            onChange={onSearch.bind(this)}
          />
        </div>

        <ToggleSwitch
          isBig={false}
          Name="goo"
          dataYes="ACT"
          dataNo="DIS"
          defaultChecked={isEnabled}
          onChangeHandler={changeHandler.bind(this)}
        ></ToggleSwitch>
      </div>

      <ListingContent>
        {props.list && props.list.length > 0 ? (
          filteredData.map((el, index) =>
            el.data.externalData.active == isEnabled ? (
              <ListCard data={el} key={el.listingId}></ListCard>
            ) : (
              ""
            )
          )
        ) : (
          <></>
        )}
      </ListingContent>
    </Page>
  );
};

export default Listings;
