import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import ToggleSwitch from "../ui/ToggleSwitch";
import Context from "../../store/context";

const ListCardView = styled.div`
  border-radius: 4px;
  margin: 4px;
  background-color: var(--white);
  height: 320px;
  max-width: 370px;
  min-width: 370px;
  display: flex;
  flex-direction: column;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: var(--darkblue) !important;
  background-color: var(--white);
  box-shadow: var(--darkgray) 1px 2px 4px;
  position: relative;
  & .hero {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: white; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    height: 100%;
    margin: 4px 4px 0 4px;
    box-sizing: border-box;
    text-align: left;
    position: relative;
    max-height: 140px;
  }
  & .hero img {
    max-height: 140px;
    width: auto; 
  }
  & .occupancyTable {
    display: flex;
    flex-direction: row nowrap;
    background-color: var(--mediumgray);
    min-height: 40px;
    justify-content: space-around;
  }
  & .occupancyBlock {
    font-size: 0.7rem;
    width: flex 1;
    padding: 5px 0 5px 0;
    height: 2rem;
  }
  & .listing-edit-button {
    color: var(--white);
    background-color: var(--darkgray);
    padding: 4px;
    max-width: 2.3rem;
    border-bottom-right-radius: 8px;
  }
  & .listing-expand-button {
    position: absolute;
    top: 40%; 
    right: 10px; 
    transform: translateY(-50%);
    color: var(--white);
    background-color: var(--darkgray);

    padding: 4px;
    border-radius: 8px;
    cursor: pointer; 
    fontSize: 1.8rem;
    z-index: 1;
    opacity: 1;
  }
 
  & .listing-switch-active {
    position: absolute;
    top: 3px;
    right: 0px;
    opacity: 0.825;
    display: none;
  }
  & .click-pad {
    position: absolute;
    top: 4rem;
    left: 0px;
    height: 30%;
    width: 100%;
  }
  & .listTitle {
    text-align: left;
    margin-left: 8px;
    padding: 10px 0 10px 5px;
    font-weight: 800;
  }

  &.card-chart {
    font-size: 0.5rem;
    cursor: pointer;
  }

`;

function ListCard(props) {
  var divImage = {
    backgroundImage: "url(" + props.data.data.thumb + ")",
  };

  const context = useContext(Context);

  let chartData = Object.keys(props.data.data.periodStats.stats.months).map(
    (e) => {
      return {
        month: e,
        rate: props.data.data.periodStats.stats.months[e].rate,
        sort: props.data.data.periodStats.stats.months[e].sort,
      };
    }
  );
  chartData = chartData.sort((a, b) => a.sort - b.sort);
  console.log(chartData);

  const onEditListingHandler = function (e) {
    e.preventDefault();
    console.log(e);
  };

  return (
    <ListCardView>
      {context.state.isAdmin ? (
        <div className="listing-switch-active">
          <ToggleSwitch
            dataYes="ACT"
            dataNo="DIS"
            Name={"isActive_" + props.data.listingId}
            onChangeHandler={() => {
              props.data.data.externalData.active = !props.data.data
                .externalData.active;
            }}
            defaultChecked={props.data.data.externalData.active}
          ></ToggleSwitch>
        </div>
      ) : (
        <></>
      )}

      <div className="hero" style={divImage}>
        {context.state.isAdmin ? (
          <div className="listing-edit-button">
            <Link to={"/listing/edit/" + props.data.listingId}>
              <FontAwesomeIcon icon={faTools} style={{ fontSize: "1.8rem" }} />
            </Link>
          </div>
        ) : (
          <></>
        )}
          
        <div className="listing-nickname">{props.data.data.nickname}</div>
        
      </div>
      <div className="listing-expand-button">
            <a href={"/listing/" + props.data.listingId} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faExpand}  />
            </a>
          </div>
      <Link to={"/listing/" + props.data.listingId}>
        <div className="click-pad"></div>
        <div className="listTitle wordwrap">{props.data.data.title}</div>
        <div className="occupancyTable">
          <div className="occupancyBlock">
            <div>14 Days</div>
            <div>
              <b>
                {Math.round(
                  props.data.data.periodStats.stats.daysRate14 * 100
                ).toFixed(0)}{" "}
                %
              </b>
            </div>
          </div>
          <div className="occupancyBlock">
            <div>30 Days</div>
            <div>
              <b>
                {(props.data.data.periodStats.stats.daysRate30 * 100).toFixed(
                  0
                )}{" "}
                %
              </b>
            </div>
          </div>
          <div className="occupancyBlock">
            <div>90 Days</div>
            <div>
              <b>
                {(props.data.data.periodStats.stats.daysRate90 * 100).toFixed(
                  0
                )}{" "}
                %
              </b>
            </div>
          </div>
        </div>

        <div className="card-chart">
          <BarChart
            width={340}
            height={80}
            data={chartData}
            margin={{
              top: 5,
              right: 0,
              bottom: 0,
              left: -10,
            }}
          >
            <Bar dataKey="rate" fill="#8884d8" />
            <YAxis tickFormatter={toPercent} tick={{ fontSize: 10 }} />

            <XAxis dataKey="month" tick={{ fontSize: 11 }} />
          </BarChart>
        </div>
      </Link>
    </ListCardView>
  );
}

export default ListCard;

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

/*




import React, { PureComponent } from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

const data = [
  {
    month: '2015.01', a: 4000, b: 2400, c: 2400,
  },
  {
    month: '2015.02', a: 3000, b: 1398, c: 2210,
  },
  {
    month: '2015.03', a: 2000, b: 9800, c: 2290,
  },
  {
    month: '2015.04', a: 2780, b: 3908, c: 2000,
  },
  {
    month: '2015.05', a: 1890, b: 4800, c: 2181,
  },
  {
    month: '2015.06', a: 2390, b: 3800, c: 2500,
  },
  {
    month: '2015.07', a: 3490, b: 4300, c: 2100,
  },
];


const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;
  return toPercent(ratio, 2);
};



const renderTooltipContent = (o) => {
  const { payload, label } = o;
  const total = payload.reduce((result, entry) => (result + entry.value), 0);

  return (
    <div className="customized-tooltip-content">
      <p className="total">{`${label} (Total: ${total})`}</p>
      <ul className="list">
        {
        	payload.map((entry, index) => (
          	<li key={`item-${index}`} style={{ color: entry.color }}>
            	{`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
           </li>
        	))
        }
      </ul>
    </div>
  );
};

export default class Example extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/zsax2hyq/';

  render() {
    return (
      <AreaChart
        width={500}
        height={400}
        data={data}
        stackOffset="expand"
        margin={{
          top: 10, right: 30, left: 0, bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis tickFormatter={toPercent} />
        <Tooltip content={renderTooltipContent} />
        <Area type="monotone" dataKey="a" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="b" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        <Area type="monotone" dataKey="c" stackId="1" stroke="#ffc658" fill="#ffc658" />
      </AreaChart>
    );
  }
}
*/
