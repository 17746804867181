import { Page } from "../../theme/theme";
import React, { useState, useEffect, useContext, useReducer } from "react";
import GlobalContext from "../../store/context";
import moment from "moment";
import TimeSelector from "../ui/TimeSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ReportCard from "./ReportCard";
import TaxExport from "./TaxExport";
import styled from "styled-components";
import { PrimaryButton } from "../../theme//theme.js";
import ReportForm from "../ui/ReportForm";
import { MetricBox } from "../../theme/theme";
import ToggleSwitch from "../ui/ToggleSwitch";

import {
    faThermometerThreeQuarters,
    faMoon,
    faMoneyBillWave,
    faAddressBook,
    faConciergeBell,
    faFunnelDollar,
    faArrowUp,
    faArrowDown,
  } from "@fortawesome/free-solid-svg-icons";
  const ListingContent = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;
const TaxReport = (props)=>{
    console.log(props);
    console.log(!props.period);
    
    const context = useContext(GlobalContext);
    const [time, setTime] = useState(moment());
    const [year, setYear] = useState(moment.utc().format("YYYY"));
    const [isEnabled, setIsEnabled] = useState(true);
    const [period, setPeriod] = useState(false); // true means annual , false is month
    const [bulk, setBulk] = useState(period ? "annual" : time.format("MMM"));
    const onSearch = (event) => {
        const regex = new RegExp(event.target.value, "i");
        const filtered = props.list.filter(function (datum) {
          return datum.data.title.search(regex) > -1;
        });
        setFiltered(filtered);
      };
    const [filteredData, setFiltered] = useState(props.list);
    const changeHandler = (val) => {
      setIncluded(incldeAll(val));
    };
    const incldeAll = function (val) {
        return props.list
          .filter((el) => el.data.externalData.active)
          .reduce((accum, el) => {
            accum[el.listingId] = val;
            return accum;
          }, {});
      };
      
  const onISelfInclude = (id) => {
    let next = {};
    Object.assign(next, included);
    next[id] = !included[id];
    setIncluded(next);
    console.log(id);
  };

  const [included, setIncluded] = useState(incldeAll(true));

      const onPeriodChange = function (val) {
        setPeriod(val);
        setBulk(val ? "annual" : time.format("MMM"));
      };
      const changeTime = function (newTime) {
        console.log(newTime);
        setTime(newTime);
        setBulk(period ? "annual" : newTime.format("MMM"));
        setYear(newTime.format("YYYY"));
      };
      const changeMonth = function (dir) {
        console.log("REDUCE TIME");
        let newT = time.clone();
        !props.period ? newT.add(dir, "months") : newT.add(dir, "years");
        changeTime(newT);
      };
    console.log(context);
    return (
        <Page>
      <div className="top-menu-bar">
      <div className="search-bar desktop">
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            placeholder="Search"
            onChange={onSearch.bind(this)}
          />
        </div>      
          
        <PrimaryButton style={{width:"150px"}}
          onClick={() => {
            let a = window.confirm(
              "You are about to generate a report for "+
                Object.values(included).filter((e) => e === true).length +
                " listing items.\n  Would you like to proceed?"
            );
            if (a) {
              context.actions({
                type: "openModal",
                payload: {
                  modalComponent: (
                    <TaxExport
                      list = {Object.keys(included).filter((el) =>included[el])} 
                      time={time}
                      isExport={true}
                    ></TaxExport>
                  ),
                },
              });
            }
          }}
        >
          Generate
        </PrimaryButton>
          <ToggleSwitch
          isBig={false}
          Name="goo"
          dataYes="ALL"
          dataNo="CLR"
          defaultChecked={isEnabled}
          onChangeHandler={changeHandler.bind(this)}
        ></ToggleSwitch>
        </div>
        
        <div style={{ paddingTop: "75px"}} className="tax-report-time-selector">
                <TimeSelector
                    time={time}
                    onTimeChange={changeMonth}
                    onPeriodChange={() => props.onPeriodChange(!props.period)}
                    period={props.period}
                    mode="monthOnly"
                ></TimeSelector>
            </div>
          <ListingContent>  

            
          

          {props.list && props.list.length > 0 ? (
          filteredData.map((el, index) =>
            el.data.externalData.active == true ? (
              <ReportCard
                data={el}
                key={el.listingId}
                included={included[el.listingId]}
                onSelfInclude={onISelfInclude}
              ></ReportCard>
            ) : (
              ""
            )
          )
        ) : (
          <></>
        )}
        </ListingContent>
        </Page>
    );
};

export default TaxReport;
