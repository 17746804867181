import React, { useState, useContext } from "react";
import {
  Form,
  Input,
  Title,
  Text,
  Button,
  Label,
  Select,
} from "../../theme//theme.js";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  border: 1px solid ${({ theme }) => theme.darkGray};
  margin: 5px;
  & img {
    max-width: 33%;
    max-height: 40px;
    margin: 5px;
  }
`;

function ListingConfigForm(props) {
  console.log("ListingConfigForm(props)"  , props);
  const context = useContext(Context);
  const [startDate, setStartDate] = useState(new Date());
  const submitForm = async (values) => {
    context.actions({
      type: "setState",
      payload: { ...context.state, isFetching: true },
    });
    if (props.init == null) {
      try {
        await FirebaseService.saveListingConfig(values, props.listingId);
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isFetching: false,
            isModalOpen: false,
            modalComponent: "",
          },
        });
        props.refresh();
      } catch (e) {
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isError: true,
            errorMsg: e.message,
            isModalOpen: false,
            modalComponent: "",
          },
        });
      }
    } else {
      // Update
      try {
        await FirebaseService.updateListingConfig(
          values,
          props.listingId,
          props.configId
        );
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isFetching: false,
            isModalOpen: false,
            modalComponent: "",
          },
        });
        props.refresh();
      } catch (e) {
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isError: true,
            errorMsg: e.message,
            isModalOpen: false,
            modalComponent: "",
          },
        });
      }
    }
  };

  return (
    <>
      <Title>{props.title}</Title>

      {/* FORMIK */}
      <Formik
        initialValues={
          !props.init
            ? {
                year: 2019,
                month: 2,
              }
            : props.init
        }
        validate={(values) => {
          console.log("VALIDATE : ", values);
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        enableReinitialize={false}
        validationSchema={yup.object().shape({
          month: yup.number(),
          year: yup.number(),
          stateTax: yup.number().required(),
          cityTax: yup.number().required(),
          managementFee: yup.number().required(),
          ListingActualCleaninCost: yup.number().required(),
        })}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Label>
              Month
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.month}
                border={errors.month && "1px solid red"}
                type="number"
                name="month"
                placeholder="Expense Amount"
                disabled={props.init !== undefined}
              >
                <option value={1}>Jan</option>
                <option value={2}>Feb</option>
                <option value={3}>Mar</option>
                <option value={4}>Apr</option>
                <option value={5}>May</option>
                <option value={6}>Jun</option>
                <option value={7}>Jul</option>
                <option value={8}>Aug</option>
                <option value={9}>Sep</option>
                <option value={10}>Oct</option>
                <option value={11}>Nov</option>
                <option value={12}>Dec</option>
              </Select>
            </Label>

            <Label>
              Year
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.year}
                border={errors.year && "1px solid red"}
                type="number"
                name="year"
                placeholder="Expense Amount"
                disabled={props.init !== undefined}
              >
                <option value={1980}>1980</option>
                <option value={2015}>2015</option>
                <option value={2016}>2016</option>
                <option value={2017}>2017</option>
                <option value={2018}>2018</option>
                <option value={2019}>2019</option>
                <option value={2020}>2020</option>
                <option value={2021}>2021</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
                <option value={2025}>2025</option>
                <option value={2026}>2026</option>
                <option value={2027}>2027</option>
                <option value={2028}>2028</option>
                <option value=""></option>
              </Select>
            </Label>
            <Label>
              City Tax
              {errors.cityTax && <Text color="red">{errors.cityTax}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.cityTax}
                border={errors.cityTax && "1px solid red"}
                type="number"
                name="cityTax"
                placeholder="Between 0 and 1"
              />
            </Label>
            <Label>
              State Tax
              {errors.stateTax && <Text color="red">{errors.stateTax}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.stateTax}
                border={errors.stateTax && "1px solid red"}
                type="number"
                name="stateTax"
                placeholder="Between 0 and 1"
              />
            </Label>
            <Label>
              Management Fee Rate
              {errors.managementFee && (
                <Text color="red">{errors.managementFee}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.managementFee}
                border={errors.managementFee && "1px solid red"}
                type="number"
                name="managementFee"
                placeholder="Between 0 and 1"
              />
            </Label>
            <Label>
              Actual Cleaning Cost
              {errors.ListingActualCleaninCost && (
                <Text color="red">{errors.ListingActualCleaninCost}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.ListingActualCleaninCost}
                border={errors.ListingActualCleaninCost && "1px solid red"}
                type="number"
                name="ListingActualCleaninCost"
                placeholder="Amount in $"
              />
            </Label>
            <Label>
              Avalara Propery Id
              {errors.AvalaraId && (
                <Text color="red">{errors.AvalaraId}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.AvalaraId}
                border={errors.AvalaraId && "1px solid red"}
                type="number"
                name="AvalaraId"
                placeholder="number like 11111"
              />
            </Label>

            {/* 25/02/2023 adding new configuration field for the tax report */}
            <Label>
              Ownership
              {errors.Ownership && (
                <Text color="red">{errors.Ownership}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Ownership}
                border={errors.Ownership && "1px solid red"}
                type="string"
                name="Ownership"
                placeholder="israel israeli"
              />
            </Label>
            
            <Label>
              State Filling Field
              {errors.StateFillingField && (
                <Text color="red">{errors.StateFillingField}</Text>
              )}
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.StateFillingField}
                border={errors.StateFillingField && "1px solid red"}
                type="number"
                name="StateFillingField"
                placeholder="State Filling Field"
              >
                <option value={3}>N/A</option>
                <option value={1}>Monthly</option>
                <option value={2}>Quarterly</option>
              </Select>
            </Label>

            <Label>
              City Filling Field
              {errors.CityFillingField && (
                <Text color="red">{errors.CityFillingField}</Text>
              )}
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.CityFillingField}
                border={errors.CityFillingField && "1px solid red"}
                type="number"
                name="CityFillingField"
                placeholder="City Filling Field"
              >
                <option value={3}>N/A</option>
                <option value={1}>Monthly</option>
                <option value={2}>Quarterly</option>

              </Select>
            </Label>

            <Label>
              County Filling Field
              {errors.CountyFillingField && (
                <Text color="red">{errors.CountyFillingField}</Text>
              )}
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.CountyFillingField}
                border={errors.CountyFillingField && "1px solid red"}
                type="number"
                name="CountyFillingField"
                placeholder="County Filling Field"
                
              >
                <option value={3}>N/A</option>
                <option value={1}>Monthly</option>
                <option value={2}>Quarterly</option>

              </Select>
            </Label>

            
            <Button type="submit">Save</Button>
          </Form>
        )}
      </Formik>
      {/* END OF FORMIK */}
    </>
  );
}

export default ListingConfigForm;
