import styled from "styled-components";

// Forms, inputs, buttons

export const theme = {
  primaryDark: "#282c34",
  primaryLight: "white",
  primaryHover: "#343078",
  mobile: "1024px",
  brandColor: "rgb(253,224,117)",
  mediumGray: "rgba(0, 0, 0, 0.04)",
  darkGray: "#CCC",
  modalBackground: "rgba(0, 0, 0, 0.64)",
  subMenu: "#4d4d4d",
  posGreen: "#82ca9d",
  negRed: "#FF5E71",
  white: "white;",
  modalindex: 9999,
  preloaderIndex: 10000,
  ErrorIndex: 10001,
};

export const Form = styled.form`
  width: fit-content;
  max-width: 400px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: ${({ theme }) => theme.white};
  padding: 2rem;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  border: 1px ${({ theme }) => theme.darkGray} solid;
`;

export const Select = styled.select`
  font-size: 1.1rem;
  border-color: ${({ theme }) => theme.mediumGray};
  margin: 0.7rem 0.5rem;
`;

export const Input = styled.input`
  border-radius: 4px;
  margin-bottom: 0.7rem;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  height: flex 1;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 16px;
  &:read-only {
    background-color: var(--darkgray) !important;
    cursor: not-allowed;
  }
`;
export const Button = styled.button`
  border-radius: 4px;
  width: fit-content;
  line-height: 2.2rem;
  border: 2px solid ${({ theme }) => theme.primaryDark};
  color: ${({ theme }) => theme.primaryDark};
  font-size: 1rem;
  height: 2.5rem;
  padding: 0 1.5rem;
  background-color: ${({ theme }) => theme.brandColor};
  cursor: pointer;
  margin: auto;
`;
export const RoundButton = styled(Button)`
  border-radius: 2rem;
  width: 2.5rem;
  padding: 0;
  text-align: center;
`;

export const Button2 = styled.button`
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  border-radius: 3px;
  max-width: 180px;
  font-size: 16px;
  color: ${(props) => props.theme.primaryDark};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.4);
  transition: background-color 0.3s cubic-bezier(0.9, 0, 0.2, 1),
    box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  &.primary {
    background-color: var(--posgreen);
  }
  &:hover {
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.4);
  }
  &.link.link:active {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    transform: translateY(1px);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
  @media (max-width: 1024px) {
    transform: scale(0.825);
  }
`;

export const PrimaryButton = styled(Button)`
  margin: 0;
`;

export const Title = styled.h1`
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.subMenu};
  font-size: 2.2em;
`;

export const SubTitle = styled.h3`
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.subMenu};
  font-size: 1.8rem;
`;

export const Label = styled.label`
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 1rem;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.negRed};
  font-size: 0.9rem;
  margin: 0.5rem;
`;

export const Page = styled.div`
  overflow-y: auto !important;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
`;

export const Content = styled.div`
  padding: 12vh 1rem 0 1rem;
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-content: flex-start;
  min-height: 100vh;
`;
export const Preloader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  background-color: white;
  opacity: 0.9;
  width: 100vw;
  height: 100vh;
  z-index: ${({ theme }) => theme.preloaderIndex};
`;
export const Burger = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1.6rem;
  width: 2rem;
  height: 2rem;
  z-index: 1001;
  color: var(--gnorange);
  @media (min-width: 1024px) {
    display: none;
    position: absolute;
    left: -50px;
  }
`;

export const MobileNav = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: var(--gnorange);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const MetricBox = styled.div`
  height: 6rem;
  border: 1px var(--darkgray) solid;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  padding: 0.5rem 2rem;
  color: var(--darkblue);
  background-color: var(--white);
  box-shadow: var(--darkgray) 1px 1px 1px;
  border-radius: 4px;
  margin: 4px;
  min-width: 18vw;
  @media (max-width: 1024px) {
    width: 34vw !important;
    min-width: 30px;
  }
  @media (max-width: 870px) {
    width: 38vw !important;
    min-width: 30px;
    padding: 0.5rem 1rem;
  }
  & h1 {
    font-weight: 400 !important;
    font-size: 1.8rem;
    margin: 1.2rem 0 0 0.3rem;
    text-align: center;
    @media (max-width: 1024px) {
      font-size: 1.1rem;
      margin: 1.4rem 0 0 0;
    }
  }
  & .sign {
    display: inline-block;
    font-size: 0.8rem;
    margin: 0 0.2rem 0 0.2rem;
    line-height: 1rem;
    vertical-align: middle;
    @media (max-width: 1024px) {
      font-size: 0.7rem;
      line-height: 0.8rem;
    }
  }
  & h3 {
    position: absolute;
    font-size: 0.8rem;
    left: 0.5rem;
    top: -0.5rem;
    color: var(--posgreendark);
    @media (max-width: 1024px) {
      font-size: 0.8rem;
    }
  }
  & h5 {
    position: absolute;
    font-size: 0.7rem;
    left: 0.5rem;
    bottom: -0.5rem;

    &.positive {
      color: green;
    }
    &.negative {
      color: red;
    }
    @media (max-width: 1024px) {
      font-size: 0.5rem;
    }
  }
  &.primary {
    background-color: ${(props) => props.theme.brandColor};
    width: 100% !important;
    max-width: 300px;
    @media (max-width: 1024px) {
      width: 100%;
    }

    @media (max-width: 1024px) {
      font-size: 0.3rem;
    }
  }
  & .info {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 1rem;
  }

  &.small {
    height: 4rem;
    border: 1px var(--darkgray) solid;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    color: var(--darkblue);
    background-color: var(--white);
    box-shadow: var(--darkgray) 1px 1px 1px;
    border-radius: 4px;
    margin: 4px;
    min-width: 18vw;
    & h1 {
      font-weight: 400 !important;
      font-size: 1.2rem !important;
      margin: 1rem 0 0 0.3rem;
      text-align: center;
      @media (max-width: 1024px) {
        font-size: 0.9rem;
      }
    }
  }
`;

export const ErrorPopup = styled.div`
  position: absolute;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;

  & .container {
    position: absolute;
    z-index: 1501;
    width: 70vw;
    height: 40vh;
    top: 20vh;
    padding: 20px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.white};
    & .closeButton {
      position: absolute;
      top: 20;
      left: 20;
      color: black;
      font-size: 1.5rem;
    }
  }
`;
