import React, { useState, useEffect, useContext } from "react";
import { useParams, Redirect } from "react-router-dom";
import styled from "styled-components";
import SubMenu from "../NavBar/SubMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Page, Content, MetricBox, PrimaryButton } from "../../theme/theme";
import Modal from "../ui/Modal";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ActivityView from "./ActivityView";
import VacationView from "./VacationView";
import FinView from "./FinView";
import moment from "moment";
import ROIComponent from "./ROIComponent";
import ROITemp from "./ROITemp";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";
import SetupListingForm from "./SetupListingForm";

const MenuBar = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.primaryDark};
  box-shadow: ${(props) => props.theme.darkGray} 1px 1px 2px;
  top: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
  z-index: 1001;
  height: 3.5rem;
  &.secondary {
    z-index: 100;
    top: 3.5rem;
    background-color: white;
    background-color: ${(props) => props.theme.white};
  }
  &.short {
    width: calc(100% - 16px);
  }

  @media (max-width: 1024px) {
    position: fixed;
    background-color: ${({ theme }) => theme.primaryDark};
  }
`;

const RefreshBar = styled.div`
  width: 100%;
  height: 1.7rem;
  background-color: ${(props) => props.theme.negRed};
  color: ${(props) => props.theme.darkGray};
`;
const ViewContent = styled(Content)`
  padding-top: 7rem;
  overflow-y: auto;
`;

const ListTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 3.5rem;
  color: ${({ theme }) => theme.brandColor};
  @media (max-width: 1024px) {
    font-size: 0.7rem;
    font-weight: 100;
  }
`;
const BackButton = styled(Link)`
  color: ${({ theme }) => theme.brandColor};
  font-size: 1.8rem;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const minimizeAddress = (address)=>{
  return address.split(",").slice(0, 2).join('')
}

function ViewListing(props) {
  
  const context = useContext(Context);

  //Data sets
  const [stats, setStats] = useState({ title: "", months: null });
  const [calendar, setCal] = useState([]);
  const [reservations, setRes] = useState([]);
  const [canceled, setCanceled] = useState([]);

  //the requested date for pulling data and presenting
  const [time, setTime] = useState(moment.utc());
  const [refreshStamp, refresh] = useState(Math.random());

  //A boolean flag to switch between annual view and month view
  const [period, setPeriod] = useState(false); // true means annual , false is month

  const [year, setYear] = useState(moment.utc().format("YYYY"));

  //the bulk of data entry - could be a name of a month in MMM format or "annual"
  const [bulk, setBulk] = useState(period ? "annual" : time.format("MMM"));

  const [isFreshData, setIsFreshData] = useState(0);

  let { id } = useParams();

  //controlled by the submenue
  const [view, setView] = useState(0);

  let subMenuItems = context.state.isAdmin
    ? [
        { label: "Activity", to: "/" },
        { label: "Finance", to: "/" },
        //{ label: "ROI", to: "/" },
        // { label: "Setup", to: "/" },
         { label: "< Edit", to: "/" },
      ]
    : [
        { label: "Activity", to: "/" },
        { label: "Finance", to: "/" },
        // { label: "ROI", to: "/" },
        // { label: "Setup", to: "/" },
      ];

  const changeView = function (selectedView) {
    setView(selectedView);
    console.log(view);
  };

  const onPeriodChange = function (val) {
    setPeriod(val);
    setBulk(val ? "annual" : time.format("MMM"));
  };

  const changeTime = function (newTime) {
    setTime(newTime);
    setBulk(period ? "annual" : newTime.format("MMM"));
    setYear(newTime.format("YYYY"));
  };

  useEffect(() => {
    const loadList = async function (time) {
      console.log("VIEW LISTING USE EFFECT");
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });

      let dateString = time.startOf("month").format("YYYY-MM-DD");

      console.log("DATE STRING : ", dateString);
      let list;
      let prevList;
      ///////////

      try {
        let annualData = await FirebaseService.getListingAnnualPerformanceData(
          id,
          dateString
        );
        console.log("ANNUAL DATA  ::::::::::::::::::::::::::::::" , annualData);
        list = annualData[0];
        prevList = annualData[1];

        if (list && prevList) {
          let calDataObj = list.data.cal;
          let resDataObj = list.data.reservations;
          let cancelObj = list.data.canceled;

          setCal(calDataObj);
          setRes(resDataObj);
          setCanceled(cancelObj);

          let s = {
            title: list.data.nickname + "  , " + minimizeAddress(list.data.title),
            months: list.data.months,
            expenses: list.data.expenses,
            income: list.data.income,
            payouts: list.data.payouts,
            prevRev: prevList.data.months,
          };
          setStats(s);
          console.log("STATS SET: ", s);
          context.actions({
            type: "setState",
            payload: { ...context.state, isFetching: false },
          });
        }
      } catch (e) {
        context.actions({
          type: "setState",
          payload: { ...context.state, isError: true, errorMsg: e.message },
        });
      }
    };

    loadList(time);
  }, [year, refreshStamp]);

  return (
    <Page>
      <MenuBar>
        <BackButton to="/listings">
          <FontAwesomeIcon icon={faChevronLeft} />
        </BackButton>
        <ListTitle>{stats.title}</ListTitle>
      </MenuBar>

      <MenuBar className="secondary short">
        <SubMenu
          onChangeMode={changeView.bind(this)}
          mode={view}
          items={subMenuItems}
        ></SubMenu>
        {isFreshData ? (
          <RefreshBar
            onClick={() => {
              setIsFreshData(0);
              refresh(Math.random());
            }}
          >
            Data is not fully updated. Click to refresh{" "}
          </RefreshBar>
        ) : (
          ""
        )}
      </MenuBar>

      <ViewContent>
        {view == 0 ? (
          <ActivityView
            {...props}
            calendar={calendar}
            reservations={reservations}
            onTimeChange={changeTime}
            onPeriodChange={onPeriodChange.bind(this)}
            period={period}
            time={time}
            stats={stats}
            bulk={bulk}
          ></ActivityView>
        ) : (
          <></>
        )}
        {view == 4 && context.state.isAdmin ? (
          <Redirect to={"/listing/edit/" + id}></Redirect>
        ) : (
          <></>
        )}
                {view == 3 ? (
          // <SetupListingForm propertyId={id}></SetupListingForm>
          <Redirect to={"/listing/edit/" + id}></Redirect>
        ) : (
          <></>
        )}

        {view == 2 ? (
          // <SetupListingForm propertyId={id}></SetupListingForm>
          <Redirect to={"/listing/edit/" + id}></Redirect>
        ) : (
          <></>
        )}
        {/* {view == 2 ? <ROIComponent propertyId={id}></ROIComponent> : <></>} */}


        {view == 1 ? (
          <FinView
            {...props}
            onTimeChange={changeTime}
            onPeriodChange={onPeriodChange.bind(this)}
            period={period}
            time={time}
            data={stats}
            propertyId={id}
            calendar={calendar}
            reservations={reservations}
            canceled={canceled}
            bulk={bulk}
            year={year}
            refresh={() => refresh(Math.random())}
            notifyRefresh={() => setIsFreshData(1)}
          ></FinView>
        ) : (
          <></>
        )}
      </ViewContent>
    </Page>
  );
}

export default ViewListing;

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};
