import React, { useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import ToggleSwitch from "../ui/ToggleSwitch";
import Context from "../../store/context";

const ReportCardView = styled.div`
  border-radius: 4px;
  margin: 10px;
  background-color: var(--white);
  height: 140px;
  max-width: 200px;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color: var(--darkblue) !important;
  background-color: var(--white);
  box-shadow: var(--darkgray) 1px 2px 4px;
  position: relative;

  &.selected {
    background-color: ${({ theme }) => theme.brandColor};
  }
  & .hero {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: white; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    height: 100%;
    margin: 4px 4px 0 4px;
    box-sizing: border-box;
    text-align: left;
  }

  & .listing-switch {
    position: absolute;
    top: 3px;
    right: 0px;
    opacity: 0.925;
  }
  & .click-pad {
    position: absolute;
    top: 4rem;
    left: 0px;
    height: 30%;
    width: 100%;
    background-color: white;
    opacity: 0;
    z-index: 30;
  }
  @media (max-width: 1024px) {
  }
`;

function ReportCard(props) {
  var divImage = {
    backgroundImage: "url(" + props.data.data.thumb + ")",
  };

  const context = useContext(Context);

  let chartData = Object.keys(props.data.data.periodStats.stats.months).map(
    (e) => {
      return {
        month: e,
        rate: props.data.data.periodStats.stats.months[e].rate,
        sort: props.data.data.periodStats.stats.months[e].sort,
      };
    }
  );
  chartData = chartData.sort((a, b) => a.sort - b.sort);

  const onEditListingHandler = function (e) {
    e.preventDefault();
    console.log(e);
  };

  return (
    <ReportCardView className={props.included ? "selected" : ""}>
      <div className="listing-switch">
        <ToggleSwitch
          dataYes="✓"
          dataNo="X"
          Name={"isIncluded_" + props.data.listingId}
          onChangeHandler={() => {
            props.onSelfInclude(props.data.listingId);
          }}
          defaultChecked={props.included}
        ></ToggleSwitch>
      </div>

      <div className="hero" style={divImage}></div>

      <div className="listTitle wordwrap">{props.data.data.title}</div>
    </ReportCardView>
  );
}

export default ReportCard;

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;
