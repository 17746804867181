import { useState } from "react";
import FirebaseService from "../services/FirebaseService";

const useGlobalState = () => {
  const [state, setState] = useState({
    user: null,
    listings: [],
    userList: [],
    isFetching: false,
    modalComponent: "",
    isModalOpen: false,
    isError: false,
    errorMsg: "",
  });

  const actions = (action) => {
    const { type, payload } = action;
    switch (type) {
      case "setState":
        console.log("SET STATE ", payload);
        return setState(payload);
      case "openModal":
        console.log("OPEN MODAL ", payload);
        return setState({
          ...state,
          isModalOpen: true,
          modalComponent: payload.modalComponent,
        });
      case "closeModal":
        console.log("CLOSE MODAL ", payload);
        return setState({ ...state, isModalOpen: false, modalComponent: "" });
      case "getUsers":
        console.log("GET USERS ", payload);
        let func = async () => {
          setState({ ...state, isFetching: true });
          let users = await FirebaseService.getUsers();
          setState({ ...state, isFetching: false });
          return setState({ ...state, userList: users, isModalOpen: false });
        };
        return func();
      case "updateListing":
        console.log("updateListing", payload);
        let updateFunc = async () => {
          setState({ ...state, isFetching: true });
          let updated = await FirebaseService.updateListing(payload);
          setState({ ...state, isFetching: false });
          return setState({ ...state, listings: updated, isModalOpen: false });
        };
        return updateFunc();
      default:
        return state;
    }
  };

  return { state, actions };
};

export default useGlobalState;
