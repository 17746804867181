import React, { useState, useEffect, useContext, useReducer } from "react";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "styled-components";

import { Page, Content, PrimaryButton } from "../../theme//theme.js";
import GlobalContext from "../../store/context";
import TimeSelector from "../ui/TimeSelector";
import Metrices from "../ui/Metrices";
import ReportForm from "../ui/ReportForm";
import FinanceTable from "./FinanceTable";
import moment from "moment";

import {
  Sector,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  BarChart,
  Bar,
  Cell,
  Label,
} from "recharts";

const MenuBar = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.primaryDark};
  box-shadow: ${(props) => props.theme.darkGray} 1px 1px 2px;
  top: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
  z-index: 1001;
  height: 3.5rem;
  &.secondary {
    z-index: 100;
    top: 3.5rem;
    background-color: white;
    background-color: ${(props) => props.theme.white};
  }
  &.short {
    width: calc(100% - 16px);
  }

  @media (max-width: 1024px) {
    position: fixed;
    background-color: ${({ theme }) => theme.primaryDark};
  }
`;

const ListingContent = styled.div`
  padding-top: 14vh;
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;
const ViewContent = styled(Content)`
  padding-top: 7rem;
  overflow-y: auto;
`;

const TableTitle = styled.div`
  width: 90vw;
  text-align: left;
  padding-top: 1.2rem;
  padding-left: 1rem;
  font-size: 1.4rem;
  @media (max-width: 1024px) {
    font-size: 1.4em !important;
  }
`;

const CustomTable = styled(DataTable)`
  & .rdt_TableCell {
    & .partial {
      color: red;
    }
    & .active {
      color: ${({ theme }) => theme.brandColor};
      font-weight: 900;
    }
    & .positive {
      color: ${({ theme }) => theme.posGreen};
      font-weight: 900;
    }
    & .small {
      font-size: 0.6rem;
    }
  }
  & .rdt_TableHeader {
    display: none;
  }

  & .rdt_Table {
    margin: 1rem 0;
    max-height: 70vh;
  }
`;

const calcAvgKPI = function (
  dataArr,
  path,
  isPrev = false,
  isSum = false,
  isRounded = false
) {
  let kpis = [];
  //console.log("DATA BEFORE PARSE ", dataArr, path);
  dataArr.map((el) => {
    let kpiSlot = !isPrev ? el : el.prev;
    kpiSlot = resolvePath(kpiSlot, path, 0);

    kpis.push(kpiSlot);
  });
  //console.log(kpis);
  let kpi = 0;
  if (isSum) {
    kpi = roundToTwo(kpis.reduce((a, b) => a + b, 0));
  } else {
    kpi = roundToTwo(kpis.reduce((a, b) => a + b, 0) / kpis.length);
  }
  if (isRounded) {
    kpi = Math.round(kpi);
  }
  return kpi;
};

const filterDataByStartDate = function (bulk, year, data) {
  // console.log("filterDataByStartDate : ", bulk, year);

  let result = data.filter((listing) => {
    let startDate = moment
      .utc(listing.listingStartDate, "MMM-YYYY")
      .startOf("month");

    if (bulk !== "annual") {
      let reportDateFilter = moment
        .utc(bulk + "-" + year, "MMM-YYYY")
        .startOf("month");
      return reportDateFilter.diff(startDate, "months") >= 0;
    } else {
      let reportDateFilter = moment
        .utc("Jan-" + year, "MMM-YYYY")
        .startOf("month");
      return reportDateFilter.diff(startDate, "years") >= 0;
    }
  });

  //console.log("filterDataByStartDate : \n", result);
  return result;
};

const filterDataByListing = function (
  complete_data,
  bulk,
  listing = undefined
)

{
  //let data = [...complete_data];
  let data = complete_data;
  console.log(data);
  console.log(complete_data); 
  if (listing !== undefined) {
    data = data.filter((el) =>el.id === listing);
    console.log("data from listinrg!==undifiend" , data);
  }
  console.log("filter listing data after" , data);
  console.log("data length:" , data.length);
  //console.log("listing within filterdatabylisting" + listing);
  let res = {};
  res.income_avg = calcAvgKPI(data, "months." + bulk + ".income.amount");
  res.expenses_avg = calcAvgKPI(data, "months." + bulk + ".expenses.amount");

  res.adr = calcAvgKPI(data, "months." + bulk + ".adr");
  res.adr_prev = calcAvgKPI(data, "months." + bulk + ".adr", true);
  res.adr_comp = calcLastYearDiff(res.adr, res.adr_prev);

  res.avgnts = calcAvgKPI(data, "months." + bulk + ".avgnts");
  res.avgnts_prev = calcAvgKPI(data, "months." + bulk + ".avgnts", true);
  res.avgnts_comp = calcLastYearDiff(res.avgnts, res.avgnts_prev);

  res.dayAdv = calcAvgKPI(data, "months." + bulk + ".dayAdv");
  res.dayAdv_prev = calcAvgKPI(data, "months." + bulk + ".dayAdv", true);
  res.dayAdv_comp = calcLastYearDiff(res.dayAdv, res.dayAdv_prev);

  res.occupancy = calcAvgKPI(data, "months." + bulk + ".occupanceRate");
  res.occupancy_prev = calcAvgKPI(
    data,
    "months." + bulk + ".occupanceRate",
    true
  );
  res.occupancy_comp = calcLastYearDiff(res.occupancy, res.occupancy_prev);

  res.income_total = calcAvgKPI(
    data,
    "months." + bulk + ".income.amount",
    false,
    true,
    true
  );
  res.income_total_prev = calcAvgKPI(
    data,
    "months." + bulk + ".income.amount",
    true,
    true
  );
  res.income_total_comp = calcLastYearDiff(
    res.income_total,
    res.income_total_prev
  );

  res.income_avg = calcAvgKPI(
    data,
    "months." + bulk + ".income.amount",
    false,
    false,
    true
  );
  res.income_avg_prev = calcAvgKPI(
    data,
    "months." + bulk + ".income.amount",
    true,
    false,
    true
  );
  res.income_avg_comp = calcLastYearDiff(res.income_avg, res.income_avg_prev);

  res.expenses_total = calcAvgKPI(
    data,
    "months." + bulk + ".expenses.amount",
    false,
    true,
    true
  );

  res.expenses_total_prev = calcAvgKPI(
    data,
    "months." + bulk + ".expenses.amount",
    true,
    true,
    true
  );
  res.expenses_total_comp = calcLastYearDiff(
    res.expenses_total,
    res.expenses_total_prev
  );

  res.expenses_avg = calcAvgKPI(
    data,
    "months." + bulk + ".expenses.amount",
    false,
    false,
    true
  );

  res.expenses_avg_prev = calcAvgKPI(
    data,
    "months." + bulk + ".expenses.amount",
    true,
    false,
    true
  );
  res.expenses_avg_comp = calcLastYearDiff(
    res.expenses_avg,
    res.expenses_avgl_prev
  );

  res.net_total = roundToTwo(res.income_total - res.expenses_total);

  res.net_total_prev = roundToTwo(
    res.income_total_prev - res.expenses_total_prev
  );

  res.net_total_comp = calcLastYearDiff(res.net_total, res.net_total_prev);

  res.net_avg = roundToTwo(res.income_avg - res.expenses_avg);
  res.net_avg_prev = roundToTwo(res.income_avg_prev - res.expenses_avg_prev);
  res.net_total_comp = calcLastYearDiff(res.net_total, res.net_total_prev);

  res.balance_total = roundToTwo(
    res.income_total -
      res.expenses_total -
      calcAvgKPI(data, "payouts." + bulk + ".amount", false, true, true)
  );
  res.balance_total_prev = roundToTwo(
    res.income_total_prev -
      res.expenses_total_prev -
      calcAvgKPI(data, "payouts." + bulk + ".amount", true, true, true)
  );

  res.balance_tota_comp = calcLastYearDiff(
    res.balance_total,
    res.balance_total_prev
  );

  res.balance_avg = roundToTwo(
    res.income_avg -
      res.expenses_avg -
      calcAvgKPI(data, "payouts." + bulk + ".amount", false, false, true)
  );
  res.balance_avg_prev = roundToTwo(
    res.income_avg_prev -
      res.expenses_avg_prev -
      calcAvgKPI(data, "payouts." + bulk + ".amount", true, false, true)
  );

  res.balance_avg_comp = calcLastYearDiff(
    res.balance_avg,
    res.balance_avg_prev
  );
  // if(data.length >0){
  //   if(data[0].expenses[bulk]!= undefined &&data[0].expenses[bulk].distribution['Management fee'] != undefined){
      
  //     data[0].months[bulk].expenses.distribution['Management fee'] += data[0].expenses[bulk].distribution['Management fee'].amount;
  //   }    
  // }
  
  res.management_total = calcAvgKPI(
    data,
    "months." + bulk + ".expenses.distribution.Management fee",
    false,
    true,
    true
  );

  res.management_total_prev = calcAvgKPI(
    data,
    "months." + bulk + ".expenses.distribution.Management fee",
    true,
    true,
    true
  );
  res.management_total_comp = calcLastYearDiff(
    res.management_total,
    res.management_total_prev
  );

  res.management_avg = calcAvgKPI(
    data,
    "months." + bulk + ".expenses.distribution.Management fee",
    false,
    false,
    true
  );

  res.management_avg_prev = calcAvgKPI(
    data,
    "months." + bulk + ".expenses.distribution.Management fee",
    true,
    false,
    true
  );
  res.management_avg_comp = calcLastYearDiff(
    res.management_avg,
    res.management_avg_prev
  );

  res.profit_total = roundToTwo(res.net_total / res.income_total);
  res.profit_avg = roundToTwo(res.net_avg / res.income_avg);
  console.log("res from build res" , res);
  return res;
};


const buildListingTableData = function (bulk, data) {
  let tableData = [];
  console.log(bulk);
  console.log(" PERFORMACE DATA :", data);
  
  data.map((el) => {
    //TODO Fix the identifier for listing - see if id can be used
    /////////////////
    /////////////////// ------------------------------------------------------------------
    let res = filterDataByListing(data, bulk, el.id);
    console.log("res from filter data by listing" , res);
      let listingObj = {
      title: el.nickname,
      adr: res.adr,
      adr_comp: res.adr_comp,
      avgnts: res.avgnts,
      avgnts_comp: res.avgnts_comp,
      dayAdv: res.dayAdv,
      occupancy: res.occupancy,
      occupancy_comp: res.occupancy_comp,
      income: res.income_total,
      income_comp: res.income_total_comp,
      expenses: res.expenses_total,
      expenses_comp: res.expenses_total_comp,
      management: res.management_total,
      management_comp: res.management_total_comp,
      net: res.net_total,
      net_comp: res.net_total_comp,
      balance: res.balance_total,
      profit: res.profit_total,
    };
    console.log("listinrgObj:" , listingObj);
    tableData.push(listingObj);
    console.log("table data:" , tableData);
  });

  let res = filterDataByListing(data, bulk);
  console.log("filter databy listing after" , res);
  let totals = {
    title: "Total",
    adr: "-",
    avgnts: "-",
    dayAdv: "-",
    occupancy: "-",
    income: res.income_total,
    income_comp: res.income_total_comp,
    expenses: res.expenses_total,
    expenses_comp: res.expenses_total_comp,
    management: res.management_total,
    management_comp: res.management_total_comp,
    net: res.net_total,
    net_comp: res.net_total_comp,
    balance: res.balance_total,
    profit: res.profit_total,
  };

  let avgs = {
    title: "Average",
    adr: res.adr,
    adr_comp: res.adr_comp,
    avgnts: res.avgnts,
    avgnts_comp: res.avgnts_comp,
    dayAdv: res.dayAdv,
    occupancy: res.occupancy,
    occupancy_comp: res.occupancy_comp,
    income: res.income_avg,
    income_comp: res.income_avg_comp,
    expenses: res.expenses_avg,
    expenses_comp: res.expenses_avg_comp,
    management: res.management_avg,
    management_comp: res.management_avg_comp,
    net: res.net_avg,
    net_comp: res.net_avg_comp,
    balance: res.balance_avg,
    profit: res.profit_avg,
  };
  //console.log("TOTALS : ", totals);
  //console.log("AVGS : ", avgs);
  tableData.push(avgs);
  tableData.push(totals);

  return tableData;
};


const getTotalTableColumns = function () {
  return [
    {
      name: "Listing",
      selector: "title",
      width: "120px",
    },
    {
      name: "ADR",
      selector: "adr",
      width: "70px",
      compact: true,
      center: true,
      sortable: true,
    },
    {
      name: "-",
      selector: "adr_comp",
      width: "30px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => {
        if (row.adr_comp > 0) {
          return <span className="small positive">{row.adr_comp}%</span>;
        } else if (row.adr_comp < 0) {
          return <span className="small partial">{row.adr_comp}%</span>;
        }
      },
    },
    {
      name: "Avg. Nights",
      selector: "avgnts",
      width: "70px",
      compact: true,
      center: true,
      sortable: true,
    },
    {
      name: "-",
      selector: "avgnts_comp",
      width: "30px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => {
        if (row.avgnts_comp > 0) {
          return <span className="small positive">{row.avgnts_comp}%</span>;
        } else if (row.avgnts_comp < 0) {
          return <span className="small partial">{row.avgnts_comp}%</span>;
        }
      },
    },
    {
      name: "Booked Before",
      selector: "dayAdv",
      width: "70px",
      compact: true,
      center: true,
      sortable: true,
    },
    {
      name: "Occupancy",
      selector: "occupancy",
      width: "70px",
      compact: true,
      center: true,
      sortable: true,
    },
    {
      name: "-",
      selector: "occupancy_comp",
      width: "30px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => {
        if (row.occupancy_comp > 0) {
          return <span className="small positive">{row.occupancy_comp}%</span>;
        } else if (row.occupancy_comp < 0) {
          return <span className="small partial">{row.occupancy_comp}%</span>;
        }
      },
    },

    {
      name: "Management",
      selector: "management",
      width: "80px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => numberWithCommas(row.management),
    },
    {
      name: "-",
      selector: "management_comp",
      width: "30px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => {
        if (row.management_comp > 0) {
          return <span className="small positive">{row.management_comp}%</span>;
        } else if (row.management_comp < 0) {
          return <span className="small partial">{row.management_comp}%</span>;
        }
      },
    },
    {
      name: "Income",
      selector: "income",
      width: "90px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => numberWithCommas(row.income),
    },
    {
      name: "-",
      selector: "income_comp",
      width: "30px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => {
        if (row.income_comp > 0) {
          return <span className="small positive">{row.income_comp}%</span>;
        } else if (row.income_comp < 0) {
          return <span className="small partial">{row.income_comp}%</span>;
        }
      },
    },
    {
      name: "Expenses",
      selector: "expenses",
      width: "90px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => numberWithCommas(row.expenses),
    },
    {
      name: "-",
      selector: "expenses_comp",
      width: "30px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => {
        if (row.expenses_comp > 0) {
          return <span className="small positive">{row.expenses_comp}%</span>;
        } else if (row.expenses_comp < 0) {
          return <span className="small partial">{row.expenses_comp}%</span>;
        }
      },
    },

    {
      name: "Net",
      selector: "net",
      width: "80px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => numberWithCommas(row.net),
    },
    {
      name: "-",
      selector: "net_comp",
      width: "30px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => {
        if (row.net_comp > 0) {
          return <span className="small positive">{row.net_comp}%</span>;
        } else if (row.net_comp < 0) {
          return <span className="small partial">{row.net_comp}%</span>;
        }
      },
    },
    {
      name: "Balance",
      selector: "balance",
      width: "80px",
      compact: true,
      center: true,
      sortable: true,
      format: (row) => numberWithCommas(row.balance),
    },
    {
      name: "Profit",
      selector: "profit",
      width: "45px",
      compact: true,
      center: true,
      sortable: true,
    },
  ];
};

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#14213d",
  "#606c38",
  "#dc2f02",
];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const buildFilteredListsByBulk = function (data, year) {
  let data_copy = [...data];
  let res = {};
  res[year] = {};
  res[year - 1] = {};

  months.forEach((month) => {
    let monthLists = filterDataByStartDate(month, year, data_copy);
    let prev_monthLists = filterDataByStartDate(month, year - 1, data_copy);
    res[year][month] = monthLists;
    res[year - 1][month] = prev_monthLists;
  });
  res[year]["annual"] = filterDataByStartDate("annual", year, data_copy);
  res[year - 1]["annual"] = filterDataByStartDate(
    "annual",
    year - 1,
    data_copy
  );
  //console.log("buildFilteredListsByBulk ", res);
  return res;
};

const createKPIGraphArray = function (
  year,
  dataObj,
  kpi,
  isSum = false,
  isRound = false
) {
  let graphData = [];
  months.forEach((month) => {
    let monthLists =
      dataObj[year] && dataObj[year][month] ? dataObj[year][month] : [];
    let prev_monthLists =
      dataObj[year - 1] && dataObj[year - 1][month]
        ? dataObj[year - 1][month]
        : [];
    let val = calcAvgKPI(
      monthLists,
      "months." + month + "." + kpi,
      false,
      isSum,
      isRound
    );

    let val_prev = calcAvgKPI(
      prev_monthLists,
      "months." + month + "." + kpi,
      true,
      isSum,
      isRound
    );
    //console.log("MONTH : ", month, val);
    graphData.push({
      name: month,
      current: roundToTwo(val),
      prev: roundToTwo(val_prev),
    });
  });
  //console.log("GRAPH  : ", graphData);
  return graphData;
};

const multiEntryAccum = function (data, path) {
  // let graphData = [];
  let aggregatedChannelNum = {};
  data.forEach((e) => {
    let el = resolvePath(e, path);
    console.log("ellll", el);
    Object.keys(el).forEach(item =>{
      console.log("itemm", item);
      if(item.toString().includes('.com')){
        if(item.toString().includes('@')){
          let temp = item.split('@')[1];
          temp = temp.split('.')[0];
          console.log(temp);
          el[temp] =  el[item];
          delete el[item];
        }
        else{
          let temp = item.split('.')[0];
          el[temp] =  el[item];
          delete el[item];
        }
      }
    })



    aggregateObjects(aggregatedChannelNum, el);
    
  });
  //Object.keys(aggregatedChannelNum).forEach((el) => {
  //graphData.push({ name: el, value: aggregatedChannelNum[el] });
  //});
  //return graphData;
  return aggregatedChannelNum;
};

const getListingTotalChannelCancelation = function (bulk, data) {
  let channeList = getChannelList(data);
  let res = {};
  channeList.map((channel) => {
    res[channel] = { res: 0, amount: 0 };
  });
  // console.log("######   >>", res);
  data.map((listing) => {
    let cancelationObj = listing.canceled[bulk];
    if (cancelationObj !== undefined) {
      let cancelationArr = Object.values(cancelationObj);
      cancelationArr.map((order) => {
        let channel = order.source;
        console.log(channel);
        if (channel !== undefined && res[channel] !== undefined) {
          console.log(res[channel].res);
          res[channel].res += 1;
          console.log(order.finance.income);
          let tempAmount = roundToTwo(order.finance.income);
          console.log(tempAmount);
          console.log("intparse" , parseInt(tempAmount));
          res[channel].amount +=parseInt(tempAmount) || 0;
        }
      });
    }
  });
  Object.keys(res).forEach(rs=>{
    console.log(rs);
  })
  console.log("canceld finance" , res);
  return res;
};

const get_data_channels_perform = function (bulk, data) {
  // console.log("CHANNEL DATA : ", data);
  let graphData = [];
  let channelNum = multiEntryAccum(data, "months." + bulk + ".resSource");
  let channelAmount = multiEntryAccum(
    data,
    "months." + bulk + ".income.distribution"
  );
  let prev_channelNum = multiEntryAccum(
    data,
    "prev.months." + bulk + ".resSource"
  );
  let prev_channelAmount = multiEntryAccum(
    data,
    "prev.months." + bulk + ".income.distribution"
  );
  let canceledObj = getListingTotalChannelCancelation(bulk, data);

  Object.keys(channelNum).map((el) => {
    let a = {};
    a["channel"] = el;
    a["num"] = channelNum[el];
    a["amount"] = Math.round(channelAmount[el]);
    a["prev_num"] = prev_channelNum[el];
    a["prev_amount"] = prev_channelAmount[el];
    a["num_comp"] = calcLastYearDiff(channelNum[el], prev_channelNum[el]);
    a["amount_comp"] = calcLastYearDiff(
      channelAmount[el],
      prev_channelAmount[el]
    );

    a["cancel_num"] = canceledObj[el].res;
    a["cancel_amount"] = canceledObj[el].amount;
    // console.log("CHANNEL OBJ", a);
    graphData.push(a);
  });

  return graphData;
};


// const SetDataIndexer = function(data){
//   retrun data[0];
// }

const createChannelGraphArray = function (dataObj, path, year) {
  let graphData = [];
  //console.log(getChannelList(data));
  months.forEach((month) => {
    let data =
      dataObj[year] && dataObj[year][month] ? dataObj[year][month] : [];
    let channelAmount = multiEntryAccum(data, "months." + month + path);
    let a = {};
    Object.keys(channelAmount).forEach((el) => {
      a.name = month;
      a[el] = channelAmount[el] || 0;
    });
    graphData.push(a);
  });
  //console.log(graphData);
  return graphData;
};

const get_columns_channels_perform = function () {
  return [
    {
      name: "Channel",
      selector: "channel",
      sortable: true,
      width: "160px",
    },

    {
      name: "Amount",
      selector: "amount",
      sortable: true,
      width: "100px",
      compact: true,
      center: true,
      format: (row) => numberWithCommas(row.amount),
    },
    {
      name: "-",
      selector: "amount_comp",
      sortable: true,
      width: "50px",
      compact: true,
      center: true,
      format: (row) => {
        let a = row.amount_comp;

        if (a > 0) {
          return <span className="small positive">{a}%</span>;
        } else if (a < 0) {
          return <span className="small partial">{a}%</span>;
        }
      },
    },
    {
      name: "(#)",
      selector: "num",
      sortable: true,
      width: "80px",
      compact: true,
      center: true,
    },
    {
      name: "-",
      selector: "num_comp",
      sortable: true,
      width: "50px",
      compact: true,
      center: true,
      format: (row) => {
        let a = row.num_comp;

        if (a > 0) {
          return <span className="small positive">{a}%</span>;
        } else if (a < 0) {
          return <span className="small partial">{a}%</span>;
        }
      },
    },
    {
      name: "Canceled",
      selector: "cancel_amount",
      sortable: true,
      width: "100px",
      compact: true,
      center: true,
      format: (row) => numberWithCommas(row.cancel_amount),
    },
    {
      name: "(#)",
      selector: "cancel_num",
      sortable: true,
      width: "100px",
      compact: true,
      center: true,
    },
  ];
};

const calcProfitByMonth = function (year, dataObj) {
  let graphData = [];
  let income = createKPIGraphArray(
    year,
    dataObj,
    "income.amount",
    true,
    false,
    true
  );
  let expenses = createKPIGraphArray(
    year,
    dataObj,
    "expenses.amount",
    true,
    false,
    true
  );
  income.map((el, index) => {
    graphData.push({
      name: el.name,
      current: roundToTwo((el.current - expenses[index].current) / el.current),
      prev: roundToTwo((el.prev - expenses[index].prev) / el.prev),
    });
  });
  return graphData;
};

const getChannelList = function (data) {
  let channels = {};
  data.forEach((el) => {
    let list = Object.keys(el.months.annual.resSource);
    list.forEach((source) => {
      channels[source] = 1;
    });
  });
  return Object.keys(channels);
};

const get_data_channels = function (bulk, data) {
  let channels = getChannelList(data);
  let graphData = [];

  data.map((listing) => {
    let listingObj = {
      title: listing.title,
    };
    channels.map((channelName) => {
      listingObj[channelName] = {
        res: listing.months[bulk].resSource[channelName] || 0,
        amount: Math.round(
          listing.months[bulk].income.distribution[channelName] !== undefined
            ? listing.months[bulk].income.distribution[channelName]
            : 0
        ),
      };
    });
    graphData.push(listingObj);
  });
  return graphData;
};

const get_columns_channels = function (bulk, data) {
  let channels = getChannelList(data);
  let columns = [];
  columns.push({
    name: "Listing",
    selector: "title",
    width: "160px",
    sortable: true,
  });
  channels.map((ch) => {
    columns.push({
      name: ch,
      sortable: true,
      selector: ch + ".amount",
      width: "70px",
      compact: true,
      center: true,
      format: (row) => {
        return numberWithCommas(row[ch].amount);
      },
    });

    columns.push({
      name: "(#)",
      sortable: true,
      selector: ch + ".res",
      width: "45px",
      compact: true,
      center: true,
    });
  });
  return columns;
};
///////////////////////////////////////////////////////////////////////////

//////REPORT COMPONENT/////////////////////
///////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////

const ReportComponent = (props) => {
  const context = useContext(GlobalContext);

  const [time, setTime] = useState(moment());

  const [initData, setInitData] = useState([]);

  const [data, setData] = useState([]);
  
  const [monthlyListingObject, set_monthlyListingObject] = useState([]);

  const [period, setPeriod] = useState(true);

  const [bulk, setBulk] = useState(period ? "annual" : time.format("MMM"));

  const onPeriodChange = async function (val) {
    setPeriod(val);
    let blk = val ? "annual" : time.format("MMM");
    setBulk(blk);
    let data_filtered = filterDataByStartDate(blk, time.year(), initData);
    setData(data_filtered);
  };

  const listingTableOnSort = (rows, field, direction) => {
    let arr = [...rows].slice(0, rows.length - 2);
    let totals = [...rows].slice(rows.length - 2, rows.length);
    const sortAsc = (a, b) => a[field] - b[field];
    const sortDesc = (a, b) => b[field] - a[field];

    arr = arr.sort(direction === "asc" ? sortAsc : sortDesc);
    // console.log("SORT  : " + rows, arr);
    arr = [...arr, ...totals];
    return arr;
  };

  const incldeAll = function (val) {
    return props.list
      .filter((el) => el.data.externalData.active)
      .reduce((accum, el) => {
        accum[el.listingId] = val;
        return accum;
      }, {});
  };

  const pageTime = function (dir) {
    let newT = time.clone();
    !period ? newT.add(dir, "months") : newT.add(dir, "years");
    if (newT.year() === time.year()) {
      setTime(newT);
      let blk = period ? "annual" : newT.format("MMM");
      setBulk(blk);
      let data_filtered = monthlyListingObject[newT.year()][blk];
      setData(data_filtered);
      //console.log("Filtered By date : ", data_filtered);
    }
  };

  const changeYear = function (dir) {
    let newT = time.clone();
    newT.add(dir, "years");
    setTime(newT);
  };
  const [included, setIncluded] = useState(incldeAll(true));
  const onDataLoad =async function (init_data) {
    //console.log("DATA PREPARED : ", data);
    let prepared =await prepareData(init_data);
    console.log("INIT:::::: ", init_data);
    console.log("PREP:::::: ", prepared);
    setInitData(init_data);
    set_monthlyListingObject(buildFilteredListsByBulk(init_data, time.year()));
    setData(init_data);
  };
  // add incomes and expenses into months object both current and prev
  const prepareData =async function (data) {
    let temp_data = [...data];
    //console.log("PREPARE DATA :", temp_data);
    temp_data = filterDataByStartDate("annual", time.year(), temp_data);

    temp_data.forEach((el) => {
      let el_prev = el.prev;

      let exp = Object.keys(el.expenses);
      let inc = Object.keys(el.income);

      let exp_prev = Object.keys(el_prev.expenses);
      let inc_prev = Object.keys(el_prev.income);

      exp.forEach(
        (month) =>
          (el.months[month].expenses.amount = roundToTwo(
            el.months[month].expenses.amount + el.expenses[month].amount
          ))
      );
      exp_prev.forEach(
        (month) =>
          (el_prev.months[month].expenses.amount = roundToTwo(
            el_prev.months[month].expenses.amount +
              el_prev.expenses[month].amount
          ))
      );
      inc.forEach(
        (month) =>
          (el.months[month].income.amount = roundToTwo(
            el.months[month].income.amount + el.income[month].amount
          ))
      );
      inc_prev.forEach(
        (month) =>
          (el_prev.months[month].income.amount = roundToTwo(
            el_prev.months[month].income.amount + el_prev.income[month].amount
          ))
      );
      let months = Object.keys(el.months);
      months.forEach((month) => {
        el.months[month].net = roundToTwo(
          el.months[month].income.amount - el.months[month].expenses.amount
        );
      });

      let months_prev = Object.keys(el_prev.months);
      months_prev.forEach((month) => {
        el_prev.months[month].net = roundToTwo(
          el_prev.months[month].income.amount -
            el_prev.months[month].expenses.amount
        );
      });
    });
    return temp_data;
  };

  //console.log("LISTING STATE ", included);

  return (
    <Page>
      <div className="top-menu-bar">
        <h1>Portfolio Summary</h1>
      </div>

      {initData.length == 0 && (
        <ListingContent>
          <div>Please select a timeframe for the report:</div>
          <TimeSelector
            time={time}
            onTimeChange={changeYear}
            onPeriodChange={() => null}
            period={true}
            mode="monthOnly"
          ></TimeSelector>
          <PrimaryButton
            onClick={() => {
              let a = window.confirm(
                "You are about to generate a report for " +
                  Object.values(included).filter((e) => e === true).length +
                  " listing items.\n Would you like to proceed?"
              );
              if (a) {
                context.actions({
                  type: "openModal",
                  payload: {
                    modalComponent: (
                      <ReportForm
                        list={Object.keys(included).filter(
                          (el) => included[el]
                        )}
                        time={time}
                        onLoad={onDataLoad}
                        
                        onPeriodChange={() => onPeriodChange(!period)}
                      ></ReportForm>
                    ),
                  },
                });
              }
            }}
          >
            Generate Report
          </PrimaryButton>
        </ListingContent>
      )}
      {initData.length !== 0 && (
        <Content>
          <MenuBar className="secondary short">
            <TimeSelector
              time={time}
              onTimeChange={pageTime}
              onPeriodChange={() => onPeriodChange(!period)}
              period={period}
            ></TimeSelector>
          </MenuBar>
          <Metrices
            adr={calcAvgKPI(data, "months." + bulk + ".adr")}
            adrPrev={calcAvgKPI(
              filterDataByStartDate(bulk, time.year() - 1, data),
              "months." + bulk + ".adr",
              true
            )}
            avgnts={calcAvgKPI(data, "months." + bulk + ".avgnts")}
            avgntsPrev={calcAvgKPI(
              filterDataByStartDate(bulk, time.year() - 1, data),
              "months." + bulk + ".avgnts",
              true
            )}
            dayAdv={calcAvgKPI(data, "months." + bulk + ".dayAdv")}
            dayAdvPrev={calcAvgKPI(
              filterDataByStartDate(bulk, time.year() - 1, data),
              "months." + bulk + ".dayAdv",
              true
            )}
            occupanceRate={calcAvgKPI(
              data,
              "months." + bulk + ".occupanceRate"
            )}
            occupanceRatePrev={calcAvgKPI(
              filterDataByStartDate(bulk, time.year() - 1, data),
              "months." + bulk + ".occupanceRate",
              true
            )}
            expenses={calcAvgKPI(
              data,
              "months." + bulk + ".expenses.amount",
              false,
              true
            )}
            expensesPrev={calcAvgKPI(
              data,
              "months." + bulk + ".expenses.amount",
              true,
              true
            )}
            income={calcAvgKPI(
              data,
              "months." + bulk + ".income.amount",
              false,
              true
            )}
            incomePrev={calcAvgKPI(
              data,
              "months." + bulk + ".income.amount",
              true,
              true
            )}
            management={calcAvgKPI(
              data,
              "months." + bulk + ".expenses.distribution.Management fee",
              false,
              true
            )}
            managementPrev={calcAvgKPI(
              data,
              "months." + bulk + ".expenses.distribution.Management fee",
              true,
              true
            )}
          ></Metrices>
          <TableTitle>Listing Performance</TableTitle>
          <CustomTable
            
            data= {buildListingTableData(bulk,data)}
            
            title=""
            columns={getTotalTableColumns()}
            paginationPerPage={data.length}
            sortFunction={(rows, field, direction) =>
              listingTableOnSort(rows, field, direction)
            }
            noPagination={true}
            fixedHeader={true}
          ></CustomTable>

          <fieldset>
            <legend>Average Daily Rates</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={createKPIGraphArray(
                        time.year(),
                        monthlyListingObject,
                        "adr"
                      )}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis type="number" domain={[0, "dataMax"]} />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="current" fill="#8884d8" />
                      <Bar dataKey="prev" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Booked Before</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={createKPIGraphArray(
                        time.year(),
                        monthlyListingObject,
                        "dayAdv"
                      )}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="current" fill="#8884d8" />
                      <Bar dataKey="prev" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Occupancy Rate</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={createKPIGraphArray(
                        time.year(),
                        monthlyListingObject,
                        "occupanceRate"
                      )}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="current" fill="#8884d8" />
                      <Bar dataKey="prev" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Management</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={createKPIGraphArray(
                        time.year(),
                        monthlyListingObject,
                        "expenses.distribution.Management fee",
                        true,
                        true
                      )}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="current" fill="#8884d8" />
                      <Bar dataKey="prev" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Income</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={createKPIGraphArray(
                        time.year(),
                        monthlyListingObject,
                        "income.amount",
                        true,
                        true
                      )}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="current" fill="#8884d8" />
                      <Bar dataKey="prev" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Profit</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={calcProfitByMonth(
                        time.year(),
                        monthlyListingObject
                      )}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      <Bar dataKey="current" fill="#8884d8" />
                      <Bar dataKey="prev" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>

          <TableTitle>Channel Performance</TableTitle>
          <FinanceTable
            data={get_data_channels_perform(bulk, data)}
            title=""
            columns={get_columns_channels_perform()}
            paginationPerPage={50}
            noPagination={true}
          ></FinanceTable>

          <TableTitle>Channel Performance By listing</TableTitle>

          <FinanceTable
            data={get_data_channels(bulk, data)}
            title=""
            columns={get_columns_channels(bulk, data)}
            paginationPerPage={50}
            noPagination={true}
          ></FinanceTable>

          <fieldset>
            <legend>Channel Income By Month</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={createChannelGraphArray(
                        monthlyListingObject,
                        ".income.distribution",
                        time.year()
                      )}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis type="number" domain={[0, "dataMax"]} />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      {getChannelList(data).map((el, index) => {
                        //console.log(el);
                        return (
                          <Bar
                            dataKey={el}
                            key={el}
                            fill={COLORS[index % COLORS.length]}
                          />
                        );
                      })}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>Channel Reservations By Month</legend>
            <div className="widget-container">
              <div className="chart-widget ">
                <div style={{ width: "99%", height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart
                      data={createChannelGraphArray(
                        monthlyListingObject,
                        ".resSource",
                        time.year()
                      )}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name"></XAxis>

                      <YAxis type="number" domain={[0, "dataMax"]} />
                      <Tooltip />
                      <Legend verticalAlign="top" align="left" height={36} />
                      {getChannelList(data).map((el, index) => {
                        //console.log(el);
                        return (
                          <Bar
                            dataKey={el}
                            key={el}
                            fill={COLORS[index % COLORS.length]}
                          />
                        );
                      })}
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </fieldset>
        </Content>
      )}
    </Page>
  );
};

const roundToTwo = function (num) {
  return Number(Math.round(num + "e+2") + "e-2");
};
const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

function aggregateObjects(obj1, obj2) {
  Object.keys(obj2).forEach((e) => {
    if (obj1[e] === undefined) {
      obj1[e] = obj2[e];
      obj1[e] = roundToTwo(obj1[e]);
    } else {
      obj1[e] += obj2[e];
      obj1[e] = roundToTwo(obj1[e]);
    }
  });
}
const calcLastYearDiff = function (a, b) {
  if (b === 0 || a === 0) {
    return 0;
  } else {
    return Math.round((a / b - 1) * 100);
  }
};

const numberWithCommas = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default ReportComponent;

/*

 <FinanceTable
              data={buildTotalTableData(bulk, data)}
              title=""
              dense={true}
              columns={getTotalTableColumns()}
              noPagination={true}
              className="dense"
            ></FinanceTable>
*/
