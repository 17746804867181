import React, { useState, useEffect, useContext } from "react";
import { Page, Content, Input, Form, PrimaryButton } from "../../theme/theme";
import styled from "styled-components";
import TimeSelector from "../ui/TimeSelector";
import moment from "moment";
import FirebaseService from "../../services/FirebaseService";
import Context from "../../store/context";
import ListConfigForm from "../Listings/ListingConfigForm";
import ListingSetDateForm from "../Listings/ListingSetDateForm";

const ChannelCard = styled.fieldset`
  width: flex 1;
  max-width: 300px;
  border: 1px ${(props) => props.theme.darkGray} solid;
  border-radius: 4px;
`;
const Channels = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
`;
const ChannelForm = styled(Form)`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  justify-content: space-around;
  padding: 0;
  border: none;
  margin: 0;
`;
const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.negRed};
  align-self: flex-end;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: -50px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default function ConfigEdit(props) {
  const context = useContext(Context);

  const [currentTime, setTime] = useState(moment());
  const [settings, setSettings] = useState(null);
  const [settingList, setSettingList] = useState([]);
  const [settingIndex, setIndext] = useState(0);
  let id = props.id;
  const [activityStart, setActivityStart] = useState(props.activityStart);

  const onTimeChange = function (val) {
    let newIndex = settingIndex + val * -1;
    console.log("LIST : ", settingList, settingList.length);
    if (newIndex >= 0 && newIndex < settingList.length) {
      console.log("NEW INDEX : " + newIndex);
      setSettings(settingList[newIndex]);
      setIndext(newIndex);
    }
  };

  const deleteConfig = async (listingId, configId) => {
    context.actions({
      type: "setState",
      payload: { ...context.state, isFetching: true },
    });
    try {
      await FirebaseService.deleteListingConfig(listingId, configId);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      setTime(moment());
    } catch (e) {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    }
  };

  useEffect(() => {
    const loadList = async function () {
      console.log("Loading Settings");
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });
      try {
        let list = await FirebaseService.getListingConfig(id);
        console.log("list", list);
        setSettingList(list);
        setSettings(list[settingIndex]);
        console.log("list", list);
        console.log("setting", settings);
        context.actions({
          type: "setState",
          payload: { ...context.state, isFetching: false },
        });
        if (list.length === 0) {
          throw new Error("Configuation could not be found!!");
        }
      } catch (e) {
        console.log(e);
        context.actions({
          type: "setState",
          payload: { ...context.state, isError: true, errorMsg: e.message },
        });
      }
    };

    loadList();
  }, [currentTime]);
  try {
    return (
      <Page>
        <Content>
          <fieldset>
            <legend>Listing Finance Configuration </legend>
            <ButtonContainer>
              <PrimaryButton
                onClick={() => {
                  console.log("PRIMARY BUTTON WAS CLICKED");
                  context.actions({
                    type: "openModal",
                    payload: {
                      modalComponent: (
                        <ListConfigForm
                          title="New Configuration"
                          listingId={id}
                          refresh={() => setTime(moment())}
                        ></ListConfigForm>
                      ),
                    },
                  });
                }}
              >
                Create New
              </PrimaryButton>
            </ButtonContainer>
            <Channels>
              <ChannelCard>
                <TimeSelector
                  time={
                    settings === null
                      ? moment()
                      : moment(
                          settings.data.activationYear +
                            "-" +
                            settings.data.activationMonth,
                          "YYYY-MM"
                        )
                  }
                  mode="monthOnly"
                  onTimeChange={onTimeChange}
                ></TimeSelector>
                <legend></legend>
                <ChannelForm>
                  <label>City Tax:</label>
                  <Input
                    type="number"
                    value={
                      settings === null ? 0 : settings.data.settings.cityTax
                    }
                    readOnly
                  ></Input>
                  <label>State Tax:</label>
                  <Input
                    type="number"
                    value={
                      settings === null ? 0 : settings.data.settings.stateTax
                    }
                    readOnly
                  ></Input>

                  <label>Management Fee:</label>
                  <Input
                    type="number"
                    value={
                      settings === null
                        ? 0
                        : settings.data.settings.managementFee
                    }
                    readOnly
                  ></Input>
                  <label>Actual Cleaning Cost:</label>
                  <Input
                    type="number"
                    value={
                      settings === null
                        ? 0
                        : settings.data.settings.ListingActualCleaninCost
                    }
                    readOnly
                  ></Input>
                  <label>Avalara Property ID</label>
                  <Input
                    type="number"
                    value={
                      settings.data.settings.AvalaraId === null
                        ? 0
                        : settings.data.settings.AvalaraId
                    }
                    readOnly
                  ></Input>
                  <label>Ownership</label>
                  <Input
                    type="string"
                    value={
                      settings === null
                        ? "N/A"
                        : settings.data.settings.Ownership
                    }
                    readOnly
                  ></Input>
                   <label>State Filling Field</label>
                  <Input
                    type="string"
                    value={
                      settings === null
                        ? 0
                        :GetFillingFieldNameByNumber(settings.data.settings.StateFillingField) 
                    }
                    readOnly
                  ></Input>
                  <label>City Filling Field</label>
                  <Input
                    type="string"
                    value={
                      settings === null
                        ? "N/A"
                        :GetFillingFieldNameByNumber(settings.data.settings.CityFillingField) 
                    }
                    readOnly
                  ></Input>
                  <label>County Filling Field</label>
                  <Input
                    type="string"
                    value={
                      settings === null
                        ? "N/A"
                        : GetFillingFieldNameByNumber(settings.data.settings.CountyFillingField)
                    }
                    readOnly
                  ></Input>
                  
                  <ButtonsContainer>
                    <PrimaryButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        console.log("UPDATE BUTTON WAS CLICKED");
                        context.actions({
                          type: "openModal",
                          payload: {
                            modalComponent: (
                              <ListConfigForm
                                title="Update Configuration"
                                listingId={id}
                                configId={settings.id}
                                refresh={() => setTime(moment())}
                                init={settings.data.settings}
                              ></ListConfigForm>
                            ),
                          },
                        });
                      }}
                    >
                      Update
                    </PrimaryButton>
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        console.log("DELETE BUTTON WAS CLICKED");
                        if (
                          window.confirm(
                            "Are You Sure You Want to Delete This Configuration?"
                          )
                        ) {
                          deleteConfig(id, settings.id);
                        } else {
                          console.log("DO NOTHING ");
                        }
                      }}
                    >
                      DELETE
                    </DeleteButton>
                  </ButtonsContainer>
                </ChannelForm>
              </ChannelCard>
            </Channels>
          </fieldset>
          <fieldset>
            <legend>Activity Start Period </legend>
            <div>
              <h3>{activityStart || "Has Not Been Set!"}</h3>
            </div>

            <PrimaryButton
              onClick={() => {
                console.log("PRIMARY BUTTON WAS CLICKED");
                context.actions({
                  type: "openModal",
                  payload: {
                    modalComponent: (
                      <ListingSetDateForm
                        title="New Activity Date"
                        listingId={id}
                        updateLocalListing={(date) => {
                          props.getListing(
                            id,
                            context.state.listings
                          ).activityStart = date;
                          setActivityStart(date);
                        }}
                      ></ListingSetDateForm>
                    ),
                  },
                });
              }}
            >
              Set New Date
            </PrimaryButton>
          </fieldset>
        </Content>
      </Page>
    );
  } catch (e) {
    console.log(e);

    return (
      <>
        <h1>{e.message}</h1>
      </>
    );
  }
}
function GetFillingFieldNameByNumber(number){
  let res;
  switch (number) {
    case 1:
      res = "Monthly";
      break;
      case 2:
      res = "Quarterly";
      break;
      case 3:
      res = "N/A";
      break;
    default:
      res = "N/A"
      break;
  }
  return res;
}
