import React, { useState, useContext, useRef } from "react";
import {
  Form,
  Input,
  Title,
  Text,
  Button,
  Label,
  Select,
  PrimaryButton,
} from "../../theme//theme.js";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";
import ToggleSwitch from "../ui/ToggleSwitch";
import DatePicker from "react-datepicker";

const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.negRed};
  align-self: flex-end;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
`;

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

function InvestorExpenseForm(props) {
  const context = useContext(Context);

  const [startDate, setStartDate] = useState(new Date());

  const submitForm = async (values) => {
    let res;
    try {
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });

      res = await FirebaseService.createInvestorExpense(props.id, values);

      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      console.log(res);
      props.onupdate(Math.random());
    } catch (e) {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    }
  };

  const deletePayment = async (listingId, expenseId) => {
    try {
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: true,
          isModalOpen: true,
          modalComponent: "",
        },
      });
      let res = await FirebaseService.deleteInvestorExpense(
        listingId,
        expenseId
      );

      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: false,
          isModalOpen: false,
          modalComponent: "",
        },
      });
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isError: true,
          errorMsg: e.message,
        },
      });
    }
  };

  return (
    <>
      <Title>{props.title}</Title>

      {/* FORMIK */}
      <Formik
        initialValues={
          !props.init
            ? {
                amount: 0,
                type: "Cash",
                notes: "",
                paidAt: startDate,
              }
            : props.init
        }
        validate={(values) => {
          console.log("VALIDATE : ", values);
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        enableReinitialize={true}
        validationSchema={yup.object().shape({
          amount: yup.number().required(),
          type: yup.string().required(),
          notes: yup.string().required(),
        })}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Label>
              Amount
              {errors.amount && <Text color="red">{errors.amount}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.amount}
                border={errors.amount && "1px solid red"}
                type="number"
                name="amount"
                placeholder="Expense Amount"
              />
            </Label>

            <Label>
              Paid at
              <DatePickerField
                name="paidAt"
                value={values.paidAt}
                onChange={setFieldValue}
              />
            </Label>
            <Label>
              Type
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.type}
                border={errors.type && "1px solid red"}
                type="text"
                name="type"
                placeholder="Expense Amount"
              >
                <option value="Mortgage principle">Mortgage principle</option>
                <option value="Mortgage interest">Mortgage interest</option>
                <option value="Utilities">Utilities</option>
                <option value="Home owner insurance">
                  Home owner insurance
                </option>
                <option value="Property tax">Property tax</option>
                <option value="Other monthly expenses">
                  Other monthly expenses
                </option>
              </Select>
            </Label>

            <Label>
              Notes
              {errors.notes && <Text color="red">{errors.notes}</Text>}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.notes}
                border={errors.notes && "1px solid red"}
                type="text"
                name="notes"
                placeholder="Add your notes"
              />
            </Label>

            <ButtonsContainer>
              <PrimaryButton type="submit">Save</PrimaryButton>
              {props.init !== null ? (
                <DeleteButton
                  type="button"
                  onClick={async () => {
                    let a = window.confirm(
                      "Are you sure you want to delete the expense?"
                    );
                    if (a) {
                      try {
                        await deletePayment(
                          props.init.listingId,
                          props.init.id
                        );
                        props.onupdate(Math.random());
                      } catch (e) {
                        console.log(e);
                        context.actions({
                          type: "setState",
                          payload: {
                            ...context.state,
                            isError: true,
                            errorMsg: e.message,
                          },
                        });
                      }
                    }
                  }}
                >
                  DELETE
                </DeleteButton>
              ) : (
                <></>
              )}
            </ButtonsContainer>
          </Form>
        )}
      </Formik>
      {/* END OF FORMIK */}
    </>
  );
}

export default InvestorExpenseForm;
