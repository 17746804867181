import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`


html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
  --white: white;
  --gnorange:rgb(253,224,117);
  --gnorangetrans:rgba(253,224,117,0.4);
  --darkblue: #282c34;
  --spacegray: rgba(0, 0, 0, 0.06);
  --mediumgray: rgba(0, 0, 0, 0.04);
  --darkgray: rgba(0, 0, 0, 0.2);
  --overlaygray: rgba(0, 0, 0, 0.45);
  --posgreen: #82ca9d;
  --posgreendark: #58926e;
  --gray: #f2f2f2;
  --semiwhite: rgba(255, 255, 255, 0.94);
  --color-error : red;
  font-family: "Lato", sans-serif;
  text-align:center;
  
}


*:focus {
  outline: none;
}
div{
    -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;
  box-sizing:border-box;

}

.data-table{
  overflow:hidden;
  box-sizing:border-box;
  max-width:90vw;
 width:100%;
  margin:0.35rem 0 1.4rem 0 ;
  background-color:${({ theme }) => theme.white};
  font-size:1.2rem;
  border: none;
  box-shadow: var(--darkgray)1px 1px 2px;
}
.data-Table .partial{
  color:red
}

fieldset {
  position:relative;
  padding:2rem 1rem;
  border-radius: 4px;
  background-color: var(--white);
  border: none;
  box-shadow: var(--darkgray)1px 1px 2px;
  display:flex;
  flex-flow :column wrap;
  width:100%;
  margin-top:3rem;
  height:fit-content;
  
} 


legend {
  padding:0;
  font-size:1.4em;
  margin:0;
  text-align: left;
  position:absolute;
  top:-2rem;
  

} 


.App {
  text-align: center;
  color: #282c34;
  display: grid;
  grid-template-columns: 15vw 85vw;
  grid-template-rows: 100vh;
  box-sizing: border-box;
}

.App-logo {
  max-width: 170px;
  pointer-events: none;
  width:80%;
}

.App-version{
  color:gray;
  font-size:0.6rem;
  position:absolute;
  bottom:0.6rem;
  left:0.6rem;
  z-index:500
}
.App-logo-container {
  background-color: white;
  min-height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0
}

.App-sidebar {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  box-shadow: rgba(0, 0, 0, 0.32) 1px 0px 2px;
  height: 100vh;
  border-right: 0px#f8cf3a solid;
  box-sizing: border-box;
}
.App-nav {
  background-color:var(--darkblue);
  width: 100%;
  height: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  box-sizing: border-box;
}
a{
  text-decoration: none;
  color:inherit;
}
 ul {
  list-style: none;
  border: 0px black solid;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  text-align: left;
}

.App-nav li {
  color: var(--white);
  font-size: 1rem;
  font-weight: 100;
  padding: 2px 5px 2px 40px;
  margin-bottom: 10px;
  height:3rem;
  line-height:3rem;
  box-sizing: border-box;
  cursor: pointer;
}
.App-nav li:hover {
  color: white;
 
  background-color: rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
}

.App-nav li .nav-label {
  margin-left: 12px;
 
}

.nav-selected{
 color: var(--gnorange)!important;
}
.App-main {
  background-color: var(--spacegray);
  overflow: hidden;
  position: relative;
}


@media only screen and (max-width: 1024px) {
  body{
    font-size: 12px;
      overflow-y:auto;

  }
  h1{
    font-size: 1.2rem;
  }
  .App {
    grid-template-columns: minmax(auto, 100vw);
  }

  .desktop {
    display: none  !important;
  }


  .page{
    padding: 0 20px 0 20px !important;
  }

  .App-sidebar {
    position: absolute;
    left: -100%;
    width: 100%;
    max-width: 80vmin;
    z-index: 100;
    height: 100%;
  }
}

.lds-ripple { 
  position: relative;
  margin:auto;
  width: 6rem;
  line-height: 14rem;
  color: var(--darkblue);
  font-weight: 100;
  font-size: 1.1rem;

}

.lds-ripple div {
  position: absolute;
  border: 4px solid var(--gnorange);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  z-index: -1;
  left:-8rem;
  text-align:center;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
.lds-ripple div:nth-child(3) {
  animation-delay: -0.8s;
}
@keyframes lds-ripple {
  0% {
    top: 3rem;
    left: 3rem;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 6rem;
    height: 6rem;
    opacity: 0;
  }
}
.ids-ripple-text {
  color: var(--darkblue);
  line-height: 14rem;
}


.page {
  overflow-y: auto;
  scroll-behavior: smooth ;
  height: 100%;
  padding: 0 2vw 0 2vw;
  animation: animatePage 0.3s ease-in-out;
}
.contentContainer {
  padding-top: 14vh;
  height: auto;
  display: flex;
  justify-content: center;
 
}
.listContainer {
  flex-flow: row wrap;
  align-content: flex-start;
 
}
.userContainer{
  flex-flow: column ;
  justify-content:space-evenly;
 
}

.view-listing-container{
  flex-flow: column ;
  justify-content:space-around;
  }

  .view-listing-container fieldset{
    margin-bottom:2rem;
  }


.card {
  border-radius: 4px;
  margin: 4px;
  background-color: var(--white);
}

.widget-card{
  min-height: 100px;
  max-width: 60vw;
  padding:20px;
  display:flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.user-row{
  display:flex;
  flex-direction: row;
  max-height:40px;
  border: 1px var(--darkgray) solid;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 10px 10px 10px 20px;
  align-items: center;
  width: 80%;
}

.list-card {
  height: 400px;
  max-width:360px;
  min-width: 340px;
  display: flex;
  flex-direction: column;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  color:var(--darkblue) !important;
  background-color: var(--white);
  box-shadow: var(--darkgray) 1px 2px 4px;
}
.list-card .hero {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: white; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 100%;
  margin: 4px 4px 0 4px;
  box-sizing: border-box;
  text-align: left;
}

.listing-edit-button{
  color:var(--white);
  background-color: var(--darkgray);
  padding:4px;
  max-width:2rem;
  border-bottom-right-radius: 8px;
}

.listing-nickname{
  color:var(--white);
  background-color: var(--overlaygray);
  padding:4px;
  max-width:280px;
  font-size:1.6rem;
  border-bottom-right-radius: 8px;
position:relative;
 top:43%;
 font-weight:100;
 border-bottom: 1px solid lightslategray;

}

.listTitle {
  text-align: left;
  margin-left: 8px;
  padding: 10px 0 10px 5px;
  font-weight: 800;
}

.card-chart {
  font-size: 0.5rem;
  cursor: pointer;
}

.list-chart {
  max-height: 80px;
}

.search-bar {
  height: 45px;
  width:60%;
  background-color: white;
  border-radius: 40px;
  line-height: 45px;
  margin-left: 10vw;
  border: 1px var(--darkgray) solid;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 1.5rem;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.search-bar input {
  width: 70%;
  line-height: 24px;
  font-size: 1.2rem;
  -webkit-appearance: none;
  border: none;
  margin-left: 8px;
  
}

.top-menu-bar {
  position: absolute;
  z-index: 100;
  left:0;
  height: 62px;
  display: flex;
  width:100%;
  background-color: var(--white);
  padding: 10px 20px 10px 20px;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: var(--darkgray) 1px 1px 2px;
  flex-direction: row;
  @media screen and (max-width: 1024px) {
      position:fixed
  }
}

.button2 {
  height: 45px;
  margin-left: 10px;
  border-radius: 4px;
  background-color: var(--posgreen);
  padding:4px 18px 4px 18px;
  box-sizing: border-box;
  line-height: 35px;
  cursor: pointer;
}
.button2:hover{
  background-color: var(--postgreendark);
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--posgreen);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--posgreendarkblue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switchLabel {
  font-size: 0.8rem;
  margin: 0 3px 0 3px;
}
@keyframes animatePage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/** Modal **/
.modal{
  position: absolute;
  display: flex;
  width:100%;
  height:100vh;
  flex-direction: row;
justify-content: center;
align-items: center;
top:0;
background-color: var(--darkgray);
animation: animatePage 0.3s ease-in-out;
}


.modal .card{
  width:100%;
  min-width: 300px;
  height:90vh;
  max-height: 99vh;
  display:flex;
  flex-direction: column;
  padding:20px;
  box-sizing: border-box;
  align-items: center;
  overflow-y: auto;
  position: relative;
 
}
.hide{
  display: none;
}

.closeButton{
  position:absolute;
  z-index:100;
  left:20px;
  top:20px;
}

.wordwrap { 
  overflow-wrap: break-word !important;
}

.widget-container{
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  width:100%;
  justify-content: center;
}





.chart-widget{
 min-width:300px;
 width:100%;
  background-color: var(--white);
  display:flex;
  flex-flow:row wrap;
  justify-items:stretch;
  justify-content:center;

}



.toggle-switch {
  position: relative;
  width: 78px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  transform:scale(0.8);
 
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  
}
.toggle-switch-inner::before, .toggle-switch-inner::after {
  display: block;
  float: left;
  width:50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
 
}
.toggle-switch-inner:before {
  content: attr(data-yes) ;
  text-transform: uppercase;
  padding-left: 10px;
  background-color: ${({ theme }) => theme.primaryDark};
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-disabled::before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-inner::after {
  content: attr(data-no);
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 28px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}

.toggle-switch.big-switch {
  width: 100px;
}
.toggle-switch.big-switch .toggle-switch-switch{
  right:65px;
}

.toggle-switch.small-switch {
  width: 40px;
}
.toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
  content: "";
  height: 20px;
  line-height: 20px;
}
.toggle-switch.small-switch .toggle-switch-switch {
  width: 16px;
  right: 20px;
  margin: 2px;
}
@media screen and (max-width: 991px) {
  .toggle-switch {
    transform: scale(0.9);
  }
  .App-version{
    display:inherit;
  }
 
}
@media screen and (max-width: 767px) {
  .toggle-switch {
    transform: scale(0.825);
  }
  .App-version{
    display:none;
  }
}
@media screen and (max-width: 575px) {
  .toggle-switch {
    transform: scale(0.75);
  }
  .App-version{
    display:none;
  }
}



.custom-select {
  position: relative;
  font-family: Arial;
  min-width:200px;
  min-height:200px;
  border:1px black solid;
 
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.roitooltip{
  background-color : DodgerBlue !important;
  opacity:1 !important;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

`;
