import React, { useState, useContext } from "react";
import Context from "../../store/context";
import styled from "styled-components";
import { faInfoCircle, faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PhotoGallery from "../ui/PhotoGallery";

const Cells = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 1.5rem;
  line-height: 1.4rem;
  padding: 0rem 0.4rem;

  & div {
    width: 30%;
    border-bottom: 1px ${({ theme }) => theme.darkGray} dotted;
    &.income {
      color: ${({ theme }) => theme.posGreen};
    }
    &.expense {
      color: ${({ theme }) => theme.negRed};
    }
    & .icon {
      margin-left: 1rem;
    }
    & .iconContainer {
      width: 30px;
    }
    &.payouts {
      color: ${({ theme }) => theme.primaryDark};
    }
  }
  & :first-child {
    width: 60%;
  }
  & :last-child {
    text-align: right;
  }
`;

const InnerCells = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  line-height: 1.4rem;
  padding: 0rem 0.8rem;
  font-size: 0.8rem;
  cursor: pointer;

  & div {
    margin-left: 1rem;
  }
  & .desc {
    margin-left: 1rem;
  }
  & :first-child {
    width: auto;
  }
`;
export default function ExpensesBox(props) {
  const context = useContext(Context);
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Cells>
        <div>{props.type}</div>
        <div className="iconContainer">
          <FontAwesomeIcon
            className="icon"
            onClick={() => setOpen(!isOpen)}
            icon={faInfoCircle}
          ></FontAwesomeIcon>
        </div>
        <div className="expense">${props.total}</div>
      </Cells>
      {isOpen ? (
        <>
          {props.list.map((el) => (
            <InnerCells>
              <div>{"$" + el.amount} </div>
              <div>
                paid at
                {" " +
                  moment
                    .utc(new Date(el.paidAt._seconds * 1000))
                    .format("MM-DD-YYYY")}
              </div>

              {el.media && el.media.length > 0 ? (
                <div>
                  <FontAwesomeIcon
                    onClick={() => {
                      context.actions({
                        type: "openModal",
                        payload: {
                          modalComponent: (
                            <PhotoGallery images={el.media}></PhotoGallery>
                          ),
                        },
                      });
                    }}
                    icon={faPhotoVideo}
                  ></FontAwesomeIcon>
                </div>
              ) : (
                <></>
              )}
              {el.description ? (
                <div className="desktop">
                  {el.source ? el.source : el.type ? el.type : " "}
                  {el.description ? (
                    <>
                      <span className="desc">{el.description}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </InnerCells>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
