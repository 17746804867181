import React, { useState, useEffect, useContext } from "react";

import Context from "../../store/context";

import FirebaseService from "../../services/FirebaseService";
import FinanceTable from "./FinanceTable";
import CResForm from "./CResForm";
import { PrimaryButton } from "../../theme/theme";
import InvestorExpenseForm from "./InvestorExpenseForm";

const buildColumnObj = function (name, selectorName, isNumber, sign, width) {
  let columnObj = {
    name: name,
    selector: selectorName,
    width: width,
    sortable: true,
  };

  columnObj.format = (row) => {
    let res = row[selectorName];
    res = isNumber ? res.toFixed(2) : res;
    return res + (sign !== undefined ? sign : "");
  };

  return columnObj;
};

const columns = [
  buildColumnObj("Amount", "amount", true, "$"),
  buildColumnObj("Type", "type", false, ""),
  buildColumnObj("Paid at ", "paidAt", false, ""),
  buildColumnObj("Comments ", "notes", false, ""),
];

const getTableData = (period, year, dataObject) => {
  console.log("getTableData ", period, year);
  let res = [];
  if (period === "annual" && dataObject[year]) {
    Object.values(dataObject[year]).map((monthArray) => {
      return (res = [...res, ...monthArray]);
    });
    return res;
  } else {
    res = (dataObject[year] && dataObject[year][period]) || [];
    return res;
  }
};

const openExpenseForm = (context, id, title, initData, updateLocalRefresh) => {
  context.actions({
    type: "openModal",
    payload: {
      modalComponent: (
        <InvestorExpenseForm
          title={title}
          id={id}
          init={initData}
          onupdate={updateLocalRefresh}
        ></InvestorExpenseForm>
      ),
    },
  });
};

function InvestorExpensesTable(props) {
  const context = useContext(Context);

  const [list, setList] = useState(null);
  const [localRefresh, updateLocalRefresh] = useState(Math.random());

  useEffect(() => {
    const loadList = async function (id, year) {
      let payload = await FirebaseService.getInvestorExpenses(id, year);
      setList(payload.data);
      console.log("INVESTOR EXPENSES : ", payload.data);
    };

    loadList(props.id, props.year);
  }, [props.year, localRefresh]);

  return (
    <>
      <PrimaryButton
        onClick={() => {
          openExpenseForm(
            context,
            props.id,
            "Create Expense",
            null,
            updateLocalRefresh
          );
        }}
      >
        Add New
      </PrimaryButton>
      {list !== null ? (
        <FinanceTable
          data={getTableData(props.bulk, props.year, list)}
          title=""
          columns={columns}
          onRowClick={(initData) => {
            openExpenseForm(
              context,
              props.id,
              "Update Expense",
              initData,
              updateLocalRefresh
            );
          }}
          paginationPerPage={5}
        ></FinanceTable>
      ) : (
        <>
          <div>Loading Data</div>
        </>
      )}
    </>
  );
}

export default InvestorExpensesTable;

/*


    */
