import React, { useState, useContext, useEffect } from "react";
import UserRow from "./UserRow"
import GlobalContext from "../../store/context";
import FirebaseService from "../../services/FirebaseService"

function UserList(props) {
  
  const context = useContext(GlobalContext)
  console.log(context);
  const [selected, setSelected] = useState("undefined")
  const [assets] = useState(context.state.listings)
  const [touched, setTouch] = useState(Math.random())

  useEffect(() => {
    context.actions({ type: "getUsers", payload: null })
  }, [touched]);

  return (
    <>

      {
        props.users.map((el, index) => (
          <UserRow key={el.uid} user={el} isEdit={selected == el.uid} onGetSelected={(id) => setSelected(id)} listings={assets} onClose={() => { props.onClose(); setTouch(Math.random()) }} onUpdate={() => { setTouch(Math.random()); props.onClose() }}>
          </UserRow>
        ))
      }

    </>

  )
}

export default UserList;
