import React, { useState, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { useParams } from "react-router-dom";
import TimeSelector from "../ui/TimeSelector";
import {
  Form,
  Input,
  Label,
  Button,
  SubTitle,
  RoundButton,
} from "../../theme/theme";
import ExpenseForm from "../Listings/ExpenseForm";
import PaymentForm from "../Listings/PaymentForm";
import IncomeForm from "../Listings/IncomeForm";
import RuleForm from "../Listings/RuleForm";

import {
  faThermometerThreeQuarters,
  faMoon,
  faMoneyBillWave,
  faAddressBook,
  faConciergeBell,
  faFunnelDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Sector,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Legend,
  BarChart,
  Bar,
  Cell,
} from "recharts";
import Context from "../../store/context";
import FinanceTable from "./FinanceTable";

const PnlLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  text-align: left;
`;

const AcountTable = styled.div`
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  border-radius: 4px;
  border: 1px ${({ theme }) => theme.darkGray} solid;
  padding: 0.5rem;
  width: 100%;
  max-width: 900px;
  min-width: 300px;
  margin: 0.5rem auto;
`;

const Cells = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 1.5rem;
  line-height: 1.4rem;
  padding: 0rem 0.4rem;

  & div {
    width: 50%;
    border-bottom: 1px ${({ theme }) => theme.darkGray} dotted;
    &.income {
      color: ${({ theme }) => theme.posGreen};
    }
    &.expense {
      color: ${({ theme }) => theme.negRed};
    }
  }
  & :last-child {
    text-align: right;
  }
`;
const TableTitleRow = styled.div`
  font-size: 1rem;
  font-weight: 900;
  width: flex 1;
  padding: 0.7rem 0.3rem;
  &.balance {
    background-color: ${({ theme }) => theme.darkGray};
    font-size: 1.1rem;
  }
`;

const TableSubTotalRow = styled.div`
  font-size: 1rem;
  font-weight: 900;
  width: flex 1;
  height: 2rem;
  padding: 0.5rem 0;
`;

const expenses_columns = [
  {
    name: "Amount ($)",
    selector: "amount",
    sortable: true,
  },
  {
    name: "Type",
    selector: "type",
    sortable: true,
  },
  {
    name: "Source",
    selector: "source",
    sortable: true,
  },
  {
    name: "Paid at",
    selector: "paidAt",
    sortable: true,
  },
  {
    name: "Description",
    selector: "description",
    sortable: false,
    hide: "sm",
  },
];

const payments_columns = [
  {
    name: "Amount ($)",
    selector: "amount",
    sortable: false,
  },
  {
    name: "IssuedBy",
    selector: "doneBy",
    sortable: false,
  },
  {
    name: "Paid at",
    selector: "paidAt",
    sortable: true,
  },
  {
    name: "Type",
    selector: "type",
    sortable: false,
    hide: "sm",
  },
];

const income_columns = [
  {
    name: "Amount ($)",
    selector: "amount",
    sortable: true,
  },
  {
    name: "IssuedBy",
    selector: "doneBy",
    sortable: true,
  },
  {
    name: "Paid at",
    selector: "paidAt",
    sortable: true,
  },
  {
    name: "Type",
    selector: "type",
    sortable: false,
    hide: "sm",
  },
];

const openExpenseForm = (context, id, title, refresh, initData) => {
  context.actions({
    type: "openModal",
    payload: {
      modalComponent: (
        <ExpenseForm
          title={title}
          id={id}
          refresh={refresh}
          init={initData}
        ></ExpenseForm>
      ),
    },
  });
};

const openPaymentForm = (context, id, title, refresh, initData) => {
  context.actions({
    type: "openModal",
    payload: {
      modalComponent: (
        <PaymentForm
          title={title}
          id={id}
          refresh={refresh}
          init={initData}
        ></PaymentForm>
      ),
    },
  });
};

const openIncomeForm = (context, id, title, refresh, initData) => {
  context.actions({
    type: "openModal",
    payload: {
      modalComponent: (
        <IncomeForm
          title={title}
          id={id}
          refresh={refresh}
          init={initData}
        ></IncomeForm>
      ),
    },
  });
};

const openRuleForm = (context, id, title, refresh, initData) => {
  context.actions({
    type: "openModal",
    payload: {
      modalComponent: (
        <RuleForm
          title={title}
          id={id}
          refresh={refresh}
          init={initData}
        ></RuleForm>
      ),
    },
  });
};

export default function FinEdit(props) {
  const context = useContext(Context);
  let { id } = useParams();

  return (
    <>
      <fieldset>
        <PnlLayout>
          <FinanceTable
            data={props.expenses}
            title="Expenses"
            columns={expenses_columns}
            onRowClick={(initData) => {
              openExpenseForm(
                context,
                id,
                "Modify Expense",
                props.refresh,
                initData
              );
            }}
          ></FinanceTable>
          <Button
            onClick={() => {
              openExpenseForm(
                context,
                id,
                "Create new Expense",
                props.refresh,
                null
              );
            }}
          >
            Add Client Expense
          </Button>
        </PnlLayout>
      </fieldset>

      <fieldset>
        <PnlLayout>
          <FinanceTable
            data={props.payments}
            title="Customer Payments"
            columns={payments_columns}
            onRowClick={(initData) => {
              openPaymentForm(
                context,
                id,
                "Update Payment",
                props.refresh,
                initData
              );
            }}
          ></FinanceTable>
          <Button
            onClick={() => {
              openPaymentForm(
                context,
                id,
                "Create Customer Payment",
                props.refresh,
                null
              );
            }}
          >
            New Client Payment{" "}
          </Button>
        </PnlLayout>
      </fieldset>
      <fieldset>
        <legend></legend>
        <PnlLayout>
          <FinanceTable
            data={props.income}
            title="Listing Income"
            columns={income_columns}
            onRowClick={(initData) => {
              openIncomeForm(
                context,
                id,
                "Update Income",
                props.refresh,
                initData
              );
            }}
          ></FinanceTable>
          <Button
            onClick={() => {
              openIncomeForm(
                context,
                id,
                "Create Listing Income",
                props.refresh,
                null
              );
            }}
          >
            New Listing Income{" "}
          </Button>
        </PnlLayout>
      </fieldset>
      <fieldset>
        <legend></legend>
        <PnlLayout>
          <FinanceTable
            data={props.rules}
            title="Expense Rules"
            columns={expenses_columns}
            onRowClick={(initData) => {
              openRuleForm(
                context,
                id,
                "Update Expense Rule",
                props.refresh,
                initData
              );
            }}
          ></FinanceTable>
        </PnlLayout>
      </fieldset>
    </>
  );
}
