import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import SubMenu from "../NavBar/SubMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Page, Content } from "../../theme/theme";
import { useParams, Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Context from "../../store/context";
import FinEdit from "./FinEdit";
import ConfigEdit from "./ConfigEdit";
import FirebaseService from "../../services/FirebaseService";
import moment from "moment";

const MenuBar = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.primaryDark};
  box-shadow: ${(props) => props.theme.darkGray} 1px 1px 2px;
  top: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
  z-index: 1001;
  height: 3.5rem;
  &.secondary {
    z-index: 100;
    top: 3.5rem;
    background-color: white;
    background-color: ${(props) => props.theme.white};
  }

  @media (max-width: 1024px) {
    position: fixed;
    background-color: ${({ theme }) => theme.primaryDark};
  }
`;
const ViewContent = styled(Content)`
  padding-top: 7rem;
  overflow-y: auto;
`;

const ListTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 3.5rem;
  color: ${({ theme }) => theme.brandColor};
  @media (max-width: 1024px) {
    font-size: 0.9rem;
    font-weight: 100;
  }
`;
const BackButton = styled(Link)`
  color: ${({ theme }) => theme.brandColor};
  font-size: 1.8rem;
  @media (min-width: 1024px) {
    display: none;
  }
`;

function EditListing(props) {
  const context = useContext(Context);
  let { id } = useParams();

  const [mode, setMode] = useState(0);
  const [refresh, setRefresh] = useState(Date.now());

  const [expenseList, setExpenses] = useState([]);
  const [paymentList, setPayments] = useState([]);
  const [incomeList, setIncome] = useState([]);
  const [rulesList, setRules] = useState([]);
  const changeMode = function (newMode) {
    setMode(newMode);
    console.log(mode);
  };

  const subMenuItems = [
    { label: "Finance", to: "/" },
    { label: "Configuration", to: "/" },
    { label: "< View", to: "/" },
  ];

  useEffect(() => {
    const getFinData = async () => {
      console.log(id);
      context.actions({
        type: "setState",
        payload: {
          ...context.state,
          isFetching: true,
          isModalOpen: false,
          modalComponent: "",
        },
      });
      try {
        let expenses = await FirebaseService.getListingExpenses(id);
        let payments = await FirebaseService.getListingPayments(id);
        let income = await FirebaseService.getListingIncome(id);
        let rules = await FirebaseService.getListingRules(id);
        setExpenses(expenses);
        setPayments(payments);
        setIncome(income);
        setRules(rules);
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isFetching: false,
            isModalOpen: false,
            modalComponent: "",
          },
        });
      } catch (e) {
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isError: true,
            errorMsg: e.message,
            isModalOpen: false,
            modalComponent: "",
          },
        });
      }
    };
    getFinData();
  }, [refresh]);

  return (
    <Page>
      <MenuBar>
        <BackButton to="/listings">
          <FontAwesomeIcon icon={faChevronLeft} />
        </BackButton>
        <ListTitle>{getlisting(id, context.state.listings).title}</ListTitle>
      </MenuBar>

      <MenuBar className="secondary">
        <SubMenu
          onChangeMode={changeMode.bind(this)}
          mode={mode}
          items={subMenuItems}
        ></SubMenu>
      </MenuBar>

      <ViewContent>
        {mode == 0 ? (
          <FinEdit
            expenses={expenseList}
            payments={paymentList}
            income={incomeList}
            rules={rulesList}
            refresh={setRefresh}
          ></FinEdit>
        ) : (
          <></>
        )}
        {mode == 1 ? (
          <ConfigEdit
            {...props}
            id={id}
            activityStart={getlisting(id, context.state.listings).activityStart}
            getListing={getlisting}
          ></ConfigEdit>
        ) : (
          <></>
        )}
        {mode == 2 ? <Redirect to={"/listing/" + id}></Redirect> : <></>}
      </ViewContent>
    </Page>
  );
}

const getlisting = function (id, list) {
  console.log("THE LIST", list);
  let reduced = list.filter((e) => e.listingId === id);
  let res = reduced.length > 0 ? reduced[0].data : "???";
  return res;
};

export default EditListing;
