import React, { useState, useContext } from "react";
import Context from "../../store/context";
import styled from "styled-components";
import { faInfoCircle, faPhotoVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PhotoGallery from "../ui/PhotoGallery";
import { object } from "yup";


const Cells = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 1.5rem;
  line-height: 1.4rem;
  padding: 0rem 0.4rem;

  & div {
    width: 30%;
    border-bottom: 1px ${({ theme }) => theme.darkGray} dotted;
    &.income {
      color: ${({ theme }) => theme.posGreen};
    }
    &.expense {
      color: ${({ theme }) => theme.negRed};
    }
    & .icon {
      margin-left: 1rem;
    }
    & .iconContainer {
      width: 30px;
    }
    &.payouts {
      color: ${({ theme }) => theme.primaryDark};
    }
  }
  & :first-child {
    width: 60%;
  }
  & :last-child {
    text-align: right;
  }
`;
const InnerReservation = styled.div`
display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  line-height: 1.4rem;
  padding: 0rem 0.8rem;
  font-size: 0.8rem;
  cursor: pointer;

  & div {
    margin-left: 1rem;
  }
  & .desc {
    margin-left: 1rem;
  }
  & :first-child {

    width: auto;
  }
`;
const InnerCells = styled.div`
  display: flex;
  color:green;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  line-height: 1.4rem;
  padding: 0rem 0.8rem;
  font-size: 0.8rem;
  cursor: pointer;  
  & div {
    margin-left: 1rem;
  }
  & .desc {
    margin-left: 1rem;
  }
  & :first-child {
    width: auto;
  }
`;

export default function TaxExpensesBox(props) {  
  console.log("TaxExpensesBoxprops" , props);  
  const context = useContext(Context);
  const [isOpen, setOpen] = useState(false);  
  var isTax = false;
  var totalTaxesfromReservation = 0;
  var totalTaxesfromExpenses = 0;
  var isTaxes = true;
  var cleaningFeeNUmber = 0;
  var isToOpen = false;
  var isArr = Array.isArray(props.list);
  var totalTax = 0;  
  if(props.type !== 'Processing fee1' || props.type !== 'Channel fee1'){
    if(props.list !== null){
        isToOpen = true;      
    }
    if(props.type === 'Cleaning fee'){
      cleaningFeeNUmber = props.cleaningFee;
    }
    if(props.type === 'Processing fee1' || props.type === 'Channel fee1'){
      isTaxes = false;
    }
    if((props.type != 'Processing fee1' || props.type == 'Channel fee1' ) && isArr){
      isTax = true;
      if(props.total !== props.Reservation){
        totalTaxesfromReservation = props.Reservation; 
        totalTaxesfromExpenses = props.total;
        totalTax =Number(props.Reservation ) + Number(totalTaxesfromExpenses) ;
      }
      else{
        totalTax = props.total;
      }      
    }
    if(props.type == 'Management fee'){
      if(props.total !== props.Reservation){
        totalTaxesfromReservation = props.Reservation; 
        totalTaxesfromExpenses = props.total;
        totalTax =Number(props.Reservation ) + Number(totalTaxesfromExpenses) ;
      }      
      else{
        totalTax = props.total;
      }
      console.log(totalTax);
    }
    else if(props.type == 'Cleaning fee'){
      if(props.total !== props.Reservation){
        totalTaxesfromReservation = props.Reservation; 
        totalTaxesfromExpenses = props.total;
        totalTax =Number(props.Reservation ) + Number(totalTaxesfromExpenses);
      }      
      else{
        totalTax = props.total;
      }
    }
    // else{
    //   totalTax = totalTaxesfromExpenses + props.total;
    // }
    return (
      <div>
        {}
        <Cells>
          <div>{cleaningFeeNUmber !== 0 ?cleaningFeeNUmber :props.type}</div>
          <div className="iconContainer">
            {isTaxes && isArr ? (
              <>
              <FontAwesomeIcon
              className="icon"
              onClick={() => setOpen(!isOpen)}
              icon={faInfoCircle}
            ></FontAwesomeIcon>
              </>
            ):<></>}           
          </div>
          <div className="expense">${totalTaxesfromExpenses !== 0 ? Number(totalTax).toFixed(2)   : Number(props.total).toFixed(2)  }</div>
        </Cells>
        {isOpen && isToOpen === true?(
          <>
          {isTax  ? (
                  <>
                    <div style={{color: "blue"}}>
                    <li>{"Total " + props.type + " : $" + totalTaxesfromReservation}</li>
                    <br></br>                  
                    </div>                      
                <div style={{ color: "green" }}>
                  <li>{"Total " + props.type + " Adjustment : $" + totalTaxesfromExpenses}</li>
                </div>     
                  </>                
                ) : (<></>)}
            {props.list.map((el) => (
              <InnerCells>
                <div>{"$" +el.amount} </div>
                <div>
                  paid at
                  {" " +
                    moment
                      .utc(new Date(el.paidAt._seconds * 1000))
                      .format("MM-DD-YYYY")}
                </div>
                {el.media && el.media.length > 0 ? (
                  <div>
                    <FontAwesomeIcon
                      onClick={() => {
                        context.actions({
                          type: "openModal",
                          payload: {
                            modalComponent: (
                              <PhotoGallery images={el.media} ></PhotoGallery>
                            ),
                          },
                        });
                      }}
                      icon={faPhotoVideo}
                    ></FontAwesomeIcon>
                  </div>
                ) : (
                  <></>
                )}
                {el.description ? (
                  <div className="desktop">
                    {el.source ? el.source : el.type ? el.type : " "}
                    {el.description ? (
                      <>
                        <span className="desc">{el.description}</span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                
              </InnerCells>              
            ))}
             
          </>
          
        ) : (
          <></>
        )}
      </div>
    );
  }
  
}
