import React from "react";
import styled from "styled-components";
import moment from "moment";

const ResContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  border: 1px dotted ${({ theme }) => theme.darkGray};
  border-radius: 4px;
  width: 80%;
  padding: 2rem;
  & div {
    margin-bottom: 0.8rem;
    text-align: left;
    padding: 0.2rem;
    border-bottom: 1px dotted ${({ theme }) => theme.darkGray};
    width: 42%;
  }
  & .title {
    font-weight: bold;
    margin-right: 1.5rem;
  }
  & .content {
    min-width: 150px;
    align-self: flex-end;
  }
  & .partial {
    color: red;
  }
`;

export default function ReservationForm(props) {
  let obj = props.res;
  console.log("reservationForm-props" , props);
  console.log(obj.checkIn);
  console.log(moment.utc(obj.checkIn).format("MM-DD-YYYY"))
  var currentDate = moment().format("MM-DD-YYYY");
  console.log(currentDate);
  var tempCheckInDate = moment.utc(obj.checkIn).format("MM-DD-YYYY");
  console.log(tempCheckInDate);
  if(currentDate>tempCheckInDate){
    console.log("work:(");
  }
  console.log("obj",obj);

  let isCrossMonths =
    Object.keys(
      returnObjectField(obj, "financeCalc." + props.time.format("YYYY"))
    ).length > 2;

  return (
    <ResContainer>
      <div className="title">Guest : </div>
      <div className="title">{returnObjectField(obj, "guest.fullName")}</div>

      <div className="title">Source : </div>
      <div className="content">{returnObjectField(obj, "source")}</div>

      <div className="title">CheckIn : </div>
      <div className="content">
        {returnObjectField(obj, "checkIn").split("T")[0]}
      </div>

      <div className="title">CheckOut : </div>
      <div className="content">
        {returnObjectField(obj, "checkOut").split("T")[0]}
      </div>

      <div className="title">ConfirmedAt : </div>
      <div className="content">
        {returnObjectField(obj, "confirmedAt").split("T")[0]}
      </div>

      <div className="title">Total Nights : </div>
      <div className="content">{returnObjectField(obj, "nightsCount")}</div>

      <div className="title">Accommodation fare: </div>
      <div className="content">
        {(isCrossMonths
          ? "$" +
            returnObjectField(
              obj,
              "financeCalc." +
                props.time.format("YYYY") +
                "." +
                props.time.format("MMM") +
                ".breakdown.fareAccommodation"
            ) +
            "   /"
          : "") +
          "$" +
          returnObjectField(
            obj,
            "financeCalc." +
              props.time.format("YYYY") +
              "." +
              "annual.breakdown.fareAccommodation"
          )}
      </div>
      <div className="title">Cleaning fare: </div>
      <div className="content">
        {(isCrossMonths
          ? "$" +
            Number(
              returnObjectField(obj,"financeCalc." + props.time.format("YYYY") +"." +props.time.format("MMM") +".breakdown.fareCleaning")
              // returnObjectField(obj, "money.fareCleaning") *
              //   Number(
              //     returnObjectField(
              //       obj,
              //       "financeCalc." +
              //         props.time.format("YYYY") +
              //         "." +
              //         props.time.format("MMM") +
              //         ".rate"
              //     )
              //   )
            ).toFixed(2) +
            "   /" 
          : "") +
          "$" +
          returnObjectField(
            obj,
            "financeCalc." +
              props.time.format("YYYY") +
              "." +
              "annual.breakdown.fareCleaning"
          )}
      </div>
      <div className="title">City Tax : </div>
      <div className="content">
        {(isCrossMonths
          ? "$" +
            Number(
              returnObjectField(
                obj,
                "financeCalc." +
                  props.time.format("YYYY") +
                  "." +
                  props.time.format("MMM") +
                  ".breakdown.cityTax"
              )
            ).toFixed(2) +
            "   /"
          : "") +
          "$" +
          returnObjectField(
            obj,
            "financeCalc." +
              props.time.format("YYYY") +
              "." +
              "annual.breakdown.cityTax"
          )}
      </div>

      <div className="title">State Tax : </div>
      <div className="content">
        {(isCrossMonths
          ? "$" +
            Number(
              returnObjectField(
                obj,
                "financeCalc." +
                  props.time.format("YYYY") +
                  "." +
                  props.time.format("MMM") +
                  ".breakdown.stateTax"
              )
            ).toFixed(2) +
            "   /"
          : "") +
          "$" +
          returnObjectField(
            obj,
            "financeCalc." +
              props.time.format("YYYY") +
              "." +
              "annual.breakdown.stateTax"
          )}
      </div>

      <div className="title">Processing fee : </div>
      <div className="content">
        {(isCrossMonths
          ? "$" +
            Number(
              returnObjectField(
                obj,
                "financeCalc." +
                  props.time.format("YYYY") +
                  "." +
                  props.time.format("MMM") +
                  ".breakdown.process_fee"
              )
            ).toFixed(2) +
            "   /"
          : "") +
          "$" +
          returnObjectField(
            obj,
            "financeCalc." +
              props.time.format("YYYY") +
              "." +
              "annual.breakdown.process_fee"
          )}
      </div>

      <div className="title">Channel fee : </div>
      <div className="content">
        {(isCrossMonths
          ? "$" +
            Number(
              returnObjectField(
                obj,
                "financeCalc." +
                  props.time.format("YYYY") +
                  "." +
                  props.time.format("MMM") +
                  ".breakdown.channel_fee"
              )
            ).toFixed(2) +
            "   /"
          : "") +
          "$" +
          returnObjectField(
            obj,
            "financeCalc." +
              props.time.format("YYYY") +
              "." +
              "annual.breakdown.channel_fee"
          )}
      </div>

      <div className="title">Cleaning fee : </div>
      <div className="content">
        {(isCrossMonths
          ? "$" +
            Number(
              returnObjectField(
                obj,
                "financeCalc." +
                  props.time.format("YYYY") +
                  "." +
                  props.time.format("MMM") +
                  ".breakdown.cleaning_fee"
              )
            ).toFixed(2) +
            "   /"
          : "") +
          "$" +
          returnObjectField(
            obj,
            "financeCalc." +
              props.time.format("YYYY") +
              "." +
              "annual.breakdown.cleaning_fee"
          )}
      </div>

      <div className="title">Management Fee</div>
      <div className="content">
        {(isCrossMonths
          ? "$" +
            Number(
              returnObjectField(
                obj,
                "financeCalc." +
                  props.time.format("YYYY") +
                  "." +
                  props.time.format("MMM") +
                  ".breakdown.management_fee"
              )
            ).toFixed(2) +
            "   /"
          : "") +
          "$" +
          returnObjectField(
            obj,
            "financeCalc." +
              props.time.format("YYYY") +
              "." +
              "annual.breakdown.management_fee"
          )}
      </div>

      <div className="title">ID : </div>
      <div className="content">{returnObjectField(obj, "_id")}</div>
    </ResContainer>
  );
}

function returnObjectField(obj, field) {
  try {
    let subs = field.split(".");
    console.log(subs);
    let result = subs.reduce((a, b) => a[b], obj);
    console.log(result);
    if(result.toString().includes('.com')){
      if(result.toString().includes('@')){
        result = result.split('@')[1].split('.')[0];        
      }
      else{
        result = result.split('.')[0];
        
      }
    }
    if (result !== undefined) {
      return result;
    } else {
      throw new Error("No Such Field --> " + field);
    }
  } catch (e) {
    console.log(e.message);
    return "N/A";
  }
}
