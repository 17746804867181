import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Context from "../../store/context";

import { Page, Content, Preloader, Burger, MobileNav } from "../../theme/theme";
import {
  faThList,
  faUserCircle,
  faCog,
  faSignOutAlt,
  faUserEdit,
  faFileArchive,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

class NavBar extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);

    this.state = {
      items: [
        { icon: faThList, label: "My Listings", to: "/" },
        { icon: faUserEdit, label: "Users", to: "/users" },
        { icon: faCog, label: "Settings", to: "/settings" },
        { icon: faFileArchive, label: "Reports", to: "/reports" },
        { icon: faSignOutAlt, label: "Sign out", func: true },
      ],
      userItems: [
        { icon: faThList, label: "My Listings", to: "/" },
        { icon: faFileArchive, label: "Reports", to: "/reports" },
        { icon: faSignOutAlt, label: "Sign out", func: true },
      ],
      selected: this.getInitLocation(this.props.path),
    };
    console.log("NAVBAR Constructor", this.props.path);
  }

  getInitLocation(path) {
    console.log("PATH : ", path);
    let index = 0;
    switch (path) {
      case "/listings":
        index = 0;
        break;
      case "/users":
        index = 1;
        break;
      case "/settings":
        index = 2;
        break;
      case "/reports":
        index = 3;
        break;
      default:
        index = 0;
    }
    return index;
  }
  componentDidMount() {
    this.getInitLocation(this.props.path);
  }

  render() {
    let listItems = this.context.state.isAdmin
      ? this.state.items
      : this.state.userItems;

    let content =
      this.context.state.user !== null ? (
        <>
          <Username>
            <FontAwesomeIcon
              icon={faUserCircle}
              className="user_icon"
            ></FontAwesomeIcon>
            <span>{this.context.state.user.displayName}</span>
          </Username>
          <ul>
            {listItems.map((el, index) =>
              !el.func ? (
                <NavLink to={el.to} key={index}>
                  <li
                    onClick={(e) => this.onItemClick(e, index, el)}
                    className={
                      this.state.selected == index ? "nav-selected" : ""
                    }
                  >
                    <FontAwesomeIcon icon={el.icon} onClick={this.childClick} />
                    <span className="nav-label" onClick={this.childClick}>
                      {el.label}
                    </span>
                  </li>
                </NavLink>
              ) : (
                <li
                  key={index}
                  onClick={(e) => this.onItemClick(e, index, el)}
                  className={this.state.selected == index ? "nav-selected" : ""}
                >
                  <FontAwesomeIcon icon={el.icon} onClick={this.childClick} />
                  <span className="nav-label" onClick={this.childClick}>
                    {el.label}
                  </span>
                </li>
              )
            )}
          </ul>
        </>
      ) : (
        <Wrapper>
          <Title>Welcome to Property Management Portal</Title>
        </Wrapper>
      );
    return (
      <>
        <div className="App-nav">{content}</div>
      </>
    );
  }

  onItemClick(e, index, el) {
    console.log(e);

    if (this.props.closeMe) {
      this.props.closeMe();
    }
    if (el.func) {
      console.log("LOGOUT FUNC ACTIVATED", this.props.func);
      this.props.func();
    }

    this.setState({ selected: index });
    console.log(e);
  }
}

const Title = styled.h1`
  font-size: 1.4rem;
  text-align: center;
  color: var(--gnorange);
`;
const Username = styled.h5`
  font-size: 0.7rem;
  text-align: left;
  border-bottom: 1px solid gray;
  padding-bottom: 2rem;
  color: ${({ theme }) => theme.white};
  & .user_icon {
    margin-right: 0.3rem;
    margin-left: 2.5rem;
    font-size: 1.2rem;
  }
`;
const Wrapper = styled.section`
  padding: 20vh 1rem;
  text-align: center;
`;
export default NavBar;
