import React, { Component } from "react";
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons"


import { Page, Content, Preloader, Burger, MobileNav } from "../../theme/theme";
import {
    faThList,
    faBuilding,
    faCog,
    faSignOutAlt,
    faUserEdit
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";



const MenuBarItem = styled.div`
display:flex;
flex-flow:column nowrap;
border:0px black solid;
padding:0.7rem 0;
align-items:center;
color:${props => props.theme.subMenu};
cursor:pointer;
box-sizing:border-box;
line-height:1rem;
font-size:1rem;
border-bottom-width:0px;
transition: border-bottom-width 0.2s;
&.selected{
    color:${({ theme }) => theme.brandColor};
    font-size:1.2rem;
    font-weight:900;
    border-bottom:3px solid ${({ theme }) => theme.primaryDark};
    border-bottom-width:100%
  

}
`;
class SubMenu extends Component {

    constructor(props) {
        super(props)
        console.log(props);
        this.state = {
            selected: this.props.mode
        }
    }


    render() {

        const content = <>
            {this.props.items.map((el, index) => (

                <MenuBarItem key={index} onClick={(e) => this.onItemClick(e, index)} className={this.state.selected == index ? "selected" : ""}>

                    <span onClick={this.childClick}>{el.label}</span>
                </MenuBarItem>

            ))
            }

        </>

        return (
            <>

                {content}


            </>
        )
    }

    onItemClick(e, index) {
        console.log(index)
        this.setState({ selected: index })
        this.props.onChangeMode(index)
    }


}

const Title = styled.h1`
          font-size: 1.4rem;
          text-align: center;
          color: var(--gnorange);
        `;
const Wrapper = styled.section`
          padding: 20vh 1rem;
          text-align:center;
        `;
export default SubMenu;
