import React, { useState } from "react"
import { Button } from "../../theme/theme"
import styled from "styled-components"
import ToggleSwitch from "./ToggleSwitch"

import { faThermometerThreeQuarters, faMoon, faMoneyBillWave, faFunnelDollar, faConciergeBell, faAddressBook, faForward, faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment"

const RoundButton = styled.div`
box-shadow:none;
display:inline-block;
border-radius:4px;
border-width:0px;
border-style:solid;
box-shadow:none;
border-color:${ props => props.theme.darkGray};
width: 2.5rem;
height: 2.5rem;
line-height: 2.5rem;
font-size: 1rem;
margin: 0 1.5rem;
cursor: pointer;
&:hover {
    box-shadow: none;
}
&.link.link:active {
    box-shadow: none;
}
&.regular{
    border-color: ${ props => props.theme.primaryDark};
    background-color: ${ props => props.theme.primaryLight};
}
`;

const TimeSelectorView = styled.div`
border-color: ${ props => props.theme.primaryDark};
border-radius: 4px;
font-size: 1.1rem;
line-height: 2.5rem;
border-width: 0px;
border-style: solid;
padding: 0.6rem;
display: flex;
flex-flow: row wrap;
background-color: ${ props => props.theme.mediumGray};
width:100%;
min-width:300px;
justify-content: space-around;
margin-bottom: 1rem;
`;

const Selector = styled.div`
    width:280px;
    display:flex;
    flex-flow:row nowrap;
    background-color:white;
    align-self:flex-start;
    justify-content: space-around;
`;
const SwitchContainer = styled.div`
display:"inherit";
&.monthOnly{
    display:none;
}
    `;

function TimeSelector(props) {

    return (
        <TimeSelectorView>
            <Selector>
                <RoundButton onClick={(e) => props.onTimeChange(-1)}><FontAwesomeIcon icon={faBackward} /></RoundButton>
                <div> {(!props.period ? (props.time.format("MMM") + "-") : "") + props.time.year()}</div>

                <RoundButton onClick={(e) => props.onTimeChange(1)}> <FontAwesomeIcon icon={faForward} /></RoundButton>
            </Selector>
            <SwitchContainer className={props.mode ? "monthOnly" : ""}>
                <ToggleSwitch isBig={true} Name="period" dataYes="Year" dataNo="Month" defaultChecked={props.period} onChangeHandler={props.onPeriodChange}></ToggleSwitch>
            </SwitchContainer>
        </TimeSelectorView>

    )
}

export default TimeSelector;