import app from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import LocalDb from "./LocalDB";
import moment from "moment";

const firebaseConfig = {
  apiKey: "AIzaSyAccdlFIVoF7qyqL7jBPjGGK0F7iUQazEc",
  authDomain: "goldnest-352f4.firebaseapp.com",
  databaseURL: "https://goldnest-352f4.firebaseio.com",
  projectId: "goldnest-352f4",
  storageBucket: "goldnest-352f4.appspot.com",
  messagingSenderId: "937088648647",
  appId: "1:937088648647:web:2169560a0f229755fec5e2",
};

let apiUrl = "";

class FirebaseService {
  constructor(apiRef, dbRef) {
    console.log("FIREBASE SERVICE CONSTRUCTOR", apiRef, dbRef);
    this.app = app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.storage = app.storage();
    this.apiRef = apiRef || "remote";
    this.dbRef = dbRef || "remote";
    if (dbRef === "local") {
      let LocalDB = require("./LocalDB");
      this.localDb = new LocalDb();
    }
    if (this.apiRef === "local") {
      apiUrl = "http://localhost:5001/goldnest-352f4/us-central1/api/";
    } else {
      apiUrl = "https://us-central1-goldnest-352f4.cloudfunctions.net/api/";
    }
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  async getListingAnnualPerformanceData3(
    listingId,
    date,
    cached = false,
    isDualYear = false
  ) {
    let promises = [];
    console.log("getListingAnnualPerformanceData2 date", date);
    try {
      if (this.mode !== "local") {
        let request_current = this.getData(
          apiUrl +
            "view/listing/activity/" +
            listingId
        );

        promises.push(request_current);
        if (isDualYear) {
          let request_prev = this.getData(
            apiUrl +
              "view/listing/activity/" +
              listingId
              );
          console.log(request_prev);
          promises.push(request_prev);
        }
        let values = await Promise.all(promises);
        let res = [];
        let currentYearData = await values[0].json();
        console.log(values);
        res.push(currentYearData.payload);
        if (isDualYear) {
          let prevYearData = await values[1].json();
          res.push(prevYearData.payload);
        }
        console.log("getListingAnnualPerformanceData2 res", res);
        return res;
      } else {
        let query = this.localDb.getData();
        console.log(query);
        return query.payload;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
  async getListingAnnualPerformanceData2(
    listingId,
    date,
    cached = false,
    isDualYear = false
  ) {
    let promises = [];
    console.log("getListingAnnualPerformanceData2 date", date);
    try {
      if (this.mode !== "local") {
        let request_current = this.getData(
          apiUrl +
            "view/listing/activity/" +
            listingId +
            "?date=" +
            date
        );

        promises.push(request_current);
        if (isDualYear) {
          let request_prev = this.getData(
            apiUrl +
              "view/listing/activity/" +
              listingId +
              "?date=" +
              moment(date).subtract(1, "year").format("YYYY-MM-DD")
              );
          console.log(request_prev);
          promises.push(request_prev);
        }
        let values = await Promise.all(promises);
        let res = [];
        let currentYearData = await values[0].json();
        console.log(values);
        res.push(currentYearData.payload);
        if (isDualYear) {
          let prevYearData = await values[1].json();
          res.push(prevYearData.payload);
        }
        console.log("getListingAnnualPerformanceData2 res", res);
        return res;
      } else {
        let query = this.localDb.getData();
        console.log(query);
        return query.payload;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
  async createUser(name, phone, password, email, role) {
    let data = {
      data: {
        name: name,
        phone: phone,
        email: email,
        password: password,
        role: role,
      },
    };
    console.log("createUser : ", data);
    try {
      let user = await (
        await this.postData(apiUrl + "admin/createUser", data)
      ).json();
      console.log(user);
      return user;
    } catch (e) {
      throw e;
    }
  }

  async updateUser(id, name, phone, email, role) {
    let data = {
      data: {
        name: name,
        phone: phone,
        email: email,
        role: role,
      },
    };
    console.log("updateUser : ", data);
    try {
      let user = await (
        await this.putData(apiUrl + "admin/user/" + id, data)
      ).json();
      console.log(user);
      return user;
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  }

  logout() {
    this.auth.signOut();
  }

  isInitialized() {
    console.log("CHEKING : isInitialized");
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  async loadListings(...params) {
    let data = { data: params[0] };
    try {
      if (this.mode !== "local") {
        let res = await this.getData(apiUrl + "view/listings", data);
        let response = await res.json();
        console.log(response);
        return response.payload;
      } else {
        let query = this.localDb.getListings();
        console.log(query);
        return query.payload;
      }
    } catch (e) {
      console.log(e);
    }
  }
  
  async getUsers() {
    try {
      let res = await this.getData(apiUrl + "admin/users");
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      // throw new Error("ERROR MY ERR")
    }
  }

  async getListingData(listingId, date) {
    console.log(date);
    try {
      if (this.mode !== "local") {
        let request = await this.getData(
          apiUrl + "view/listing/activity/" + listingId + "?date=" + date
        );
        let response = await request.json();
        console.log(response);
        return response.payload;
      } else {
        let query = this.localDb.getData();
        console.log(query);
        return query.payload;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getListingAnnualPerformanceData(
    listingId,
    date,
    cached = false,
    isDualYear = true
  ) {
    let promises = [];
    console.log(date);
    try {
      if (this.mode !== "local") {
        let request_current = this.getData(
          apiUrl +
            "view/listing/activity/" +
            (cached === true ? "cached/" : "") +
            listingId +
            "?date=" +
            date
        );
        promises.push(request_current);

        if (isDualYear) {
          let request_prev = this.getData(
            apiUrl +
              "view/listing/activity/" +
              (cached === true ? "cached/" : "") +
              listingId +
              "?date=" +
              moment(date).subtract(1, "year").format("YYYY-MM-DD")
          );
          promises.push(request_prev);
        }

        let values = await Promise.all(promises);
        let res = [];
        let currentYearData = await values[0].json();
        res.push(currentYearData.payload);
        if (isDualYear) {
          let prevYearData = await values[1].json();
          res.push(prevYearData.payload);
        }

        return res;
      } else {
        let query = this.localDb.getData();
        console.log(query);
        return query.payload;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async getListingCanceledData(listingId, date) {
    console.log(date);
    try {
      if (this.mode !== "local") {
        let request = await this.getData(
          apiUrl + "view/listing/canceled/" + listingId + "?date=" + date
        );
        let response = await request.json();
        console.log(response);
        return response.payload;
      } else {
        let query = this.localDb.getData();
        console.log(query);
        return query.payload;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async postData(url, data) {
    const request = {
      body: JSON.stringify(data), //JSON.stringify(data), // must match 'Content-Type' header
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *omit
      method: "POST", // *GET, PUT, DELETE, etc.
      mode: "cors", // cors, *same-origin
      redirect: "follow", // *manual, error
      referrer: "no-referrer", // *client
      headers: {
        "user-agent": "Mozilla/4.0 MDN Example",
        "content-type": "application/json",
      },
    };

    let token = await this.auth.currentUser.getIdToken();
    request.headers.Authorization = "Bearer " + token;
    try {
      let res = await fetch(url, request);
      if (res.status > 399) {
        let status = await res.json();
        console.log(status);
        let ne = new Error(status.message);
        ne.code = status.code;
        throw ne;
      } else {
        return res;
      }
    } catch (e) {
      console.error(e.name + " :  " + e.message);
      throw e;
    }
  }

  async deleteData(url, data) {
    const request = {
      body: JSON.stringify(data), //JSON.stringify(data), // must match 'Content-Type' header
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *omit
      method: "DELETE", // *GET, PUT, DELETE, etc.
      mode: "cors", // cors, *same-origin
      redirect: "follow", // *manual, error
      referrer: "no-referrer", // *client
      headers: {
        "user-agent": "Mozilla/4.0 MDN Example",
        "content-type": "application/json",
      },
    };

    let token = await this.auth.currentUser.getIdToken();
    request.headers.Authorization = "Bearer " + token;
    try {
      let res = await fetch(url, request);
      if (res.status > 399) {
        let status = await res.json();
        console.log(status);
        let ne = new Error(status.message);
        ne.code = status.code;
        throw ne;
      } else {
        return res;
      }
    } catch (e) {
      console.error(e.name + " :  " + e.message);
      throw e;
    }
  }

  async putData(url, data) {
    const request = {
      body: JSON.stringify(data), //JSON.stringify(data), // must match 'Content-Type' header
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *omit
      method: "PUT", // *GET, PUT, DELETE, etc.
      mode: "cors", // cors, *same-origin
      redirect: "follow", // *manual, error
      referrer: "no-referrer", // *client
      headers: {
        "user-agent": "Mozilla/4.0 MDN Example",
        "content-type": "application/json",
      },
    };

    let token = await this.auth.currentUser.getIdToken();
    request.headers.Authorization = "Bearer " + token;
    try {
      let res = await fetch(url, request);
      if (res.status > 399) {
        console.log(res);
        throw new Error(
          "Server : Problem in processing data - status :" +
            res.status +
            "@  [  " +
            res.url +
            " ]"
        );
      } else {
        return res;
      }
    } catch (e) {
      throw new Error("Server : Problem in processing data - status :" + e);
    }
  }

  async getData(url) {
    const request = {
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *omit
      method: "GET", // *GET, PUT, DELETE, etc.
      mode: "cors", // cors, *same-origin
      redirect: "follow", // *manual, error
      referrer: "no-referrer", // *client
      headers: {
        "user-agent": "Mozilla/4.0 MDN Example",
        "content-type": "application/json",
      },
    };
    let token = await this.auth.currentUser.getIdToken();
    request.headers.Authorization = "Bearer " + token;
    try {
      let res = await fetch(url, request);
      if (res.status > 399) {
        let status = await res.json();
        console.log(status);
        let ne = new Error(status.message);
        ne.code = status.code;
        throw ne;
      } else {
        return res;
      }
    } catch (e) {
      throw e;
    }
  }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  async addListingToUser(data, user) {
    let body = { data: data };
    console.log("Adding Assets : ", data);
    console.log("USER : ", user);
    try {
      let update = await this.postData(
        apiUrl + "admin/updateUserListings/" + user,
        body
      );
      let updatePayload = await update.json();
      console.log(updatePayload);
      return updatePayload;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async forgotPassword(email) {
    console.log("EMAIL REQUEST");
    this.auth
      .sendPasswordResetEmail(email)
      .then(function () {
        console.log("EMAIL SENT");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error);
      });
  }

  async uploadImagesToStorage(imgs, dir) {
    imgs.shift();
    console.log("uploadImagesToStorageimgs" ,imgs );
    let res = [];
    let urls = [];
    let index = 0;
    try {
      let promises = [];
      let user =
        this.auth.currentUser !== null ? this.auth.currentUser.uid : "";
        promises = imgs.map(async (el) => {
        if(el.file.name!==null){
          let storageRef = this.storage.ref();
          let fileRef = storageRef.child(dir + "/" + user + "/" + el.file.name + "/" + Date.now() + "/" + index++);     
          return res.push(await fileRef.put(el.file));
        }
      });
      console.log(res);
      await Promise.all(promises);
      res = res.map(async (el) => {
        return urls.push(await el.ref.getDownloadURL());
      });
      await Promise.all(res);
      return urls;
    } catch (e) {
      console.log(e);
      throw new Error("Images upload to cloud faild!!");
    }
  }

  async createExpense(expenseValues, urls, listingId, isRule) {
    try {
      let payload = {
        data: {
          ...expenseValues,
          media: urls,
          listingId: listingId,
          currency: "USD",
          isRule: isRule,
        },
      };
      console.log("\n*************************");
      console.log("INITAL DATE -> ", payload.data.paidAt);

      //Forces the date to be treated as utc to avoid locality
      payload.data.paidAt = this.convertToUTC(payload.data.paidAt);

      console.log("CONVERTED DATE -> ", this.convertToUTC(payload.data.paidAt));
      console.log("\n*************************\n\n");

      let res = await this.postData(apiUrl + "admin/createExpense", payload);
      return res;
    } catch (e) {
      console.log(e);
      throw new Error("Expense Creation faild!! \n" + e.message);
    }
  }

  convertToUTC(date) {
    let newDate = moment(date);
    let newDateRep = newDate.format("MM/DD/YYYY");
    let utcTime = moment.utc(newDateRep, "MM/DD/YYYY");
    return utcTime;
  }

  async createPayment(values, listingId) {
    try {
      let payload = {
        data: {
          ...values,
          listingId: listingId,
          currency: "USD",
        },
      };

      //Forces the date to be treated as utc to avoid locality
      payload.data.paidAt = this.convertToUTC(payload.data.paidAt);

      let res = await this.postData(apiUrl + "admin/createPayment", payload);
      return res;
    } catch (e) {
      console.log(e);
      throw new Error("Expense Creation faild!! \n" + e.message);
    }
  }

  async rollBalance(property_id, amount, current_month) {
    try {
      let payload = {
        type: "Balance Forwarding",
        amount: amount,
        paidAt: current_month,
        description: "Rolling balance to next month",
        doneBy: "System admin",
        listingId: property_id,
      };
      console.log(payload);
      let res = await this.createPayment(payload, property_id);
      console.log(res);
      payload.amount = -payload.amount;
      payload.paidAt = moment(payload.paidAt).add(1, "month").startOf("month");
      payload.description = "Balance rolled from prev. month";
      let res2 = await this.createPayment(payload, property_id);
      console.log(res2);
    } catch (e) {
      throw new Error(
        "Problem in Rolling Balance - rollBalance() " + e.message
      );
    }
  }

  async createIncome(values, listingId) {
    try {
      let payload = {
        data: {
          ...values,
          listingId: listingId,
          currency: "USD",
        },
      };

      //Forces the date to be treated as utc to avoid locality
      payload.data.paidAt = this.convertToUTC(payload.data.paidAt);

      let res = await this.postData(
        apiUrl + "admin/createListingIncome",
        payload
      );
      return res;
    } catch (e) {
      console.log(e);
      throw new Error("Income Creation faild!! \n" + e.message);
    }
  }

  async getListingExpenses(id) {
    console.log("ID ID :", id);
    try {
      let res = await this.getData(apiUrl + "admin/expenses/" + id + "");
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      throw new Error(
        "Problem in feching Listing Finance - getListingExpenses() "
      );
    }
  }

  async getListingIncome(id) {
    console.log("ID ID :", id);
    try {
      let res = await this.getData(apiUrl + "admin/income/" + id);
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      throw new Error(
        "Problem in feching Listing Finance - getListingIncome() "
      );
    }
  }

  async getListingPayments(id) {
    console.log("ID ID :", id);
    try {
      let res = await this.getData(apiUrl + "admin/payouts/" + id);
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      // throw new Error("ERROR MY ERR")
    }
  }

  async getListingRules(id) {
    try {
      let res = await this.getData(apiUrl + "admin/rules/" + id);
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      // throw new Error("ERROR MY ERR")
    }
  }

  async getListingConfig(id) {
    console.log("GET CONFIG  : ", id);
    try {
      let res = await this.getData(apiUrl + "admin/config/" + id);
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      // throw new Error("ERROR MY ERR")
    }
  }

  async updateExpense(values, urls) {
    let payload = {
      data: {
        ...values,
        media: urls,
      },
    };
    console.log("UPDATE", payload);

    //Forces the date to be treated as utc to avoid locality
    payload.data.paidAt = this.convertToUTC(payload.data.paidAt);

    try {
      let res = await this.putData(
        apiUrl + "admin/expense/" + payload.expenseId,
        payload
      );
      console.log(res);
    } catch (e) {
      throw new Error(
        "Problem in updating Expense - updateExpense() " + e.message
      );
    }
  }

  async updatePayment(values) {
    let payload = {
      data: {
        ...values,
      },
    };
    console.log("UPDATE", payload);

    //Forces the date to be treated as utc to avoid locality
    payload.data.paidAt = this.convertToUTC(payload.data.paidAt);

    try {
      let res = await this.putData(
        apiUrl + "admin/payouts/" + payload.data.paymentId,
        payload
      );
      console.log(res);
    } catch (e) {
      throw new Error(
        "Problem in updating Payment - updatePayment() " + e.message
      );
    }
  }

  async updateIncome(values) {
    let payload = {
      data: {
        ...values,
      },
    };
    console.log("UPDATE", payload);

    //Forces the date to be treated as utc to avoid locality
    payload.data.paidAt = this.convertToUTC(payload.data.paidAt);

    try {
      let res = await this.putData(
        apiUrl + "admin/income/" + payload.data.incomeId,
        payload
      );
      console.log(res);
    } catch (e) {
      throw new Error(
        "Problem in updating Payment - updateIncome() " + e.message
      );
    }
  }

  async updateRule(values) {
    let payload = {
      data: {
        ...values,
      },
    };
    console.log("UPDATE RULE", payload);
    try {
      let res = await this.putData(
        apiUrl + "admin/rules/" + payload.data.ruleId,
        payload
      );
      console.log(res);
    } catch (e) {
      throw new Error("Problem in updating Rule - updateRule() " + e.message);
    }
  }

  async deleteExpense(id) {
    console.log("DELETE EXPENSE", id);
    try {
      let res = await this.deleteData(apiUrl + "admin/expense/" + id);
      console.log(res);
    } catch (e) {
      console.log(e);
      throw new Error(
        "Problem in Delete Expense - deleteExpense() " + e.message
      );
    }
  }

  async deletePayment(id) {
    console.log("DELETE PAYMENT", id);
    try {
      let res = await this.deleteData(apiUrl + "admin/payouts/" + id);
      console.log(res);
    } catch (e) {
      console.log(e);
      throw new Error(
        "Problem in Delete Payment - deletePayment() " + e.message
      );
    }
  }

  async deleteIncome(id) {
    console.log("DELETE INCOME", id);
    try {
      let res = await this.deleteData(apiUrl + "admin/income/" + id);
      console.log(res);
    } catch (e) {
      console.log(e);
      throw new Error(
        "Problem in Delete Payment - deleteIncome() " + e.message
      );
    }
  }

  async deleteCanceled(id) {
    console.log("DELETE CANCELED RESERVATION", id);
    try {
      let res = await this.deleteData(apiUrl + "admin/activateCanceled/" + id);
      console.log(res);
    } catch (e) {
      console.log(e);
      throw new Error(
        "Problem in Delete canceled reservation - deleteCanceled() " + e.message
      );
    }
  }

  async deleteRule(id) {
    try {
      console.log("DELETE RULE", id);
      let res = await this.deleteData(apiUrl + "admin/rules/" + id);
      console.log(res);
    } catch (e) {
      console.log(e);
      throw new Error("Problem in Delete Payment - deleteRule() " + e.message);
    }
  }

  async deleteUser(id) {
    try {
      console.log("DELETE USER", id);
      let res = await this.deleteData(apiUrl + "admin/user/" + id);
      console.log(res);
    } catch (e) {
      console.log(e);
      throw new Error("Problem in Delete User - deleteUser() " + e.message);
    }
  }

  async getSettings() {
    try {
      let res = await this.getData(apiUrl + "admin/settings");
      let list = await res.json();
      return list.payload;
    } catch (e) {
      throw new Error("Problem in getting settings- getSettings() " + e);
    }
  }

  async saveListingConfig(values, listingId) {
    try {
      let payload = {
        data: {
          ...values,
          listingId: listingId,
        },
      };
      let res = await this.postData(
        apiUrl + "admin/config/" + listingId,
        payload
      );
      return res;
    } catch (e) {
      console.log(e);
      throw new Error("Saving Configuration Failed!! \n\n\n" + e.message);
    }
  }

  async updateListingConfig(values, listingId, configId) {
    console.log("UPDATE VALUES : ", values);
    try {
      let payload = {
        data: {
          ...values,
          listingId: listingId,
          configId: configId,
        },
      };
      let res = await this.putData(
        apiUrl + "admin/config/" + listingId,
        payload
      );
      return res;
    } catch (e) {
      console.log(e);
      throw new Error("Updating Configuration Failed!! \n\n\n" + e.message);
    }
  }

  async deleteListingConfig(listingId, configId) {
    console.log("DELETE CONFIG : ", configId);
    try {
      let payload = {
        data: {
          listingId: listingId,
          configId: configId,
        },
      };
      let res = await this.deleteData(
        apiUrl + "admin/config/" + listingId,
        payload
      );
      return res;
    } catch (e) {
      console.log(e);
      throw new Error("Deleting Configuration Failed!! \n\n\n" + e.message);
    }
  }

  async saveChannelConfig(values) {
    console.log("UPDATE VALUES : ", values);
    try {
      let payload = {
        data: {
          ...values,
        },
      };
      let res = await this.postData(apiUrl + "admin/settings/", payload);
      return res;
    } catch (e) {
      console.log(e);
      throw new Error(
        "Creating Channel Configuration Failed!! \n\n\n" + e.message
      );
    }
  }

  async updateChannelConfig(values, configId) {
    console.log("UPDATE VALUES : ", values);
    try {
      let payload = {
        data: {
          ...values,
        },
      };
      let res = await this.putData(
        apiUrl + "admin/settings/" + configId,
        payload
      );
      return res;
    } catch (e) {
      console.log(e);
      throw new Error("Updating Configuration Failed!! \n\n\n" + e.message);
    }
  }

  async updateBeginDate(id, dateString) {
    try {
      let payload = {
        data: {
          dateString: dateString,
        },
      };
      let res = await this.postData(apiUrl + "admin/beginDate/" + id, payload);
      return res;
    } catch (e) {
      console.log(e);
      throw new Error(
        "Updating Date Configuration Failed!! \n\n\n" + e.message
      );
    }
  }

  async deleteChannelConfig(id) {
    console.log("DELETE VALUES : ", id);
    try {
      let res = await this.deleteData(apiUrl + "admin/settings/" + id);
      return res;
    } catch (e) {
      console.log(e);
      throw new Error(
        "Delete Channel Configuration Failed!! \n\n\n" + e.message
      );
    }
  }
  async activateCanceledRes(values, listingId) {
    console.log(values);
    try {
      let payload = {
        data: {
          ...values,
        },
      };

      let res = await this.postData(apiUrl + "admin/activateCanceled", payload);
      return res;
    } catch (e) {
      console.log(e);
      throw new Error("Canceled Reservaton Activation faild!! \n" + e.message);
    }
  }

  async getRoiReportData(id) {
    console.log("GET ROI  : ", id);
    try {
      let res = await this.getData(apiUrl + "view/listing/roi/" + id);
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      throw new Error(
        "Setup data must be configured for this listing!  " + e.message
      );
    }
  }

  async getListingSetup(id) {
    console.log("GET SETUP  : ", id);
    try {
      let res = await this.getData(apiUrl + "view/listing/setup/" + id);
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      throw new Error(
        "There was a problem fetching the listing setup data  " + e.message
      );
    }
  }

  async updateListingSetup(id, values) {
    try {
      let payload = {
        data: {
          ...values,
        },
      };
      let res = await this.postData(
        apiUrl + "view/listing/setup/" + id,
        payload
      );
      return res;
    } catch (e) {
      console.log(e);
      throw new Error(
        "There was a problem updating the listing setup data" + e.message
      );
    }
  }
  async getListingTaxRateById(id){
    console.log("GET LISTING TAX RATE ID  : ", id);  
    let taxRate;
      try {
        let res = await this.getData(apiUrl + "admin/config/" + id);
        let list = await res.json();
        console.log("list",list);
        taxRate =  list.payload[0]['data']['settings']['cityTax'] + list.payload[0]['data']['settings']['stateTax'];
        console.log("taxRate",taxRate);
        
        return taxRate;
  
      } catch (e) {
        console.log(e);
        // throw new Error("ERROR MY ERR")
      }
      return taxRate;
  
   }
   async getListingConfig(id) {
    console.log("GET CONFIG  : ", id);
    try {
      let res = await this.getData(apiUrl + "admin/config/" + id);
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) { 
      console.log(e);
      // throw new Error("ERROR MY ERR")
    }
  }

  async getListingById(id){
    console.log("getListingByid :" , id);
    let promises = [];
    let res;
    try{
      console.log("ssss");
      res = await this.getData(apiUrl + "view/listings/" + id);
      console.log(res);
      let list = await res.json();
      return list.payload;
    }catch(e){
      console.log(e.message);
    }
  }

  async getListingByTitle(title){
    console.log("getListingByTitle :" , title);
    try{
      console.log("ss");
      let res = await this.getData(apiUrl + "view/listings/" + title);
      console.log(res);
      let list = await res.json();
      console.log(list);
      return list;
    }catch(e){
      console.log(e.message);
    }
  } 
  async getInvestorExpenses(listingId, year) {
    console.log("GET Investor Expenses  : ", listingId);
    try {
      let res = await this.getData(
        apiUrl + "view/listing/investor/" + listingId + "?year=" + year
      );
      let list = await res.json();
      console.log(list);
      return list.payload;
    } catch (e) {
      console.log(e);
      throw new Error(
        "There was a problem fetching the investor expenses data  " + e.message
      );
    }
  }

  async createInvestorExpense(listingId, values) {
    console.log(listingId);
    console.log(values);
    try {
      let payload = {
        data: {
          ...values,
        },
      };
      let res = await this.postData(
        apiUrl + "view/listing/investor/" + listingId,
        payload
      );
      return res;
    } catch (e) {
      console.log(e);
      throw new Error(
        "There was a problem updating the listing setup data" + e.message
      );
    }
  }
  
  async deleteInvestorExpense(listingId, expenseId) {
    console.log("DELETE INVESTOR EXPENSE : ", listingId, expenseId);
    try {
      let payload = {
        data: {
          id: expenseId,
        },
      };

      let res = await this.deleteData(
        apiUrl + "view/listing/investor/" + listingId,
        payload
      );
      return res;
    } catch (e) {
      console.log(e);
      throw new Error(
        "Delete Channel Configuration Failed!! \n\n\n" + e.message
      );
    }
  }
}

export default new FirebaseService(
  process.env.REACT_APP_API_REF,
  process.env.REACT_APP_DB_REF
);
