import React, { useState, createRef } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const ModalBack = styled.div`
position: absolute;
  display: ${props => props.open === true ? "flex" : "none"};
  width:100%;
 height:100%;
  flex-direction: row;
justify-content: center;
align-items: center;
top:0;
overflow:hidden;
background-color: ${({ theme }) => theme.modalBackground};
z-index:1200;
@media (max-width:1024px){
    position: fixed;
    top:0;
}
`;

const ModalCard = styled.div`
display: flex;
position:relative;
width:100%;
max-width:1024px;
height:95vh;
max-height:1000px;
flex-direction: column;
justify-content: center;
justify-items: center;
overflow:hidden;
background-color: ${(props) => props.theme.white};
border-radius:4px;
padding:3rem 1rem;
`;

const ModalContent = styled.div`
display: block;
background-color: ${(props) => props.theme.white};
height:100%;
max-height:100vh;
overflow-Y:auto;
`;

const CloseButton = styled.div`
position:absolute;
  z-index:100;
  left:1.5rem;
  top:1rem;
  font-size:1.5rem;
  font-weight:500;
`;

const ContentItems = styled.div`
display:flex;
flex-flow:column wrap;
align-content:center;
width:100%;
padding-bottom:3rem;

`;

export default class Modal extends React.Component {


    constructor(props) {
        super(props);
        console.log("Modal Here")


    }

    componentDidMount() {

        this.targetElement = document.querySelector('#modal');
        console.log("DID MOUNT", this.targetElement)
        disableBodyScroll(this.targetElement);
        console.log(document.body.style)
        document.body.style.height = "100vh";
        document.body.style.overflowY = "hidden";
        document.body.style.boxSizing = "border-box"
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }


    onModalClose(e) {
        console.log("Modal onModalClose")
        console.log(document.body.style)
        enableBodyScroll(this.targetElement);
        this.props.onClose();


    }

    render() {
        console.log("RENDER")

        return (
            <ModalBack open={this.props.open} ytop={window.scrollY}>

                <ModalCard>
                    <CloseButton>
                        < FontAwesomeIcon icon={faTimesCircle} onClick={(e) => { this.onModalClose(e) }} />
                    </CloseButton>
                    <ModalContent id="modal">
                        <ContentItems >
                            {this.props.children}
                        </ContentItems>
                    </ModalContent>
                </ModalCard>
            </ModalBack>
        )

    }
}









