import data from "../data/listingData.json"
import listing from "../data/listings.json"

class LocalDb {

    constructor() {
        console.log("LOCAL CONSTRUCTOR")
    }

    getData() {
        console.log("LOCAL DB DATA")
        return data
    }
    getListings() {
        return listing
    }
}

export default LocalDb