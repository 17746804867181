import React, { useState, useEffect, useContext, useReducer } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import GlobalContext from "../../store/context";
import { MetricBox } from "../../theme//theme.js";

import {
  faThermometerThreeQuarters,
  faMoon,
  faMoneyBillWave,
  faAddressBook,
  faConciergeBell,
  faFunnelDollar,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

const numberWithCommas = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const calcLastYearDiff = function (a, b) {
  if (b === 0 || a === 0) {
    return 0;
  } else {
    if (!isNaN(Math.round((a / b - 1) * 100))) {
      return Math.round((a / b - 1) * 100);
    } else {
      return "---";
    }
  }
};

const returnComparisonClassName = function (a) {
  return a > 0 ? "positive" : a < 0 ? "negative" : "";
};
const Metrices = (props) => {
  const income_comp = calcLastYearDiff(props.income, props.incomePrev);
  const management_comp = calcLastYearDiff(
    props.management,
    props.managementPrev
  );
  const expenses_comp = calcLastYearDiff(props.expenses, props.expensesPrev);
  const adr_comp = calcLastYearDiff(props.adr, props.adrPrev);
  const avgnts_comp = calcLastYearDiff(props.avgnts, props.avgntsPrev);
  const dayAdv_comp = calcLastYearDiff(props.dayAdv, props.dayAdvPrev);
  const occupanceRate_comp = calcLastYearDiff(
    props.occupanceRate,
    props.occupanceRatePrev
  );
  const net_comp = calcLastYearDiff(
    props.income - props.expenses,
    props.incomePrev - props.expensesPrev
  );

  console.log("METRICES : ", props.occupanceRate, props.occupanceRatePrev);
  return (
    <fieldset>
      {props.title && <legend>{props.title}</legend>}

      <div className="widget-container">
        <>
          <MetricBox>
            <h3>
              {" "}
              <FontAwesomeIcon icon={faMoneyBillWave} />
              &nbsp;&nbsp;Income
            </h3>
            <h1>
              <span className="sign">$</span>
              {numberWithCommas(props.income.toFixed(0))}
            </h1>
            <h5 className={returnComparisonClassName(income_comp)}>
              {income_comp}%
            </h5>
          </MetricBox>

          <MetricBox>
            <h3>
              {" "}
              <FontAwesomeIcon icon={faFunnelDollar} />
              &nbsp;&nbsp;Expenses
            </h3>
            <h1>
              <span className="sign">$</span>
              {numberWithCommas(props.expenses.toFixed(0))}
            </h1>
            <h5 className={returnComparisonClassName(expenses_comp)}>
              {expenses_comp}%
            </h5>
          </MetricBox>

          <MetricBox>
            <h3>
              {" "}
              <FontAwesomeIcon icon={faConciergeBell} />
              &nbsp;&nbsp;NET
            </h3>
            <h1>
              <span className="sign">$ </span>
              {numberWithCommas((props.income - props.expenses).toFixed(0))}
            </h1>
            <h5 className={returnComparisonClassName(net_comp)}>{net_comp}%</h5>
          </MetricBox>

          <MetricBox>
            <h3>
              {" "}
              <FontAwesomeIcon icon={faMoneyBillWave} />
              &nbsp;&nbsp;Management
            </h3>
            <h5 className={returnComparisonClassName(management_comp)}>
              {management_comp}%
            </h5>

            <h1>
              <span className="sign">$</span>
              {numberWithCommas(props.management.toFixed(0))}
            </h1>
          </MetricBox>

          <MetricBox className="small">
            <h3>
              {" "}
              <FontAwesomeIcon icon={faThermometerThreeQuarters} />
              &nbsp;&nbsp;ADR
            </h3>
            <h1>
              <span className="sign">$</span>
              {props.adr.toFixed(2)}
            </h1>
            <h5 className={returnComparisonClassName(adr_comp)}>{adr_comp}%</h5>
          </MetricBox>

          <MetricBox className="small">
            <h3>
              {" "}
              <FontAwesomeIcon icon={faMoon} />
              &nbsp;&nbsp;Avg Nights
            </h3>
            <h1> {props.avgnts.toFixed(2)}</h1>
            <h5 className={returnComparisonClassName(avgnts_comp)}>
              {avgnts_comp}%
            </h5>
          </MetricBox>

          <MetricBox className="small">
            <h3>
              {" "}
              <FontAwesomeIcon icon={faAddressBook} />
              &nbsp;&nbsp;Booked Before
            </h3>
            <h1>
              {props.dayAdv.toFixed(2)}
              <span className="sign">days</span>
            </h1>
            <h5 className={returnComparisonClassName(dayAdv_comp)}>
              {dayAdv_comp}%
            </h5>
          </MetricBox>

          <MetricBox className="small">
            <h3>
              {" "}
              <FontAwesomeIcon icon={faConciergeBell} />
              &nbsp;&nbsp;Occupancy
            </h3>
            <h1>
              {props.occupanceRate.toFixed(2)}
              <span className="sign">%</span>
            </h1>
            <h5 className={returnComparisonClassName(occupanceRate_comp)}>
              {occupanceRate_comp}%
            </h5>
          </MetricBox>
        </>
      </div>
    </fieldset>
  );
};

export default Metrices;
