import React, { useState, useEffect, useContext } from "react";
import { Page, Content, Input, Form, PrimaryButton } from "../theme/theme";
import styled from "styled-components";
import ChannelConfigForm from "../components/Listings/ChannelConfigForm";
import TimeSelector from "../components/ui/TimeSelector";
import moment from "moment";
import FirebaseService from "../services/FirebaseService";
import Context from "../store/context";

const ChannelCard = styled.fieldset`
  width: flex 1;
  max-width: 300px;
  border: 1px ${(props) => props.theme.darkGray} solid;
  border-radius: 4px;
`;
const Channels = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  border-radius: 4px;
  justify-content: center;
`;
const ChannelForm = styled(Form)`
  background-color: ${({ theme }) => theme.white};
  width: 100%;
  justify-content: space-evenly;
  padding: 0;
  border: none;
  margin: 0;
`;

const Switches = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const DeleteButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.negRed};
  color: ${({ theme }) => theme.negRed};
  align-self: flex-end;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: -50px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default function Settings(props) {
  const context = useContext(Context);

  const [currentTime, setTime] = useState(moment());
  const [settings, setSettings] = useState(null);
  const [settingList, setSettingList] = useState([]);
  const [settingIndex, setIndext] = useState(0);

  const onTimeChange = function (val) {
    let newIndex = settingIndex + val * -1;
    console.log("LIST : ", settingList, settingList.length);
    if (newIndex >= 0 && newIndex < settingList.length) {
      console.log("NEW INDEX : " + newIndex);
      setSettings(settingList[newIndex]);
      setIndext(newIndex);
    }
  };

  const deleteSettings = async function (id) {
    console.log("ID : ", id);
    context.actions({
      type: "setState",
      payload: { ...context.state, isFetching: true },
    });
    try {
      let res = await FirebaseService.deleteChannelConfig(id);
      console.log(res);
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: false },
      });
      setTime(moment());
    } catch (e) {
      console.log(e);
      context.actions({
        type: "setState",
        payload: { ...context.state, isError: true, errorMsg: e.message },
      });
    }
  };

  useEffect(() => {
    const loadList = async function () {
      console.log("Loading Settings");
      context.actions({
        type: "setState",
        payload: { ...context.state, isFetching: true },
      });

      try {
        let list = await FirebaseService.getSettings();
        setSettingList(list);
        setSettings(list[settingIndex]);
        context.actions({
          type: "setState",
          payload: { ...context.state, isFetching: false },
        });
      } catch (e) {
        console.log(e);
        context.actions({
          type: "setState",
          payload: { ...context.state, isError: true, errorMsg: e.message },
        });
      }
    };

    loadList();
  }, [currentTime]);

  return (
    <Page>
      <Content>
        <fieldset>
          <legend>Channel Settings</legend>
          <ButtonContainer>
            <PrimaryButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                console.log("UPDATE BUTTON WAS CLICKED");
                context.actions({
                  type: "openModal",
                  payload: {
                    modalComponent: (
                      <ChannelConfigForm
                        title="New Configuration"
                        refresh={() => setTime(moment())}
                      ></ChannelConfigForm>
                    ),
                  },
                });
              }}
            >
              Create New
            </PrimaryButton>
          </ButtonContainer>
          <Channels>
            <ChannelCard>
              <TimeSelector
                time={
                  settings === null
                    ? moment()
                    : moment(
                        settings.data.activationYear +
                          "-" +
                          settings.data.activationMonth,
                        "YYYY-MM"
                      )
                }
                mode="monthOnly"
                onTimeChange={onTimeChange}
              ></TimeSelector>

              <ChannelForm>
                <label forHtml="channel_fee">Channel Fee:</label>
                <Input
                  type="number"
                  name="channel_fee"
                  value={
                    settings === null
                      ? 0
                      : settings.data.settings.vrbo.channelFee
                  }
                  readOnly
                ></Input>
                <label forHtml="processing_fee">Proccesing Fee:</label>
                <Input
                  type="number"
                  name="processing_fee"
                  value={
                    settings === null
                      ? 0
                      : settings.data.settings.vrbo.processingFee
                  }
                  readOnly
                ></Input>
                <ButtonsContainer>
                  <PrimaryButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      console.log("UPDATE BUTTON WAS CLICKED");
                      context.actions({
                        type: "openModal",
                        payload: {
                          modalComponent: (
                            <ChannelConfigForm
                              title="Update Configuration"
                              configId={settings.id}
                              refresh={() => setTime(moment())}
                              init={{
                                ...settings.data.settings.vrbo,
                                year: settings.data.activationYear,
                                month: settings.data.activationMonth,
                              }}
                            ></ChannelConfigForm>
                          ),
                        },
                      });
                    }}
                  >
                    Update
                  </PrimaryButton>
                  <DeleteButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      console.log("DELETE BUTTON WAS CLICKED");
                      if (
                        window.confirm(
                          "Are You Sure You Want to Delete This Configuration?"
                        )
                      ) {
                        console.log("DELETE CONFIG");
                        deleteSettings(settings.id);
                      } else {
                        console.log("DO NOTHING ");
                      }
                    }}
                  >
                    DELETE
                  </DeleteButton>
                </ButtonsContainer>
              </ChannelForm>
            </ChannelCard>
          </Channels>
        </fieldset>
      </Content>
    </Page>
  );
}
