import React, { useState, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Title,
  Text,
  Button,
  Label,
  Select,
} from "../../theme//theme.js";
import { Formik, useFormikContext, useField } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Context from "../../store/context";
import FirebaseService from "../../services/FirebaseService";

function ChannelConfigForm(props) {
  const context = useContext(Context);

  const [startDate, setStartDate] = useState(new Date());

  const submitForm = async (values) => {
    context.actions({
      type: "setState",
      payload: { ...context.state, isFetching: true },
    });
    if (props.init == null) {
      try {
        await FirebaseService.saveChannelConfig(values);
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isFetching: false,
            isModalOpen: false,
            modalComponent: "",
          },
        });
        props.refresh();
      } catch (e) {
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isError: true,
            errorMsg: e.message,
            isModalOpen: false,
            modalComponent: "",
          },
        });
      }
    } else {
      // Update
      try {
        await FirebaseService.updateChannelConfig(values, props.configId);
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isFetching: false,
            isModalOpen: false,
            modalComponent: "",
          },
        });
        props.refresh();
      } catch (e) {
        context.actions({
          type: "setState",
          payload: {
            ...context.state,
            isError: true,
            errorMsg: e.message,
            isModalOpen: false,
            modalComponent: "",
          },
        });
      }
    }
  };

  return (
    <>
      <Title>{props.title}</Title>

      {/* FORMIK */}
      <Formik
        initialValues={
          !props.init
            ? {
                year: Number(moment().format("YYYY")),
                month: Number(moment().month() + 1),
              }
            : props.init
        }
        validate={(values) => {
          console.log("VALIDATE : ", values);
        }}
        onSubmit={(values) => {
          submitForm(values);
        }}
        enableReinitialize={false}
        validationSchema={yup.object().shape({
          month: yup.number(),
          year: yup.number(),
          processingFee: yup.number().required(),
          channelFee: yup.number().required(),
        })}
      >
        {({
          touched,
          setFieldValue,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Label>
              Month
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.month}
                border={errors.month && "1px solid red"}
                type="number"
                name="month"
                placeholder="Expense Amount"
                disabled={props.init !== undefined}
              >
                <option value={1}>Jan</option>
                <option value={2}>Feb</option>
                <option value={3}>Mar</option>
                <option value={4}>Apr</option>
                <option value={5}>May</option>
                <option value={6}>Jun</option>
                <option value={7}>Jul</option>
                <option value={8}>Aug</option>
                <option value={9}>Sep</option>
                <option value={10}>Oct</option>
                <option value={11}>Nov</option>
                <option value={12}>Dec</option>
              </Select>
            </Label>

            <Label>
              Year
              <Select
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.year}
                border={errors.year && "1px solid red"}
                type="number"
                name="year"
                placeholder="Expense Amount"
                disabled={props.init !== undefined}
              >
                <option value={1980}>1980</option>
                <option value={2015}>2015</option>
                <option value={2016}>2016</option>
                <option value={2017}>2017</option>
                <option value={2018}>2018</option>
                <option value={2019}>2019</option>
                <option value={2020}>2020</option>
                <option value={2021}>2021</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
                <option value={2025}>2025</option>
                <option value={2026}>2026</option>
                <option value={2027}>2027</option>
                <option value={2028}>2028</option>
                <option value=""></option>
              </Select>
            </Label>
            <Label>
              Channel Fee
              {errors.channelFee && (
                <Text color="red">{errors.channelFee}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.channelFee}
                border={errors.channelFee && "1px solid red"}
                type="number"
                name="channelFee"
                placeholder="Between 0 and 1"
              />
            </Label>
            <Label>
              Processing Fee
              {errors.processingFee && (
                <Text color="red">{errors.processingFee}</Text>
              )}
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.processingFee}
                border={errors.processingFee && "1px solid red"}
                type="number"
                name="processingFee"
                placeholder="Between 0 and 1"
              />
            </Label>

            <Button type="submit">Save</Button>
          </Form>
        )}
      </Formik>
      {/* END OF FORMIK */}
    </>
  );
}

export default ChannelConfigForm;
